import axios from 'axios';
import { WarehouseProductPrice } from '../models/WarehouseProductPriceList';

const API_URL = process.env.REACT_APP_SERVER_URL + "/warehouseproductprice";

export const WarehouseProductPriceService = {
    getAllPrices: async (): Promise<WarehouseProductPrice[]> => {
        const response = await axios.get(`${API_URL}/getAllPrices`);
        return response.data;
    },
    getPriceById: async (id: number): Promise<WarehouseProductPrice> => {
        const response = await axios.get(`${API_URL}/getPriceById/${id}`);
        return response.data;
    },
    getPriceByProductId: async (id: number): Promise<WarehouseProductPrice[]> => {
        const response = await axios.get(`${API_URL}/getPriceByProductId/${id}`);
        return response.data;
    },
    addPrice: async (data: Omit<WarehouseProductPrice, 'id' | 'created_time' | 'updated_time'>) => {
        const response = await axios.post(`${API_URL}/add`, data);
        return response.data;
    },
    updatePrice: async (id: number, data: Omit<WarehouseProductPrice, 'id' | 'created_time' | 'updated_time'>) => {
        const response = await axios.put(`${API_URL}/update/${id}`, data);
        return response.data;
    },
    deletePrice: async (id: number): Promise<void> => {
        await axios.delete(`${API_URL}/delete/${id}`);
    },
};