import React, { useEffect, useState } from 'react';
import { BanknotesIcon, CalendarIcon, ChevronLeftIcon, CreditCardIcon } from '@heroicons/react/16/solid';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AppUser } from '../../../../models/AppUser';
import { useAppUser } from '../../../../hooks/useAppUser';
import showErrorToast from '../../../../components/Toasts/ShowErrorToast';
import { AppUserService } from '../../../../services/appUserService';
import showSuccessToast from '../../../../components/Toasts/ShowSuccessToast';
import Loading from '../../../../components/Loading';
import { Avatar } from '../../../../components/Avatar';
import { Heading, Subheading } from '../../../../components/Heading';
import { Badge } from '../../../../components/Badge';
import dateFormat from 'dateformat';
import { Button } from '../../../../components/Button';
import { Divider } from '../../../../components/Divider';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from '../../../../components/Description-List';
import { Dialog, DialogActions, DialogDescription, DialogTitle } from '../../../../components/Dialog';
import { AppAnnouncement } from '../../../../models/AppAnnouncement';
import { useAppAnnouncements } from '../../../../hooks/useAppAnnouncement';

const ViewAppAnnouncementIdPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [announcement, setAnnouncement] = useState<AppAnnouncement | null>(null);
    const navigate = useNavigate();
    const { fetchAnnouncementById, loading, error, } = useAppAnnouncements();

    useEffect(() => {
        document.title = "View App Announcement";
    }, []);

    useEffect(() => {
        if (!id) return;

        const fetchAnnouncementDetails = async () => {
            try {
                const fetchedAnnouncement = await fetchAnnouncementById(Number(id));
                setAnnouncement(fetchedAnnouncement!);
            } catch (err) {
                console.error('Failed to fetch user details:', err);
            }
        };

        fetchAnnouncementDetails();
    }, [id]);

    if (loading) return <Loading />;
    if (error) return <p className="py-16 text-center text-red-700">{error}</p>;
    if (!announcement) return <p className="py-16 text-center">Announcement not found.</p>;

    return (
        <>
            <div className="max-lg:hidden">
                <Link to="/gp/app/users" className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400">
                    <ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500" />
                    App Announcements
                </Link>
            </div>
            <div className="mt-4 lg:mt-8">
                <div className="flex items-center gap-4">
                    <Avatar square initials={announcement.title[0]} className="bg-zinc-300 text-gray-700 size-8" />
                    <Heading>{announcement.title}</Heading>
                </div>
                <div className="isolate mt-2.5 flex flex-wrap justify-between gap-x-6 gap-y-4">
                    <div className="flex flex-wrap gap-x-10 gap-y-4 py-1.5">
                        <span className="flex items-center gap-2 text-base/6 text-zinc-950 sm:text-sm/6 dark:text-white">
                            <CalendarIcon className="size-4 shrink-0 fill-zinc-400 dark:fill-zinc-500" />
                            <span>{dateFormat(announcement.created_time, "mmmm dd, yyyy")}</span>
                        </span>
                    </div>
                    <div className="flex gap-4">
                        <Button onClick={() => navigate(`/gp/app/announcements/edit/${announcement.id}`)}>Edit</Button>
                    </div>
                </div>
            </div>

            <div className="mt-12">
                <Subheading>Announcement Details</Subheading>
                <Divider className="mt-4" />
                <DescriptionList>

                    <DescriptionTerm>Title</DescriptionTerm>
                    <DescriptionDetails>{announcement.title || '-'}</DescriptionDetails>
                    <DescriptionTerm>Content</DescriptionTerm>
                    <DescriptionDetails>{announcement.content || '-'}</DescriptionDetails>

                </DescriptionList>
            </div>
        </>
    );
};

export default ViewAppAnnouncementIdPage;
