import React, { useEffect, useState } from 'react';
import { BanknotesIcon, CalendarIcon, ChevronLeftIcon, CreditCardIcon } from '@heroicons/react/16/solid';
import { Link, useNavigate, useParams } from 'react-router-dom'; // useParams to get route params
import { Heading, Subheading } from '../../../components/Heading';
import { Button } from '../../../components/Button';
import { Divider } from '../../../components/Divider';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from '../../../components/Description-List';
import { Avatar } from '../../../components/Avatar';
import { UserService } from '../../../services/userService'; // Import the UserService
import Loading from '../../../components/Loading';
import dateFormat from 'dateformat';
import showErrorToast from '../../../components/Toasts/ShowErrorToast';
import { WarehouseProductCategory } from '../../../models/WarehouseProductCategory';
import { useWarehouseProductCategory } from '../../../hooks/useWarehouseProductCategory';
import { useAppSalesOrders } from '../../../hooks/useAppSalesOrder';
import { AppSalesOrder } from '../../../models/AppSalesOrder';

const ViewAppSalesOrderIdPage: React.FC = () => {
    const { id } = useParams<{ id: string }>(); // Use useParams to get the id from the URL
    const navigate = useNavigate();
    const [salesOrder, setSalesOrder] = useState<AppSalesOrder | null>(null);
    const { fetchSalesOrderById, loading, error } = useAppSalesOrders();

    useEffect(() => {
        const fetchSalesOrderDetail = async () => {
            if (!id) return; // Ensure ID is valid before making the request

            try {
                const fetchedOrder = await fetchSalesOrderById(Number(id));
                setSalesOrder(fetchedOrder);
            } catch (err) {
                showErrorToast('Failed to fetch sales order detail.');
            }
        };
        fetchSalesOrderDetail();
    }, [id]);

    useEffect(() => {
        document.title = "View App Sales Order";
    }, []);

    if (loading) return <Loading />;
    if (error) return <p className='py-16 text-center text-red-700'>{error}</p>;
    if (!salesOrder) return <p className='py-16 text-center'>Sales Order not found.</p>;

    return (
        <>
            <div className="max-lg:hidden">
                <Link to="/gp/app/salesorders" className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400">
                    <ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500" />
                    Sales Orders
                </Link>
            </div>
            <div className="mt-4 lg:mt-8">
                <div className="flex items-center gap-4">
                    <Avatar square initials={salesOrder.customer_fullname![0]} className="bg-zinc-300 text-gray-700 size-8" />
                    <Heading>{salesOrder.customer_fullname}</Heading>
                </div>
                <div className="isolate mt-2.5 flex flex-wrap justify-between gap-x-6 gap-y-4">
                    <div className="flex flex-wrap gap-x-10 gap-y-4 py-1.5">
                        <span className="flex items-center gap-2 text-base/6 text-zinc-950 sm:text-sm/6 dark:text-white">
                            <CalendarIcon className="size-4 shrink-0 fill-zinc-400 dark:fill-zinc-500" />
                            <span>{dateFormat(salesOrder.created_time, "mmmm dd, yyyy")}</span>
                        </span>
                    </div>

                </div>
            </div>
            <div className="mt-12">
                <Subheading>Sales Order Information</Subheading>
                <Divider className="mt-4" />
                <DescriptionList>
                    <DescriptionTerm>Customer</DescriptionTerm>
                    <DescriptionDetails>{salesOrder.customer_fullname}</DescriptionDetails>
                    <DescriptionTerm>Product</DescriptionTerm>
                    <DescriptionDetails>{salesOrder.product_description}</DescriptionDetails>
                    <DescriptionTerm>Price</DescriptionTerm>
                    <DescriptionDetails>{salesOrder.price}</DescriptionDetails>
                    <DescriptionTerm>Quantity</DescriptionTerm>
                    <DescriptionDetails>{salesOrder.quantity}</DescriptionDetails>
                    <DescriptionTerm>Remarks</DescriptionTerm>
                    <DescriptionDetails>{salesOrder.remarks || '-'}</DescriptionDetails>
                </DescriptionList>
            </div>
        </>
    );
};

export default ViewAppSalesOrderIdPage;
