import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Heading, Subheading } from '../../../../components/Heading';
import { Divider } from '../../../../components/Divider';
import { Input } from '../../../../components/Input';
import { Button } from '../../../../components/Button';
import showLoadingToast from '../../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../../components/Toasts/ShowSuccessToast';
import showErrorToast from '../../../../components/Toasts/ShowErrorToast';
import { useWarehouseProduct } from '../../../../hooks/useWarehouseProduct';
import { WarehouseProduct } from '../../../../models/WarehouseProduct';
import { Dialog, DialogActions, DialogDescription, DialogTitle } from '../../../../components/Dialog';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../components/Table';
import { PencilIcon, TrashIcon } from '@heroicons/react/16/solid';
import { useSetupUsergroup } from '../../../../hooks/useSetupUsergroup';
import ReactSelect, { SingleValue } from 'react-select';
import { SetupUsergroup } from '../../../../models/SetupUsergroup';
import { useWarehouseProductPrice } from '../../../../hooks/useWarehouseProductPriceList';
import { WarehouseProductPriceService } from '../../../../services/warehouseProductPriceListService';
import { WarehouseProductPrice } from '../../../../models/WarehouseProductPriceList';

interface AddWarehouseProductPrice {
    product_id: number | null;
    supplier_id: number | null;
    price: string | null;
    remarks: string | null;
    supplier_fullname?: string | null;
}

const AddWarehouseProductPriceListPage = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [productPrice, setProductPrice] = useState<AddWarehouseProductPrice>({
        product_id: Number(id),
        supplier_id: null,
        price: '',
        remarks: '',
    });
    const { addMultiplePrice, fetchPriceByProductId } = useWarehouseProductPrice();
    const { products } = useWarehouseProduct();
    const { suppliers } = useSetupUsergroup();
    const [selectedProduct, setSelectedProduct] = useState<WarehouseProduct | null>(null);
    const [productPriceList, setProductPriceList] = useState<AddWarehouseProductPrice[]>([]);
    const [currentProductPriceList, setCurrentProductPriceList] = useState<WarehouseProductPrice[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [editItemIndex, setEditItemIndex] = useState<number | null>(null);
    const [editForm, setEditForm] = useState<Partial<AddWarehouseProductPrice>>({
        price: '',
        remarks: '',
    });

    useEffect(() => {
        document.title = "Add Product Price";
    }, []);

    useEffect(() => {
        if (!id) return;

        // Fetch the selected product
        const matchingProduct = products.find((product) => Number(product.id) === Number(id));
        if (matchingProduct) {
            setSelectedProduct(matchingProduct);
            setProductPrice(prev => ({ ...prev, product_id: matchingProduct.id }));
        }

        // Fetch prices for the selected product
        const fetchPrices = async () => {
            try {
                const prices = await fetchPriceByProductId(Number(id));
                if (prices) {
                    setCurrentProductPriceList(prices.map(price => ({
                        product_id: price.product_id,
                        supplier_id: price.supplier_id,
                        price: price.price,
                        remarks: price.remarks ?? null, // Ensure remarks is never undefined
                    })));
                }
            } catch (err) {
                showErrorToast('Failed to fetch product prices.');
            }
        };

        fetchPrices();
    }, [id, products]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setProductPrice(prev => ({ ...prev, [name]: value }));
    };

    const handleAddToTable = () => {
        const { supplier_id, price, remarks } = productPrice;

        if (!supplier_id || !price) {
            showErrorToast('Please fill in all fields before adding to table.');
            return;
        }

        setProductPrice({
            product_id: selectedProduct?.id!,
            supplier_id: null,
            price: '',
            remarks: '',
        });

        setProductPriceList(prevList => [...prevList, productPrice]);
    };

    const handleSaveAll = async () => {
        try {
            showLoadingToast(`Saving all items...`, 100);
            const res = await addMultiplePrice(productPriceList);
            if (res!.dbsuccess) {
                setTimeout(() => {
                    showSuccessToast(`All items saved successfully.`);
                    navigate('/gp/inventories/products');
                }, 1000);
            } else {
                showErrorToast(`Failed adding product items data`);
            }
        } catch (err) {
            showErrorToast(`Failed to save product items: ${err}`);
        }
    };

    const handleDeleteItem = (index: number) => {
        setProductPriceList(prevList => prevList.filter((_, i) => i !== index));
    };

    const handleOpenEditDialog = (index: number) => {
        const itemToEdit = productPriceList[index];
        setEditForm({
            price: itemToEdit.price,
            remarks: itemToEdit.remarks,
        });
        setEditItemIndex(index);
        setIsOpen(true);
    };

    const handleEditSubmit = () => {
        if (editItemIndex !== null) {
            const updatedItems = [...productPriceList];
            updatedItems[editItemIndex] = {
                ...updatedItems[editItemIndex],
                ...editForm
            };
            setProductPriceList(updatedItems);
            setIsOpen(false);
        }
    };

    const handleEditFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setEditForm(prevForm => ({ ...prevForm, [name]: value }));
    };

    const selectSupplierOptions = suppliers.map((supplier: SetupUsergroup) => ({
        value: supplier.id!,
        label: `${supplier.fullname || "-"}`,
    }));

    const handleSupplierChange = (newValue: SingleValue<{ value: number; label: string }>) => {
        if (newValue) {
            setProductPrice((prev) => ({ ...prev, supplier_id: Number(newValue.value), supplier_fullname: newValue.label }));
        }
    };

    return (
        <form method="post" className="mx-auto max-w-6xl">
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
                <Heading>Add Product Price</Heading>
                <div className="mt-5 flex lg:ml-4 lg:mt-0">
                    <Button type="button" outline onClick={() => navigate(-1)}>
                        Cancel
                    </Button>
                    <Button type="button" className="ml-3" onClick={handleSaveAll} disabled={productPriceList.length === 0}>
                        Save All
                    </Button>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Product Information</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            Individual units or variations of a product.
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                            <Subheading>Product Barcode</Subheading>
                            <label className='text-zinc-400'>{selectedProduct?.barcode}</label>
                        </div>

                        <div className="sm:col-span-full">
                            <Subheading>Description</Subheading>
                            <label className='text-zinc-400'>{selectedProduct?.description}</label>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Supplier</Subheading>
                                </div>
                                <div className="mt-2">
                                    <ReactSelect
                                        id="supplier_id"
                                        name="supplier_id"
                                        options={selectSupplierOptions}
                                        className="text-sm"
                                        placeholder="Select Supplier"
                                        onChange={handleSupplierChange}
                                        value={selectSupplierOptions.find(option => Number(option.value) === Number(productPrice.supplier_id!))}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Price</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Price"
                                        name="price"
                                        value={productPrice.price!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Remark</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Remarks"
                                        name="remarks"
                                        value={productPrice.remarks!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-end gap-4 my-6">
                <Button type="button" onClick={handleAddToTable}>
                    Add to Table
                </Button>
            </div>

            <div>
                <Table className="[--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
                    <TableHead>
                        <TableRow>
                            <TableHeader>S/N</TableHeader>
                            <TableHeader>Supplier</TableHeader>
                            <TableHeader>Price</TableHeader>
                            <TableHeader>Remark</TableHeader>
                            <TableHeader>Actions</TableHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(productPriceList.length > 0) ? productPriceList.map((item, index) => (
                            <TableRow key={index} title={`${index}`}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{item.supplier_fullname}</TableCell>
                                <TableCell>{item.price}</TableCell>
                                <TableCell className="text-zinc-500">{item.remarks}</TableCell>
                                <TableCell className="py-5 flex gap-4">
                                    <a onClick={() => handleOpenEditDialog(index)} className="text-zinc-600 hover:text-zinc-900">
                                        <PencilIcon className='h-5 w-5' />
                                    </a>
                                    <a onClick={() => handleDeleteItem(index)} className="text-zinc-600 hover:text-zinc-900">
                                        <TrashIcon className='h-5 w-5' />
                                    </a>
                                </TableCell>
                            </TableRow>
                        )) : <TableRow>
                            <TableCell colSpan={9} className="text-center py-8">No price found.</TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
            </div>

            {/* Edit Product Item Dialog */}
            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                <DialogTitle>Edit Product Item</DialogTitle>
                <DialogDescription>
                    You can edit the information of the product item.
                </DialogDescription>
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 mt-4">
                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>Price</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    aria-label="Price"
                                    type="text"
                                    name="price"
                                    value={editForm.price || ''}
                                    onChange={handleEditFormChange}
                                />
                            </div>
                        </section>
                    </div>
                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>Remark</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    aria-label="Remark"
                                    type="text"
                                    name="remark"
                                    value={editForm.remarks || ''}
                                    onChange={handleEditFormChange}
                                />
                            </div>
                        </section>
                    </div>
                </div>
                <DialogActions>
                    <Button plain onClick={() => setIsOpen(false)}>
                        Cancel
                    </Button>
                    <Button onClick={handleEditSubmit}>Save</Button>
                </DialogActions>
            </Dialog>
        </form>
    );
};

export default AddWarehouseProductPriceListPage;