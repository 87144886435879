import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppUser } from '../../../../models/AppUser';
import useUserService from '../../../../hooks/useUserService';
import { useWarehouses } from '../../../../hooks/useWarehouse';
import { useAppUser } from '../../../../hooks/useAppUser';
import showErrorToast from '../../../../components/Toasts/ShowErrorToast';
import showLoadingToast from '../../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../../components/Toasts/ShowSuccessToast';
import { Heading, Subheading } from '../../../../components/Heading';
import { Button } from '../../../../components/Button';
import { Divider } from '../../../../components/Divider';
import { Input } from '../../../../components/Input';
import { Switch, SwitchField, SwitchGroup } from '../../../../components/Switch';
import { Select } from '../../../../components/Select';
import { Text } from '../../../../components/Text';
import { AppAnnouncement } from '../../../../models/AppAnnouncement';
import { useAppAnnouncements } from '../../../../hooks/useAppAnnouncement';
import { Textarea } from '../../../../components/Textarea';

const EditAppAnnouncementPage = () => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>(); // Assuming `id` is passed as a URL parameter

    const [announcement, setAnnouncement] = useState<AppAnnouncement | null>(null);
    const { fetchAnnouncementById, loading, error, updateAnnouncement } = useAppAnnouncements();
    const { warehouses } = useWarehouses();
    const [isAdmin, setIsAdmin] = useState(false)

    useEffect(() => {
        document.title = "Edit App Announcement";
    }, []);

    useEffect(() => {
        const fetchAnnouncementDetails = async () => {
            try {
                const fetchedAnnouncement = await fetchAnnouncementById(Number(id));
                if (fetchedAnnouncement) {
                    setAnnouncement(fetchedAnnouncement);
                }
            } catch (err) {
                showErrorToast('Failed to fetch user details.');
            }
        };

        fetchAnnouncementDetails();
    }, [id]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setAnnouncement(prev => prev ? { ...prev, [name]: value } : null);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!announcement) return;
        if (!announcement.title || !announcement.content) {
            showErrorToast("Please fill in all field before submit");
            return;
        }

        try {
            const announcementToUpdate = {
                ...announcement,
                created_user_id: announcement.created_user_id!.toString(),
            };

            const res = await updateAnnouncement(announcement.id, announcementToUpdate);
            if (res.dbsuccess) {
                showLoadingToast(`Updating Announcement ...`, 100);
                setTimeout(() => {
                    navigate('/gp/app/announcements');
                    showSuccessToast(`Announcements updated successfully`);
                }, 1000);
            } else {
                showErrorToast(`Error updating announcement`);
            }
        } catch (err) {
            showErrorToast('Failed to update announcement.');
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;
    if (!announcement) return <p>Announcement not found.</p>;
    if (!warehouses) return <p>Warehouses not found.</p>;

    return (
        <form method="post" onSubmit={handleSubmit} className="mx-auto max-w-6xl">
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
                <Heading>Edit App Announcement</Heading>
                <div className="mt-5 flex lg:ml-4 lg:mt-0">
                    <Button type="reset" outline onClick={() => navigate(-1)}>
                        Cancel
                    </Button>
                    <Button type="submit" className="ml-3" disabled={(!announcement.title || !announcement.content)}>Save</Button>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Announcement Details</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            A brief summary highlighting key personal details.
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Title</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        type="text"
                                        aria-label="Title"
                                        name="title"
                                        value={announcement.title?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Content</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Textarea
                                        aria-label="Content"
                                        name="content"
                                        value={announcement.content?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>
                    </div>
                </div>

            </div>

            <div className="flex justify-end gap-4 my-6">
                <Button type="reset" plain onClick={() => navigate(-1)}>
                    Cancel
                </Button>
                <Button type="submit" disabled={(!announcement.title || !announcement.content)}>Save</Button>
            </div>
        </form>
    );
};

export default EditAppAnnouncementPage;
