import axios from 'axios';
import { AppTrackLocation } from '../models/AppTrackLocation';

const API_URL = process.env.REACT_APP_SERVER_URL + "/app/track/locations";

export const AppTrackLocationService = {
    getAllTrackLocations: async (): Promise<AppTrackLocation[]> => {
        const response = await axios.get(`${API_URL}/getAllLocations`);
        return response.data;
    },

    getTrackLocationById: async (id: number): Promise<AppTrackLocation> => {
        const response = await axios.get(`${API_URL}/getLocationById/${id}`);
        return response.data;
    },

    createTrackLocation: async (trackLocation: Omit<AppTrackLocation, 'id' | 'created_time' | 'updated_time'>) => {
        const response = await axios.post(`${API_URL}/add`, trackLocation);
        return response.data;
    },

    updateTrackLocation: async (id: number, trackLocation: Omit<AppTrackLocation, 'id' | 'created_time' | 'updated_time'>) => {
        const response = await axios.put(`${API_URL}/update/${id}`, trackLocation);
        return response.data;
    },

    deleteTrackLocation: async (id: number): Promise<void> => {
        await axios.delete(`${API_URL}/delete/${id}`);
    }
};
