// services/WarehouseProductService.ts
import axios from 'axios';
import { WarehouseProduct } from '../models/WarehouseProduct';

const API_URL = process.env.REACT_APP_SERVER_URL + "/warehouseproduct";

export const WarehouseProductService = {

    getAllProducts: async (limit: number, offset: number, searchQuery: string, filterStartDate: string, filterEndDate: string, filterYear: string, filterWarehouseId: string) => {
        const response = await axios.get(`${API_URL}/getAllProducts`, {
            params: { limit, offset, searchQuery, filterStartDate, filterEndDate, filterYear, filterWarehouseId },
        });
        return response.data;
    },

    getAllProductsByWarehouseId: async (warehouseId: string, limit: number, offset: number, searchQuery: string, filterStartDate: string, filterEndDate: string, filterYear: string, filterWarehouseId: string) => {
        const response = await axios.get(`${API_URL}/getAllProductsByWarehouseId/${warehouseId}`, {
            params: { limit, offset, searchQuery, filterStartDate, filterEndDate, filterYear, filterWarehouseId },
        });
        return response.data;
    },

    getAllProductsStockRemind: async () => {
        const response = await axios.get(`${API_URL}/getAllProductsStockRemind`);
        return response.data;
    },

    getAllProductsStockRemindByWarehouseId: async (warehouseId: string) => {
        const response = await axios.get(`${API_URL}/getAllProductsStockRemindByWarehouseId/${warehouseId}`);
        return response.data;
    },

    getProductById: async (id: number): Promise<WarehouseProduct> => {
        const response = await axios.get(`${API_URL}/getProductById/${id}`);
        return response.data;
    },

    createProduct: async (product: Omit<WarehouseProduct, 'id' | 'created_time' | 'updated_time'>) => {
        const response = await axios.post(`${API_URL}/add`, product);
        return response.data;
    },

    updateProduct: async (id: number, product: Omit<WarehouseProduct, 'created_time' | 'updated_time'>) => {
        const response = await axios.put(`${API_URL}/edit/${id}`, product);
        return response.data;
    },

    deleteProduct: async (id: number): Promise<void> => {
        await axios.delete(`${API_URL}/delete/${id}`);
    }
};
