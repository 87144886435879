import React, { useContext, useEffect, useState } from 'react';
import { Button } from '../../../components/Button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../components/Table';
import { useNavigate } from 'react-router-dom';
import { useAppPreCallPlans } from '../../../hooks/useAppPreCallPlan';
import { EyeIcon } from '@heroicons/react/16/solid';
import dateFormat from 'dateformat';
import { Input, InputGroup } from '../../../components/Input';
import { Heading } from '../../../components/Heading';
import Loading from '../../../components/Loading';

const AppPreCallPlanPage: React.FC = () => {
    const { loading, error, fetchPreCallPlans, deletePreCallPlan, preCallPlans } = useAppPreCallPlans();
    const [searchQuery, setSearchQuery] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Pre Call Plans";
        fetchPreCallPlans();
    }, [fetchPreCallPlans]);

    const filteredPreCallPlans = preCallPlans.filter(plan =>
        plan.key_message.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleDelete = async (id: number) => {
        setIsDeleting(true);
        try {
            await deletePreCallPlan(id);
            fetchPreCallPlans();
        } catch (error) {
            console.error('Failed to delete pre call plan:', error);
        } finally {
            setIsDeleting(false);
        }
    };

    return (
        <div>
            <div className="flex flex-wrap items-end justify-between gap-4">
                <div className="max-sm:w-full sm:flex-1">
                    <Heading>Pre Call Plans</Heading>
                    <div className="mt-4 flex max-w-xl gap-4">
                        <div className="flex-1">
                            <InputGroup>
                                <Input
                                    name="search"
                                    placeholder="Search pre call plans&hellip;"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </InputGroup>
                        </div>
                    </div>
                </div>
            </div>

            <ul className="mt-10">
                {loading && <div className="w-full text-center h-full py-36 justify-center"><Loading /></div>}
                {error && <p className="py-16 text-center text-red-700">{error}</p>}
                {(!loading && !error) && (
                    <div>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableHeader>S/N</TableHeader>
                                    <TableHeader>Salesman</TableHeader>
                                    <TableHeader>Customer</TableHeader>
                                    <TableHeader>Created On</TableHeader>
                                    <TableHeader className="text-center">Actions</TableHeader>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredPreCallPlans.length > 0 ? filteredPreCallPlans.map((plan, index) => (
                                    <TableRow key={plan.id}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{plan.salesman_username}</TableCell>
                                        <TableCell>{plan.customer_fullname}</TableCell>
                                        <TableCell>{dateFormat(plan.created_time, "mmm dd, yyyy")}</TableCell>
                                        <TableCell className="py-5 flex gap-4 text-zinc-500 justify-center">

                                            <a onClick={() => navigate(`/gp/app/precallplans/view/${plan.id}`)} className="text-zinc-600 hover:text-zinc-900">
                                                <EyeIcon className='h-5 w-5' />
                                            </a>
                                        </TableCell>
                                    </TableRow>
                                )) : (
                                    <TableRow>
                                        <TableCell colSpan={4} className="text-center py-8">No pre call plan found.</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                )}
            </ul>
        </div>

    );
};

export default AppPreCallPlanPage;
