import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { AppUserCurrentLocationService } from '../../../../services/appUserCurrentLocationService';
import GoogleMap from 'google-maps-react-markers';
import { ChevronLeftIcon } from '@heroicons/react/16/solid';
import { Avatar } from '../../../../components/Avatar';
import { Heading } from '../../../../components/Heading';
import Loading from '../../../../components/Loading';
import { AppUserCurrentLocation } from '../../../../models/AppUserCurrentLocation';
import dateFormat from 'dateformat';

// Default center (will be overridden by the first location)
const defaultCenter = {
    lat: 0,
    lng: 0,
};

// Map options
const mapOptions = {
    mapTypeControl: false, // Disable satellite view
    streetViewControl: false, // Disable street view
    fullscreenControl: false, // Disable fullscreen control
};

// Custom Marker Component
interface MarkerProps {
    className?: string;
    draggable: boolean;
    lat: number;
    lng: number;
    markerId: string;
    createdTime: string; // Add createdTime prop
    onClick?: (
        e: React.MouseEvent<HTMLImageElement, MouseEvent>,
        props: { lat: number; lng: number; markerId: string; createdTime: string },
    ) => void;
    onDrag?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>, props: { latLng: google.maps.LatLngLiteral }) => void;
    onDragEnd?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>, props: { latLng: google.maps.LatLngLiteral }) => void;
    onDragStart?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>, props: { latLng: google.maps.LatLngLiteral }) => void;
}

const Marker = ({
    className,
    lat,
    lng,
    markerId,
    createdTime,
    onClick,
    draggable,
    onDrag,
    onDragEnd,
    onDragStart,
    ...props
}: MarkerProps) =>
    lat && lng ? (
        <img
            className={className}
            src={'/images/locationmarker2.png'} // Red marker icon
            onClick={(e) => (onClick ? onClick(e, { markerId, lat, lng, createdTime }) : null)}
            style={{ fontSize: 40 }}
            alt={markerId}
            width={35}
            height={35}
            {...props}
        />
    ) : null;

// Main Page Component
const ViewAppUserCurrentLocationIdPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [locations, setLocations] = useState<AppUserCurrentLocation[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const mapRef = useRef<google.maps.Map | null>(null);
    const [mapReady, setMapReady] = useState<boolean>(false);

    // Calculate the default center based on the first location
    const calculateDefaultCenter = () => {
        if (locations.length > 0) {
            const lat = parseFloat(locations[0].latitude);
            const lng = parseFloat(locations[0].longitude);
            if (!isNaN(lat) && !isNaN(lng)) {
                return { lat, lng };
            }
        }
        return defaultCenter; // Fallback to default center if no valid location
    };

    useEffect(() => {
        const fetchLocations = async () => {
            try {
                const data = await AppUserCurrentLocationService.getAllLocationsByUserIdToday(Number(id));
                setLocations(data);
            } catch (err) {
                setError('Failed to fetch locations');
            } finally {
                setLoading(false);
            }
        };

        fetchLocations();
    }, [id]);

    /**
     * @description This function is called when the map is ready
     * @param {Object} map - reference to the map instance
     * @param {Object} maps - reference to the maps library
     */
    const onGoogleApiLoaded = ({ map, maps }: { map: google.maps.Map; maps: typeof google.maps }) => {
        mapRef.current = map;
        setMapReady(true);
    };

    /**
     * @description Handle marker click events
     * @param {Event} e - the click event
     * @param {Object} marker - the marker object (contains markerId, lat, lng, createdTime)
     */
    const onMarkerClick = (
        e: React.MouseEvent<HTMLImageElement, MouseEvent>,
        { markerId, lat, lng, createdTime }: { markerId: string; lat: number; lng: number; createdTime: string },
    ) => {
        console.log('Marker clicked:', markerId);
        alert(`Created Time: ${dateFormat(createdTime, "mmmm dd, yyyy hh:mm:ss")}`); // Show created_time in an alert
        if (mapRef.current) {
            mapRef.current.setCenter({ lat, lng }); // Center the map on the clicked marker
        }
    };

    if (loading) return <Loading />;
    if (error) return <div>Error: {error}</div>;

    return (
        <>
            <div className="max-lg:hidden">
                <Link to="/gp/app/track/usercurrentlocation" className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400">
                    <ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500" />
                    App User Tracking
                </Link>
            </div>
            <div className="mt-4 lg:mt-8">
                <div className="flex items-center gap-4">
                    <Avatar square initials={'A'} className="bg-zinc-300 text-gray-700 size-8" />
                    <Heading>User Location</Heading>
                </div>
            </div>
            <div className="mt-12">
                {(locations.length > 0) ?
                    <div>
                        <GoogleMap
                            apiKey="AIzaSyCSD7KL52NApFty16LDdDkzc7S_eKrYw6M" // Replace with your Google Maps API key
                            defaultCenter={calculateDefaultCenter()} // Set default center dynamically
                            defaultZoom={10}
                            options={mapOptions}
                            mapMinHeight="500px"
                            onGoogleApiLoaded={onGoogleApiLoaded}
                            onChange={(map) => console.log('Map moved', map)}
                        >
                            {locations.map((location, index) => {
                                const lat = parseFloat(location.latitude);
                                const lng = parseFloat(location.longitude);
                                if (isNaN(lat) || isNaN(lng)) {
                                    console.warn(`Invalid coordinates at index ${index}:`, location);
                                    return null;
                                }
                                return (
                                    <Marker
                                        key={index}
                                        lat={lat}
                                        lng={lng}
                                        markerId={location.user_id.toString()}
                                        createdTime={location.created_time} // Pass created_time to the Marker
                                        onClick={onMarkerClick}
                                        draggable={false} // Set to true if you want markers to be draggable
                                    />
                                );
                            })}
                        </GoogleMap>
                    </div> :
                    <div>
                        <p className="py-16 text-center">No location record today found.</p>
                    </div>
                }
            </div>
        </>
    );
};

export default ViewAppUserCurrentLocationIdPage;