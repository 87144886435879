import { useState, useEffect, useCallback } from 'react';
import { AppTrackLocation } from '../models/AppTrackLocation';
import { AppTrackLocationService } from '../services/appTrackLocationService';

export const useAppTrackLocations = () => {
    const [trackLocations, setTrackLocations] = useState<AppTrackLocation[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const fetchTrackLocations = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await AppTrackLocationService.getAllTrackLocations();
            setTrackLocations(data);
        } catch (err) {
            setError('Failed to fetch track locations');
            console.error(err);
        } finally {
            setLoading(false);
        }
    }, []);

    const fetchTrackLocationById = async (id: number) => {
        setLoading(true);
        setError(null);
        try {
            const data = await AppTrackLocationService.getTrackLocationById(id);
            return data;
        } catch (err) {
            setError('Failed to fetch track location by ID.');
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const addTrackLocation = async (newTrackLocation: Omit<AppTrackLocation, 'id' | 'created_time' | 'updated_time'>) => {
        try {
            const createdTrackLocation = await AppTrackLocationService.createTrackLocation(newTrackLocation);
            setTrackLocations(prev => [...prev, createdTrackLocation]);
            return createdTrackLocation;
        } catch (err) {
            setError('Failed to add track location');
            console.error(err);
        }
    };

    const updateTrackLocation = async (id: number, updatedTrackLocation: Omit<AppTrackLocation, 'id' | 'created_time' | 'updated_time'>) => {
        try {
            const updatedRes = await AppTrackLocationService.updateTrackLocation(id, updatedTrackLocation);
            setTrackLocations(prev => prev.map(loc => (loc.id === id ? updatedRes : loc)));
            return updatedRes;
        } catch (err) {
            setError('Failed to update track location');
            console.error(err);
        }
    };

    const deleteTrackLocation = async (id: number) => {
        try {
            await AppTrackLocationService.deleteTrackLocation(id);
            setTrackLocations(prev => prev.filter(loc => loc.id !== id));
        } catch (err) {
            setError('Failed to delete track location');
            console.error(err);
        }
    };

    useEffect(() => {
        fetchTrackLocations();
    }, [fetchTrackLocations]);

    return {
        trackLocations,
        loading,
        error,
        fetchTrackLocations,
        fetchTrackLocationById,
        addTrackLocation,
        updateTrackLocation,
        deleteTrackLocation
    };
};
