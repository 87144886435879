import React, { useContext, useEffect, useState } from 'react';
import { Button } from '../../../components/Button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../components/Table';
import { useNavigate } from 'react-router-dom';
import { useAppPostCallPlans } from '../../../hooks/useAppPostCallPlan';
import { Heading } from '../../../components/Heading';
import { Input, InputGroup } from '../../../components/Input';
import dateFormat from 'dateformat';
import { EyeIcon } from '@heroicons/react/16/solid';
import Loading from '../../../components/Loading';

const AppPostCallPlanPage: React.FC = () => {
    const { loading, error, fetchPostCallPlans, deletePostCallPlan, postCallPlans } = useAppPostCallPlans();
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Post Call Plans";
        fetchPostCallPlans();
    }, [fetchPostCallPlans]);

    const filteredPostCallPlans = postCallPlans.filter(plan =>
        plan.remarks.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div>
            <div className="flex flex-wrap items-end justify-between gap-4">
                <div className="max-sm:w-full sm:flex-1">
                    <Heading>Post Call Plans</Heading>
                    <div className="mt-4 flex max-w-xl gap-4">
                        <div className="flex-1">
                            <InputGroup>
                                <Input
                                    name="search"
                                    placeholder="Search post call plans&hellip;"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </InputGroup>
                        </div>
                    </div>
                </div>
            </div>

            <ul className="mt-10">
                {loading && <div className="w-full text-center h-full py-36 justify-center"><Loading /></div>}
                {error && <p className="py-16 text-center text-red-700">{error}</p>}
                {(!loading && !error) && (
                    <div>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableHeader>S/N</TableHeader>
                                    <TableHeader>Salesman</TableHeader>
                                    <TableHeader>Created On</TableHeader>
                                    <TableHeader className="text-center">Actions</TableHeader>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredPostCallPlans.length > 0 ? filteredPostCallPlans.map((plan, index) => (
                                    <TableRow key={plan.id}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{plan.salesman_username}</TableCell>
                                        <TableCell>{dateFormat(plan.created_time, "mmm dd, yyyy")}</TableCell>
                                        <TableCell className="py-5 flex gap-4 text-zinc-500 justify-center">

                                            <a onClick={() => navigate(`/gp/app/postcallplans/view/${plan.id}`)} className="text-zinc-600 hover:text-zinc-900">
                                                <EyeIcon className='h-5 w-5' />
                                            </a>
                                        </TableCell>
                                    </TableRow>
                                )) : (
                                    <TableRow>
                                        <TableCell colSpan={4} className="text-center py-8">No post call plan found.</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                )}
            </ul>
        </div>
    );
};

export default AppPostCallPlanPage;
