import { useState, useEffect, useCallback } from 'react';
import { PreCallPlan } from '../models/AppPreCallPlan';
import { AppPreCallPlanService } from '../services/appPreCallPlanService';

export const useAppPreCallPlans = () => {
    const [preCallPlans, setPreCallPlans] = useState<PreCallPlan[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const fetchPreCallPlans = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await AppPreCallPlanService.getAllPreCallPlans();
            setPreCallPlans(data);
        } catch (err) {
            setError('Failed to fetch pre-call plans');
            console.error(err);
        } finally {
            setLoading(false);
        }
    }, []);

    const fetchPreCallPlanById = async (id: number) => {
        setLoading(true);
        setError(null);
        try {
            const data = await AppPreCallPlanService.getPreCallPlanById(id);
            return data;
        } catch (err) {
            setError('Failed to fetch pre-call plan by ID.');
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const addPreCallPlan = async (newPreCallPlan: Omit<PreCallPlan, 'id' | 'created_time' | 'updated_time'>) => {
        try {
            const createdPreCallPlan = await AppPreCallPlanService.createPreCallPlan(newPreCallPlan);
            setPreCallPlans(prev => [...prev, createdPreCallPlan]);
            return createdPreCallPlan;
        } catch (err) {
            setError('Failed to add pre-call plan');
            console.error(err);
        }
    };

    const updatePreCallPlan = async (id: number, updatedPreCallPlan: Omit<PreCallPlan, 'id' | 'created_time' | 'updated_time'>) => {
        try {
            const updatedRes = await AppPreCallPlanService.updatePreCallPlan(id, updatedPreCallPlan);
            setPreCallPlans(prev => prev.map(plan => (plan.id === id ? updatedRes : plan)));
            return updatedRes;
        } catch (err) {
            setError('Failed to update pre-call plan');
            console.error(err);
        }
    };

    const deletePreCallPlan = async (id: number) => {
        try {
            await AppPreCallPlanService.deletePreCallPlan(id);
            setPreCallPlans(prev => prev.filter(plan => plan.id !== id));
        } catch (err) {
            setError('Failed to delete pre-call plan');
            console.error(err);
        }
    };

    useEffect(() => {
        fetchPreCallPlans();
    }, [fetchPreCallPlans]);

    return {
        preCallPlans,
        loading,
        error,
        fetchPreCallPlans,
        fetchPreCallPlanById,
        addPreCallPlan,
        updatePreCallPlan,
        deletePreCallPlan
    };
};
