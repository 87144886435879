import { useState, useEffect, useCallback } from 'react';
import { PostCallPlan } from '../models/AppPostCallPlan';
import { AppPostCallPlanService } from '../services/appPostCallPlanService';

export const useAppPostCallPlans = () => {
    const [postCallPlans, setPostCallPlans] = useState<PostCallPlan[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const fetchPostCallPlans = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await AppPostCallPlanService.getAllPostCallPlans();
            setPostCallPlans(data);
        } catch (err) {
            setError('Failed to fetch post-call plans');
            console.error(err);
        } finally {
            setLoading(false);
        }
    }, []);

    const fetchPostCallPlanById = async (id: number) => {
        setLoading(true);
        setError(null);
        try {
            const data = await AppPostCallPlanService.getPostCallPlanById(id);
            return data;
        } catch (err) {
            setError('Failed to fetch post-call plan by ID.');
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const addPostCallPlan = async (newPostCallPlan: Omit<PostCallPlan, 'id' | 'created_time' | 'updated_time'>) => {
        try {
            const createdPostCallPlan = await AppPostCallPlanService.createPostCallPlan(newPostCallPlan);
            setPostCallPlans(prev => [...prev, createdPostCallPlan]);
            return createdPostCallPlan;
        } catch (err) {
            setError('Failed to add post-call plan');
            console.error(err);
        }
    };

    const updatePostCallPlan = async (id: number, updatedPostCallPlan: Omit<PostCallPlan, 'id' | 'created_time' | 'updated_time'>) => {
        try {
            const updatedRes = await AppPostCallPlanService.updatePostCallPlan(id, updatedPostCallPlan);
            setPostCallPlans(prev => prev.map(plan => (plan.id === id ? updatedRes : plan)));
            return updatedRes;
        } catch (err) {
            setError('Failed to update post-call plan');
            console.error(err);
        }
    };

    const deletePostCallPlan = async (id: number) => {
        try {
            await AppPostCallPlanService.deletePostCallPlan(id);
            setPostCallPlans(prev => prev.filter(plan => plan.id !== id));
        } catch (err) {
            setError('Failed to delete post-call plan');
            console.error(err);
        }
    };

    useEffect(() => {
        fetchPostCallPlans();
    }, [fetchPostCallPlans]);

    return {
        postCallPlans,
        loading,
        error,
        fetchPostCallPlans,
        fetchPostCallPlanById,
        addPostCallPlan,
        updatePostCallPlan,
        deletePostCallPlan
    };
};
