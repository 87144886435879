import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Heading, Subheading } from '../../../../../components/Heading';
import { Divider } from '../../../../../components/Divider';
import { Input } from '../../../../../components/Input';
import { Select } from '../../../../../components/Select';
import { Button } from '../../../../../components/Button';
import showLoadingToast from '../../../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../../../components/Toasts/ShowSuccessToast';
import showErrorToast from '../../../../../components/Toasts/ShowErrorToast';
import { useStockOutItems } from '../../../../../hooks/useStockOutItem'; // Custom hook for Stock-Out items
import { useWarehouseProductItem } from '../../../../../hooks/useWarehouseProductItem';
import { UserContext } from '../../../../../context/userContext';
import { Text } from '../../../../../components/Text';
import { Dialog, DialogActions, DialogDescription, DialogTitle } from '../../../../../components/Dialog';
import { useStockOut } from '../../../../../hooks/useStockOut';
import { StockOut } from '../../../../../models/StockOut';
import { WarehouseProductItem } from '../../../../../models/WarehouseProductItem';
import { useWarehouseProductItemBatchNo } from '../../../../../hooks/useWarehouseProductItemBatchNo';
import { WarehouseProductItemBatchNo } from '../../../../../models/WarehouseProductItemBatchNo';
import ReactSelect, { SingleValue } from 'react-select';
import { useWarehouseProduct } from '../../../../../hooks/useWarehouseProduct';
import { WarehouseProduct } from '../../../../../models/WarehouseProduct';

interface AddStockOutItem {
    product_id: number | null;
    quantity: string | null;
    batch_no: string | null;
    stock_out_id: number | null;
    expiry_date: string | null;
}

const AddStockOutItemPageId = () => {
    const id = useParams<{ id: string }>();
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [stockOutItem, setStockOutItem] = useState<AddStockOutItem>({
        product_id: Number(0),
        quantity: '',
        batch_no: '',
        stock_out_id: Number(0),
        expiry_date: '',
    });
    const [stockOutItemsList, setStockOutItemsList] = useState<AddStockOutItem[]>([]); // To store added Stock-Out items
    const { createMultipleStockOutItems } = useStockOutItems(); // Hook for creating multiple Stock-Out items
    const { stockOuts } = useStockOut();
    const { products } = useWarehouseProduct();

    const [isOpen, setIsOpen] = useState(false); // Manage the edit dialog state
    const [editItemIndex, setEditItemIndex] = useState<number | null>(null); // Track which item is being edited
    const [editForm, setEditForm] = useState<Partial<AddStockOutItem>>({
        quantity: '',
        batch_no: '',
        expiry_date: '',
    });
    const [selectedStockOut, setSelectedStockOut] = useState<StockOut | null>(null);
    const { fetchBatchNoByProductItemId } = useWarehouseProductItemBatchNo();
    const [batchNos, setBatchNos] = useState<WarehouseProductItemBatchNo[]>([]);

    useEffect(() => {
        document.title = "Add Stock out items";
    }, []);

    useEffect(() => {
        if (!id) return;

        setStockOutItem(prev => ({ ...prev, stock_out_id: Number(id) }));

        const matchingStockOut = stockOuts.find(stockOut => Number(stockOut.id) === Number(id.id));
        if (matchingStockOut) {
            setSelectedStockOut(matchingStockOut);
            setStockOutItem(prev => ({ ...prev, stock_out_id: matchingStockOut.id }));
        }

    }, [stockOuts]);

    useEffect(() => {
        if (products.length > 0 && !stockOutItem.product_id) {
            setStockOutItem(prev => ({ ...prev, product_id: Number(products[0].id!) }));
        }
    }, [products]);

    const fetchBatchNoDetails = async (productItemId: number) => {
        try {
            const fetchBatchNo = await fetchBatchNoByProductItemId(productItemId);
            setBatchNos(fetchBatchNo || []);
        } catch (err) {
            showErrorToast(`Error fetching batch nos ${err}`);
        }
    }

    useEffect(() => {
        if (stockOutItem.product_id) {
            fetchBatchNoDetails(stockOutItem.product_id);
        }
    })

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setStockOutItem(prev => ({ ...prev, [name]: value }));

        if (name === 'batch_no') {
            const selectedBatch = batchNos.find(batch => batch.batch_no === value);
            if (selectedBatch) {
                setStockOutItem(prev => ({ ...prev, expiry_date: selectedBatch.expiry_date }));
            }
        }
    };

    // Add the current Stock-Out item to the table
    const handleAddToTable = () => {
        if (!stockOutItem.product_id || !stockOutItem.batch_no || !stockOutItem.expiry_date || !stockOutItem.quantity) {
            showErrorToast("Please fill in all field before add to table");
            return;
        }

        const { quantity, batch_no, expiry_date } = stockOutItem;
        // Validation: Ensure that quantity, batch_no, and expiry_date are not empty
        if (!quantity || !batch_no || !expiry_date) {
            showErrorToast('Please fill in Quantity, Batch No, Expiry Date before adding to table.');
            return;
        }

        setStockOutItem({
            product_id: Number(0),
            quantity: '',
            batch_no: '',
            stock_out_id: Number(selectedStockOut!.id),
            expiry_date: '',
        }); // Reset the form fields after adding

        if (products.length > 0) {
            setStockOutItem(prev => ({ ...prev, product_id: Number(products[0].id!) }));
        }

        setStockOutItemsList(prevList => [...prevList, stockOutItem]);
    };

    // Save all stock-in items in the table
    const handleSaveAll = async () => {
        try {
            showLoadingToast(`Saving all items...`, 100);
            const res = await createMultipleStockOutItems(stockOutItemsList); // Save all items
            if (res!.dbsuccess) {
                setTimeout(() => {
                    showSuccessToast(`All items saved successfully.`);
                    navigate('/gp/stocks/stockout'); // Redirect after saving
                }, 1000);
            } else {
                showErrorToast(`Failed adding stock-out items data`);
            }
        } catch (err) {
            showErrorToast(`Failed to save stock-in items: ${err}`);
        }
    };

    // Delete a Stock-Out item by index
    const handleDeleteItem = (index: number) => {
        setStockOutItemsList(prevList => prevList.filter((_, i) => i !== index));
    };

    // Open the edit dialog and prefill form with current item data
    const handleOpenEditDialog = (index: number) => {
        const itemToEdit = stockOutItemsList[index];
        setEditForm({
            quantity: itemToEdit.quantity,
            batch_no: itemToEdit.batch_no,
            expiry_date: itemToEdit.expiry_date,
        });
        setEditItemIndex(index); // Set the index of the item being edited
        setIsOpen(true); // Open the dialog
    };

    // Update the item in the StockOutItemsList
    const handleEditSubmit = () => {
        if (editItemIndex !== null) {
            const updatedItems = [...stockOutItemsList];
            updatedItems[editItemIndex] = {
                ...updatedItems[editItemIndex],
                ...editForm
            };
            setStockOutItemsList(updatedItems); // Update the list with the edited item
            setIsOpen(false); // Close the dialog
        }
    };

    const handleEditFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setEditForm(prevForm => ({ ...prevForm, [name]: value }));
    };

    const selectProductOptions = products.map((product: WarehouseProduct) => ({
        value: product.id,
        label: `${product.item_no || "-"}  ${product.second_item_no || "-"} : ${product.description || "-"}`,
    }));

    // Handle ReactSelect change
    const handleProductChange = (newValue: SingleValue<{ value: number; label: string }>) => {
        if (newValue) {
            setStockOutItem((prev) => ({ ...prev, product_id: Number(newValue.value) }));
        }
    };

    return (
        <form method="post" className="mx-auto max-w-6xl">
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
                <Heading>Add Stock-Out Item</Heading>
                <div className="mt-5 flex lg:ml-4 lg:mt-0">
                    <Button type="button" outline onClick={() => navigate(-1)}>
                        Cancel
                    </Button>
                    <Button type="button" className="ml-3" onClick={handleSaveAll} disabled={stockOutItemsList.length === 0}>
                        Save All
                    </Button>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Stock-Out Item Information</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            Details of items dispatched from stock.
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                            <Subheading>Stock Out Reference No</Subheading>
                            <label className='text-zinc-400'>{selectedStockOut?.reference_no}</label>
                        </div>

                        <div className="sm:col-span-full">
                            <Subheading>Customer</Subheading>
                            <label className='text-zinc-400'>{selectedStockOut?.customer_fullname}</label>
                        </div>

                        <div className="sm:col-span-full">
                            <Subheading>Invoice No</Subheading>
                            <label className='text-zinc-400'>{selectedStockOut?.invoice_no}</label>
                        </div>

                        {/* <div className="sm:col-span-full">
                            <section>
                                <div className="space-y-1">
                                    <Subheading>Product</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Select
                                        aria-label="Product"
                                        name="product_id"
                                        value={stockOutItem.product_id!.toString()}
                                        onChange={handleChange}
                                    >
                                        {productItems.map(product => (
                                            <option key={product.id} value={product.id!.toString()}>
                                                {product.item_no}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                            </section>
                        </div> */}


                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Product</Subheading>
                                </div>
                                <div className="mt-2">
                                    <ReactSelect
                                        id="department_id"
                                        name="department_id"
                                        options={selectProductOptions}
                                        className="text-sm"
                                        placeholder="Select Product Item"
                                        onChange={handleProductChange}
                                        value={selectProductOptions.find(option => Number(option.value) === Number(stockOutItem.product_id!))}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section>
                                <div className="space-y-1">
                                    <Subheading>Quantity</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Quantity"
                                        type="number"
                                        name="quantity"
                                        value={stockOutItem.quantity!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section>
                                <div className="space-y-1">
                                    <Subheading>Batch No</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Select
                                        aria-label="Batch No"
                                        name="batch_no"
                                        value={stockOutItem.batch_no || ''}
                                        onChange={handleChange}
                                    >
                                        <option value="" disabled>Select Batch No</option>
                                        {batchNos.map(batch => (
                                            <option key={batch.batch_no} value={batch.batch_no}>
                                                {batch.batch_no}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section>
                                <div className="space-y-1">
                                    <Subheading>Expiry Date</Subheading>
                                </div>
                                <Input
                                    type="date"
                                    aria-label="expiry_date"
                                    name="expiry_date"
                                    value={stockOutItem.expiry_date || ''}
                                    onChange={handleChange}
                                    disabled
                                />
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-end gap-4 my-6">
                <Button type="button" onClick={handleAddToTable} disabled={(!stockOutItem.product_id || !stockOutItem.batch_no || !stockOutItem.expiry_date || !stockOutItem.quantity)}>
                    Add to Table
                </Button>
            </div>

            {/* Table to display added Stock-Out items */}
            <div className="mt-8">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                        <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                Product Barcode
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Description
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Quantity
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                UOM
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Packing
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Batch No
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Expiry Date
                            </th>
                            <th scope="col" className="relative py-3.5 pl-1 pr-2 sm:pr-0">
                                <span className="sr-only">Actions</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {(stockOutItemsList.length > 0) ? stockOutItemsList.map((item, index) => (
                            <tr key={index}>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {products.find(p => BigInt(p.id) === BigInt(item.product_id!))?.item_no}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {products.find(p => BigInt(p.id) === BigInt(item.product_id!))?.description}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {item.quantity}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {products.find(p => BigInt(p.id) === BigInt(item.product_id!))?.uom}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {products.find(p => BigInt(p.id) === BigInt(item.product_id!))?.packing}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {item.batch_no}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {item.expiry_date}
                                </td>
                                <td className="relative whitespace-nowrap py-4 pr-4 text-center text-sm font-medium sm:pr-0 grid grid-cols-2 gap-1.5">
                                    <Button plain className="text-indigo-600 hover:text-indigo-900" onClick={() => handleOpenEditDialog(index)}>
                                        Edit
                                    </Button>
                                    <Button plain
                                        className="text-indigo-600 hover:text-indigo-900"
                                        onClick={() => handleDeleteItem(index)}
                                    >
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        )) : <tr><td><Text className='py-4'>No Stock-Out items found</Text></td></tr>}
                    </tbody>
                </table>
            </div>
            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                <DialogTitle>Edit Stock Out Item</DialogTitle>
                <DialogDescription>
                    You can edit the quantity, batch no, and expiry date of the Stock-Out item.
                </DialogDescription>
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 mt-4">
                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>Quantity</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    aria-label="Edit Quantity"
                                    type="number"
                                    name="quantity"
                                    value={editForm.quantity || ''}
                                    onChange={handleEditFormChange}
                                />
                            </div>
                        </section>
                    </div>
                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>Batch Number</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    aria-label="Edit Batch No"
                                    type="text"
                                    name="batch_no"
                                    value={editForm.batch_no || ''}
                                    onChange={handleEditFormChange}
                                />
                            </div>
                        </section>
                    </div>
                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>Expiry Date</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    aria-label="Edit Expiry Date"
                                    type="date"
                                    name="expiry_date"
                                    value={editForm.expiry_date || ''}
                                    onChange={handleEditFormChange}
                                />
                            </div>
                        </section>
                    </div>
                </div>
                <DialogActions>
                    <Button plain onClick={() => setIsOpen(false)}>
                        Cancel
                    </Button>
                    <Button onClick={handleEditSubmit}>Save</Button>
                </DialogActions>
            </Dialog>
        </form>
    );
};

export default AddStockOutItemPageId;
