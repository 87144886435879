import React, { useEffect, useState } from 'react';
import { BanknotesIcon, CalendarIcon, ChevronLeftIcon, CreditCardIcon } from '@heroicons/react/16/solid';
import { Link, useNavigate, useParams } from 'react-router-dom'; // useParams to get route params
import { Heading, Subheading } from '../../../../components/Heading';
import { Badge } from '../../../../components/Badge';
import { Button } from '../../../../components/Button';
import { Divider } from '../../../../components/Divider';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from '../../../../components/Description-List';
import { Avatar } from '../../../../components/Avatar';
import { UserService } from '../../../../services/userService'; // Import the UserService
import Loading from '../../../../components/Loading';
import dateFormat from 'dateformat';
import showErrorToast from '../../../../components/Toasts/ShowErrorToast';
import { SetupBrand } from '../../../../models/SetupBrand';
import { useSetupBrand } from '../../../../hooks/useSetupBrand';
import { useWarehouseProductItem } from '../../../../hooks/useWarehouseProductItem';
import { WarehouseProductItem } from '../../../../models/WarehouseProductItem';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '../../../../components/Dropdown';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { useWarehouseProduct } from '../../../../hooks/useWarehouseProduct';
import { WarehouseProduct } from '../../../../models/WarehouseProduct';

const ReportStockBalanceIdPage: React.FC = () => {
    const { jsPDF } = require('jspdf');
    require('jspdf-autotable');
    const { id } = useParams<{ id: string }>(); // Use useParams to get the id from the URL
    const navigate = useNavigate();
    const { fetchProductById, loading, error } = useWarehouseProduct();
    const [productItem, setProductItem] = useState<WarehouseProduct>();

    useEffect(() => {
        const fetchStockBalanceDetails = async () => {
            if (!id) return; // Ensure ID is valid before making the request

            try {
                const fetchedProduct = await fetchProductById(Number(id));
                setProductItem(fetchedProduct);
            } catch (err) {
                showErrorToast('Failed to fetch product item details.');
            }
        };
        fetchStockBalanceDetails();
    }, [id]);

    useEffect(() => {
        document.title = "View Stock Balance";
    }, []);

    const formatDate = (date: Date): string => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    // Function to generate PDF
    const generatePDF = () => {
        // Check if productItem exists and is valid
        if (!productItem) {
            showErrorToast("PDF generation failed. Product item is not available.");
            return; // Exit the function early if productItem is undefined
        }

        const doc = new jsPDF();
        doc.setFontSize(12);
        doc.text('Stock Balance Report', 14, 10);

        // Check if productItem properties are available, avoid crashing the app if they are undefined
        const tableBody = [
            1,
            productItem.sku || "N/A",
            productItem.item_no || "N/A",
            productItem.second_item_no || "N/A",
            productItem.description || "No description",
            productItem.minimum_quantity || 0,  // Default to 0 if missing
            productItem.current_quantity || 0  // Default to 0 if missing
        ];

        doc.autoTable({
            head: [['S/N', 'CCode', 'SKU', 'Item No', 'Second Item No', 'Description', 'Minimum Quantity', 'Current Quantity']],
            body: [tableBody] // Wrap the array inside another array to match the expected structure
        });

        const today = formatDate(new Date());
        doc.save(`stock_balance_report_${today}.pdf`);
    };

    // Function to generate CSV
    const generateCSV = () => {
        if (!productItem) {
            showErrorToast("CSV generation failed. Product item is not available.");
            return; // Exit the function early if productItem is undefined
        }
        const csvData = [
            ['S/N', 'CCode', 'SKU', 'Item No', 'Second Item No', 'Description', 'Minimum Quantity', 'Current Quantity'],
            [
                1,
                productItem!.sku || "N/A",
                productItem!.item_no || "N/A",
                productItem!.second_item_no || "N/A",
                productItem!.description || "N/A",
                productItem!.minimum_quantity || 0,
                productItem!.current_quantity || 0
            ]
        ];

        const csvContent = 'data:text/csv;charset=utf-8,' + csvData.map(e => e.join(",")).join("\n");
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        const today = formatDate(new Date());
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `stock_balance_report_${today}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Function to generate Excel
    const generateExcel = () => {
        if (!productItem) {
            showErrorToast("Excel generation failed. Product item is not available.");
            return; // Exit the function early if productItem is undefined
        }
        const worksheet = XLSX.utils.json_to_sheet([({
            SNo: 1,
            SKU: productItem!.sku || "N/A",
            ItemNo: productItem!.item_no || "N/A",
            SecondItemNo: productItem!.second_item_no || "N/A",
            Description: productItem!.description || "N/A",
            MinimumQuantity: productItem!.minimum_quantity || 0,
            CurrentQuantity: productItem!.current_quantity || 0
        })]);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Stock Balance");

        const today = formatDate(new Date());
        XLSX.writeFile(workbook, `stock_balance_report_${today}.xlsx`);
    };


    if (loading) return <Loading />;
    if (error) return <p className='py-16 text-center text-red-700'>{error}</p>;
    if (!productItem) return <p className='py-16 text-center'>Stock Balance not found.</p>;

    return (
        <>
            <div className="max-lg:hidden">
                <Link to="/gp/reports/stockbalance" className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400">
                    <ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500" />
                    Stock Balance
                </Link>
            </div>
            <div className="mt-4 lg:mt-8">
                <div className="flex items-center gap-4">
                    <Avatar square initials={productItem.item_no![0]} className="bg-zinc-300 text-gray-700 size-8" />
                    <Heading>{productItem.item_no}</Heading>
                </div>
                <div className="isolate mt-2.5 flex flex-wrap justify-between gap-x-6 gap-y-4">
                    <div className="flex flex-wrap gap-x-10 gap-y-4 py-1.5">
                        <span className="flex items-center gap-2 text-base/6 text-zinc-950 sm:text-sm/6 dark:text-white">
                            <CalendarIcon className="size-4 shrink-0 fill-zinc-400 dark:fill-zinc-500" />
                            <span>{dateFormat(productItem.created_time, "mmmm dd, yyyy")}</span>
                        </span>
                    </div>
                    <div className="flex gap-4">
                        <Dropdown>
                            <DropdownButton aria-label="More options">
                                Print Report
                            </DropdownButton>
                            <DropdownMenu anchor="bottom end">
                                <DropdownItem onClick={generatePDF}>PDF</DropdownItem>
                                <DropdownItem onClick={generateCSV}>CSV</DropdownItem>
                                <DropdownItem onClick={generateExcel}>Excel</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <div className="mt-12">
                <Subheading>Stock Balance Information</Subheading>
                <Divider className="mt-4" />
                <DescriptionList>
                    <DescriptionTerm>Item No</DescriptionTerm>
                    <DescriptionDetails>{productItem.item_no || '-'}</DescriptionDetails>
                    <DescriptionTerm>Second Item No</DescriptionTerm>
                    <DescriptionDetails>{productItem.second_item_no || '-'}</DescriptionDetails>
                    <DescriptionTerm>SKU</DescriptionTerm>
                    <DescriptionDetails>{productItem.sku || '-'}</DescriptionDetails>
                    <DescriptionTerm>Description</DescriptionTerm>
                    <DescriptionDetails>{productItem.description || '-'}</DescriptionDetails>
                    <DescriptionTerm>Packing</DescriptionTerm>
                    <DescriptionDetails>{productItem.packing || '-'}</DescriptionDetails>
                    <DescriptionTerm>UOM</DescriptionTerm>
                    <DescriptionDetails>{productItem.uom || '-'}</DescriptionDetails>
                    <DescriptionTerm>Minimum Quantity</DescriptionTerm>
                    <DescriptionDetails>{productItem.minimum_quantity || '-'}</DescriptionDetails>
                    <DescriptionTerm>Current Quantity</DescriptionTerm>
                    <DescriptionDetails>{productItem.current_quantity || '-'}</DescriptionDetails>
                </DescriptionList>
            </div>
        </>
    );
};

export default ReportStockBalanceIdPage;
