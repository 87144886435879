import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppUser } from '../../../../models/AppUser';
import useUserService from '../../../../hooks/useUserService';
import { useWarehouses } from '../../../../hooks/useWarehouse';
import { useAppUser } from '../../../../hooks/useAppUser';
import showErrorToast from '../../../../components/Toasts/ShowErrorToast';
import showLoadingToast from '../../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../../components/Toasts/ShowSuccessToast';
import { Heading, Subheading } from '../../../../components/Heading';
import { Button } from '../../../../components/Button';
import { Divider } from '../../../../components/Divider';
import { Input } from '../../../../components/Input';
import { Switch, SwitchField, SwitchGroup } from '../../../../components/Switch';
import { Select } from '../../../../components/Select';
import { Text } from '../../../../components/Text';

const EditAppUserPage = () => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>(); // Assuming `id` is passed as a URL parameter

    const [user, setUser] = useState<AppUser | null>(null);
    const { fetchUserById, loading, error, updateUser } = useAppUser();
    const { warehouses } = useWarehouses();
    const [isAdmin, setIsAdmin] = useState(false)

    useEffect(() => {
        document.title = "Edit App User";
    }, []);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const fetchedUser = await fetchUserById(id!);
                if (fetchedUser) {
                    setUser(fetchedUser);
                    setIsAdmin(Number(fetchedUser.is_admin) === 1); // ✅ Convert to number before comparison
                }
            } catch (err) {
                showErrorToast('Failed to fetch user details.');
            }
        };

        fetchUserDetails();
    }, [id]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setUser(prev => prev ? { ...prev, [name]: name === 'role' ? BigInt(value) : value } : null);
    };

    const handleToggleAdmin = (checked: any) => {
        setUser(prev => prev ? { ...prev, is_admin: checked ? '1' : '0' } : prev);
        setIsAdmin(checked);
    };


    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!user) return;
        if (!user.username || !user.password || !user.email || !user.warehouse_id) {
            showErrorToast("Please fill in all field before submit");
            return;
        }

        try {
            const userToUpdate = {
                ...user,
                is_admin: user.is_admin!.toString(),
            };

            const res = await updateUser(user.id.toString(), userToUpdate);
            if (res.dbsuccess) {
                showLoadingToast(`Updating ${user.username} ...`, 100);
                setTimeout(() => {
                    navigate('/gp/app/users');
                    showSuccessToast(`${user.username} updated successfully`);
                }, 1000);
            } else {
                showErrorToast(`Error updating user`);
            }
        } catch (err) {
            showErrorToast('Failed to update user.');
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;
    if (!user) return <p>User not found.</p>;
    if (!warehouses) return <p>Warehouses not found.</p>;

    return (
        <form method="post" onSubmit={handleSubmit} className="mx-auto max-w-6xl">
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
                <Heading>Edit App User</Heading>
                <div className="mt-5 flex lg:ml-4 lg:mt-0">
                    <Button type="reset" outline onClick={() => navigate(-1)}>
                        Cancel
                    </Button>
                    <Button type="submit" className="ml-3" disabled={(!user.username || !user.password || !user.email || !user.warehouse_id)}>Save</Button>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Profile</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            A brief summary highlighting key personal details.
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Email</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        type="email"
                                        aria-label="Email"
                                        name="email"
                                        value={user.email?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Mobile Number</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Mobile Number"
                                        name="mobile_no"
                                        value={user.mobile_no?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>
                    </div>
                </div>

                <div className='space-y-12'>
                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                        <div>
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Mobile App Account Information</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600">
                                Basic details linked to a user’s account.
                            </p>
                        </div>

                        <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                            <div className="sm:col-span-full">
                                <section className="">
                                    <div className="space-y-1">
                                        <Subheading>Username</Subheading>
                                    </div>
                                    <div className="mt-2">
                                        <Input
                                            aria-label="Username"
                                            name="username"
                                            value={user.username}
                                            onChange={handleChange}
                                            disabled
                                        />
                                    </div>
                                </section>
                            </div>

                            <div className="sm:col-span-full">
                                <section className="">
                                    <div className="space-y-1 ">
                                        <div className='flex justify-between'>
                                            <Subheading>Admin Account</Subheading>
                                            <SwitchGroup>
                                                <SwitchField>
                                                    <Switch
                                                        color="dark/zinc" // Choose color variant or customize
                                                        checked={isAdmin} // Pass the current state
                                                        onChange={handleToggleAdmin} // Set state on change
                                                    />
                                                </SwitchField>
                                            </SwitchGroup>
                                        </div>
                                        <Text>Admin Account</Text>
                                    </div>
                                </section>
                            </div>

                            <div className="sm:col-span-full">
                                <section className="">
                                    <div className="space-y-1">
                                        <Subheading>Warehouse</Subheading>
                                    </div>
                                    <div className="mt-2">
                                        <Select
                                            aria-label="Warehouse"
                                            name="warehouse_id"
                                            value={user.warehouse_id?.toString()}
                                            onChange={handleChange}
                                        >
                                            {warehouses.map(warehouse => (
                                                <option key={warehouse.id.toString()} value={warehouse.id.toString()}>
                                                    {warehouse.warehouse_name}
                                                </option>
                                            ))}
                                        </Select>
                                    </div>
                                </section>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="flex justify-end gap-4 my-6">
                <Button type="reset" plain onClick={() => navigate(-1)}>
                    Cancel
                </Button>
                <Button type="submit" disabled={(!user.username || !user.password || !user.email || !user.warehouse_id)}>Save</Button>
            </div>
        </form>
    );
};

export default EditAppUserPage;
