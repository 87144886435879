import React, { useEffect, useState } from 'react';
import { BanknotesIcon, CalendarIcon, ChevronLeftIcon, CreditCardIcon } from '@heroicons/react/16/solid';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AppUser } from '../../../../models/AppUser';
import { useAppUser } from '../../../../hooks/useAppUser';
import showErrorToast from '../../../../components/Toasts/ShowErrorToast';
import { AppUserService } from '../../../../services/appUserService';
import showSuccessToast from '../../../../components/Toasts/ShowSuccessToast';
import Loading from '../../../../components/Loading';
import { Avatar } from '../../../../components/Avatar';
import { Heading, Subheading } from '../../../../components/Heading';
import { Badge } from '../../../../components/Badge';
import dateFormat from 'dateformat';
import { Button } from '../../../../components/Button';
import { Divider } from '../../../../components/Divider';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from '../../../../components/Description-List';
import { Dialog, DialogActions, DialogDescription, DialogTitle } from '../../../../components/Dialog';
import { AppTrackLocation } from '../../../../models/AppTrackLocation';
import { useAppTrackLocations } from '../../../../hooks/useAppTrackLocation';

const ViewAppTrackLocationIdPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [location, setLocation] = useState<AppTrackLocation | null>(null);
    const navigate = useNavigate();
    const { fetchTrackLocationById, loading, error, } = useAppTrackLocations();

    useEffect(() => {
        document.title = "View App Track Location";
    }, []);

    useEffect(() => {
        if (!id) return;

        const fetchLocationDetails = async () => {
            try {
                const fetchedLocation = await fetchTrackLocationById(Number(id));
                setLocation(fetchedLocation!);
            } catch (err) {
                console.error('Failed to fetch location details:', err);
            }
        };

        fetchLocationDetails();
    }, [id]);

    if (loading) return <Loading />;
    if (error) return <p className="py-16 text-center text-red-700">{error}</p>;
    if (!location) return <p className="py-16 text-center">Location not found.</p>;

    return (
        <>
            <div className="max-lg:hidden">
                <Link to="/gp/app/track/locations" className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400">
                    <ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500" />
                    App Locations
                </Link>
            </div>
            <div className="mt-4 lg:mt-8">
                <div className="flex items-center gap-4">
                    <Avatar square initials={location.location_name[0]} className="bg-zinc-300 text-gray-700 size-8" />
                    <Heading>{location.location_name}</Heading>
                </div>
                <div className="isolate mt-2.5 flex flex-wrap justify-between gap-x-6 gap-y-4">
                    <div className="flex flex-wrap gap-x-10 gap-y-4 py-1.5">
                        <span className="flex items-center gap-2 text-base/6 text-zinc-950 sm:text-sm/6 dark:text-white">
                            <CalendarIcon className="size-4 shrink-0 fill-zinc-400 dark:fill-zinc-500" />
                            <span>{dateFormat(location.created_time, "mmmm dd, yyyy")}</span>
                        </span>
                    </div>
                    <div className="flex gap-4">
                        <Button onClick={() => navigate(`/gp/app/track/locations/edit/${location.id}`)}>Edit</Button>
                    </div>
                </div>
            </div>

            <div className="mt-12">
                <Subheading>Location Information</Subheading>
                <Divider className="mt-4" />
                <DescriptionList>

                    <DescriptionTerm>Location Name</DescriptionTerm>
                    <DescriptionDetails>{location.location_name || '-'}</DescriptionDetails>
                    <DescriptionTerm>Longitude</DescriptionTerm>
                    <DescriptionDetails>{location.longitude || '-'}</DescriptionDetails>
                    <DescriptionTerm>Latitude</DescriptionTerm>
                    <DescriptionDetails>{location.latitude || '-'}</DescriptionDetails>

                </DescriptionList>
            </div>
        </>
    );
};

export default ViewAppTrackLocationIdPage;
