import axios from 'axios';
import { AppSalesOrder } from '../models/AppSalesOrder';

const API_URL = process.env.REACT_APP_SERVER_URL + "/app/salesorders";

export const AppSalesOrderService = {
    getAllSalesOrders: async (): Promise<AppSalesOrder[]> => {
        const response = await axios.get(`${API_URL}/getAllSalesOrders`);
        return response.data;
    },

    getSalesOrderById: async (id: number): Promise<AppSalesOrder> => {
        const response = await axios.get(`${API_URL}/getSalesOrderById/${id}`);
        return response.data;
    },

    getSalesOrderByWarehouseId: async (warehouseId: string): Promise<AppSalesOrder[]> => {
        const response = await axios.get(`${API_URL}/getAllSalesOrdersByWarehouseId/${warehouseId}`);
        return response.data;
    },

    createSalesOrder: async (salesOrder: Omit<AppSalesOrder, 'id' | 'created_time' | 'updated_time'>): Promise<AppSalesOrder> => {
        const response = await axios.post(`${API_URL}/add`, salesOrder);
        return response.data;
    },

    updateSalesOrder: async (id: number, salesOrder: Omit<AppSalesOrder, 'id' | 'created_time' | 'updated_time'>): Promise<AppSalesOrder> => {
        const response = await axios.put(`${API_URL}/update/${id}`, salesOrder);
        return response.data;
    },

    deleteSalesOrder: async (id: number): Promise<void> => {
        await axios.delete(`${API_URL}/delete/${id}`);
    }
};
