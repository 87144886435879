import axios from 'axios';
import { AppAnnouncement } from '../models/AppAnnouncement';

const API_URL = process.env.REACT_APP_SERVER_URL + "/app/announcements";

export const AppAnnouncementService = {
    getAllAnnouncements: async (): Promise<AppAnnouncement[]> => {
        const response = await axios.get(`${API_URL}/getAllAnnouncements`);
        return response.data;
    },

    getAnnouncementById: async (id: number): Promise<AppAnnouncement> => {
        const response = await axios.get(`${API_URL}/getAnnouncementById/${id}`);
        return response.data;
    },

    createAnnouncement: async (announcement: Omit<AppAnnouncement, 'id' | 'created_time' | 'updated_time'>) => {
        const response = await axios.post(`${API_URL}/add`, announcement);
        return response.data;
    },

    updateAnnouncement: async (id: number, announcement: Omit<AppAnnouncement, 'id' | 'created_time' | 'updated_time'>) => {
        const response = await axios.put(`${API_URL}/update/${id}`, announcement);
        return response.data;
    },

    deleteAnnouncement: async (id: number): Promise<void> => {
        await axios.delete(`${API_URL}/delete/${id}`);
    }
};
