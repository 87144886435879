import { useState, useEffect, useCallback } from 'react';
import { AppTrackCheckIn } from '../models/AppTrackCheckIn';
import { AppTrackCheckInService } from '../services/appTrackCheckInService';

export const useAppTrackCheckIns = () => {
    const [trackCheckIns, setTrackCheckIns] = useState<AppTrackCheckIn[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const fetchTrackCheckIns = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await AppTrackCheckInService.getAllTrackCheckIns();
            setTrackCheckIns(data);
        } catch (err) {
            setError('Failed to fetch track check-ins');
            console.error(err);
        } finally {
            setLoading(false);
        }
    }, []);

    const fetchTrackCheckInById = async (id: number) => {
        setLoading(true);
        setError(null);
        try {
            const data = await AppTrackCheckInService.getTrackCheckInById(id);
            return data;
        } catch (err) {
            setError('Failed to fetch track check-in by ID.');
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const addTrackCheckIn = async (newTrackCheckIn: Omit<AppTrackCheckIn, 'id' | 'created_time' | 'updated_time'>) => {
        try {
            const createdTrackCheckIn = await AppTrackCheckInService.createTrackCheckIn(newTrackCheckIn);
            setTrackCheckIns(prev => [...prev, createdTrackCheckIn]);
            return createdTrackCheckIn;
        } catch (err) {
            setError('Failed to add track check-in');
            console.error(err);
        }
    };

    const updateTrackCheckIn = async (id: number, updatedTrackCheckIn: Omit<AppTrackCheckIn, 'id' | 'created_time' | 'updated_time'>) => {
        try {
            const updatedRes = await AppTrackCheckInService.updateTrackCheckIn(id, updatedTrackCheckIn);
            setTrackCheckIns(prev => prev.map(checkin => (checkin.id === id ? updatedRes : checkin)));
            return updatedRes;
        } catch (err) {
            setError('Failed to update track check-in');
            console.error(err);
        }
    };

    const deleteTrackCheckIn = async (id: number) => {
        try {
            await AppTrackCheckInService.deleteTrackCheckIn(id);
            setTrackCheckIns(prev => prev.filter(checkin => checkin.id !== id));
        } catch (err) {
            setError('Failed to delete track check-in');
            console.error(err);
        }
    };

    useEffect(() => {
        fetchTrackCheckIns();
    }, [fetchTrackCheckIns]);

    return {
        trackCheckIns,
        loading,
        error,
        fetchTrackCheckIns,
        fetchTrackCheckInById,
        addTrackCheckIn,
        updateTrackCheckIn,
        deleteTrackCheckIn
    };
};
