import React, { useContext, useEffect, useState } from 'react';
import { Button } from '../../../components/Button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../components/Table';
import { useNavigate } from 'react-router-dom';
import { useAppTrackLocations } from '../../../hooks/useAppTrackLocation';
import Loading from '../../../components/Loading';
import { Input, InputGroup } from '../../../components/Input';
import { Heading } from '../../../components/Heading';
import { useAppUser } from '../../../hooks/useAppUser';
import { EllipsisVerticalIcon, EyeIcon, PencilIcon } from '@heroicons/react/16/solid';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '../../../components/Dropdown';
import { Dialog, DialogActions, DialogDescription, DialogTitle } from '../../../components/Dialog';

const AppUserPage: React.FC = () => {
    const { loading, error, fetchAllUsers, appUsers, deleteUser } = useAppUser();
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false); // Dialog open state
    const [isDeleting, setIsDeleting] = useState(false); // Loading state for deletion
    const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

    useEffect(() => {
        document.title = "App Users";
    }, []);

    const filteredUsers = appUsers.filter(user =>
        user.username.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleOpenDeleteDialog = (userId: string) => {
        setSelectedUserId(userId);
        setIsOpen(true);
    };

    const handleDelete = async () => {
        if (!selectedUserId) return;

        setIsDeleting(true);
        try {
            await deleteUser(selectedUserId);
            fetchAllUsers();
            setIsOpen(false);
        } catch (error) {
            console.error('Failed to delete user:', error);
        } finally {
            setIsDeleting(false);
        }
    };

    return (
        <div>
            <div className="flex flex-wrap items-end justify-between gap-4">
                <div className="max-sm:w-full sm:flex-1">
                    <Heading>App Users</Heading>
                    <div className="mt-4 flex max-w-xl gap-4">
                        <div className="flex-1">
                            <InputGroup>
                                <Input
                                    name="search"
                                    placeholder="Search app user&hellip;"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </InputGroup>
                        </div>
                    </div>
                </div>
                <Button onClick={() => navigate('/gp/app/users/add')}>Create new app user</Button>
            </div>

            <ul className="mt-10">
                {loading && <div className="w-full text-center h-full py-36 justify-center"><Loading /></div>}
                {error && <p className="py-16 text-center text-red-700">{error}</p>}
                {(!loading && !error) && (
                    <div>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableHeader>S/N</TableHeader>
                                    <TableHeader>Username</TableHeader>
                                    <TableHeader>Email</TableHeader>
                                    <TableHeader>Created On</TableHeader>
                                    <TableHeader className="text-center">Actions</TableHeader>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredUsers.length > 0 ? filteredUsers.map((user, index) => (
                                    <TableRow key={user.id}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{user.username}</TableCell>
                                        <TableCell>{user.email}</TableCell>
                                        <TableCell>{user.created_time}</TableCell>
                                        <TableCell className="py-5 flex gap-4 text-zinc-500 justify-center">
                                            <a onClick={() => navigate(`/gp/app/users/edit/${user.id}`)} className="text-zinc-600 hover:text-zinc-900">
                                                <PencilIcon className='h-5 w-5' />
                                            </a>
                                            <a onClick={() => navigate(`/gp/app/users/view/${user.id}`)} className="text-zinc-600 hover:text-zinc-900">
                                                <EyeIcon className='h-5 w-5' />
                                            </a>
                                            <Dropdown>
                                                <DropdownButton plain aria-label="More options" className='h-5 w-5' >
                                                    <EllipsisVerticalIcon />
                                                </DropdownButton>
                                                <DropdownMenu anchor="bottom end">
                                                    <DropdownItem onClick={() => handleOpenDeleteDialog(user.id.toString())}>Delete</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </TableCell>
                                    </TableRow>
                                )) : (
                                    <TableRow>
                                        <TableCell colSpan={4} className="text-center py-8">No app user found.</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                )}
            </ul>

            {/* Delete Confirmation Dialog */}
            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                <DialogTitle>Delete App User</DialogTitle>
                <DialogDescription>
                    Are you sure you want to delete this app user? This action cannot be undone.
                </DialogDescription>
                <DialogActions>
                    <Button plain onClick={() => setIsOpen(false)} disabled={isDeleting}>
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} disabled={isDeleting}>
                        {isDeleting ? <Loading /> : 'Delete'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AppUserPage;
