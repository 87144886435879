import axios from 'axios';
import { PreCallPlan } from '../models/AppPreCallPlan';

const API_URL = process.env.REACT_APP_SERVER_URL + "/app/precallplans";

export const AppPreCallPlanService = {
    getAllPreCallPlans: async (): Promise<PreCallPlan[]> => {
        const response = await axios.get(`${API_URL}/getAllPlans`);
        return response.data;
    },

    getPreCallPlanById: async (id: number): Promise<PreCallPlan> => {
        const response = await axios.get(`${API_URL}/getPlanById/${id}`);
        return response.data;
    },

    createPreCallPlan: async (preCallPlan: Omit<PreCallPlan, 'id' | 'created_time' | 'updated_time'>): Promise<PreCallPlan> => {
        const response = await axios.post(`${API_URL}/add`, preCallPlan);
        return response.data;
    },

    updatePreCallPlan: async (id: number, preCallPlan: Omit<PreCallPlan, 'id' | 'created_time' | 'updated_time'>): Promise<PreCallPlan> => {
        const response = await axios.put(`${API_URL}/update/${id}`, preCallPlan);
        return response.data;
    },

    deletePreCallPlan: async (id: number): Promise<void> => {
        await axios.delete(`${API_URL}/delete/${id}`);
    }
};
