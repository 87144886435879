import React, { useContext, useEffect, useState } from 'react';
import { EllipsisVerticalIcon, EyeIcon, MagnifyingGlassIcon, PencilIcon } from '@heroicons/react/16/solid';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '../../../components/Dropdown';
import { Badge } from '../../../components/Badge';
import { Link } from '../../../components/Link';
import { Button } from '../../../components/Button';
import { Heading } from '../../../components/Heading';
import { Input, InputGroup } from '../../../components/Input';
import { Divider } from '../../../components/Divider';
import { Avatar } from '../../../components/Avatar';
import Loading from '../../../components/Loading';
import Pagination from '../../../components/Pagination'; // Import the Pagination component
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../../../components/Dialog';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../components/Table';
import dateFormat from 'dateformat';
import { UserContext } from '../../../context/userContext';
import { useWarehouseProductGroup } from '../../../hooks/useWarehouseProductGroup';

const WarehouseProductGroupPage: React.FC = () => {
    const { currentUser } = useContext(UserContext); // Get current user info
    const { loading, error, deleteGroup, fetchAllGroups, groups } = useWarehouseProductGroup();
    const [searchQuery, setSearchQuery] = useState<string>(''); // State to store the search query
    const [isOpen, setIsOpen] = useState(false); // Dialog open state
    const [isDeleting, setIsDeleting] = useState(false); // Loading state for deletion
    const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Product Groups";
    }, []);

    const filteredGroups = groups.filter((group) =>
        group.group_name!.toLowerCase().includes(searchQuery.toLowerCase())
        // group.group_code!.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleOpenDeleteDialog = (groupId: string) => {
        setSelectedGroupId(groupId);
        setIsOpen(true);
    };

    const handleDelete = async () => {
        if (!selectedGroupId) return;

        setIsDeleting(true);
        try {
            await deleteGroup(Number(selectedGroupId));
            fetchAllGroups();
            setIsOpen(false);
        } catch (error) {
            console.error('Failed to delete group:', error);
        } finally {
            setIsDeleting(false);
        }
    };

    return (
        <>
            <div className="flex flex-wrap items-end justify-between gap-4">
                <div className="max-sm:w-full sm:flex-1">
                    <Heading>Product Groups</Heading>
                    <div className="mt-4 flex max-w-xl gap-4">
                        <div className="flex-1">
                            <InputGroup>
                                <MagnifyingGlassIcon />
                                <Input
                                    name="search"
                                    placeholder="Search group&hellip;"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </InputGroup>
                        </div>
                    </div>
                </div>
                <Button onClick={() => navigate('/gp/inventories/groups/add')}>Create new group</Button>
            </div>

            <ul className="mt-10">
                {loading && <div className='w-full text-center h-full py-36 justify-center'><Loading /></div>}
                {error && <p className='py-16 text-center text-red-700'>{error}</p>}
                {(!loading && !error) && (
                    <div>
                        <Table className="[--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
                            <TableHead>
                                <TableRow>
                                    <TableHeader>S/N</TableHeader>
                                    <TableHeader>Group Name</TableHeader>
                                    <TableHeader>Group Code</TableHeader>
                                    <TableHeader>Created on</TableHeader>
                                    <TableHeader className="text-center">Actions</TableHeader>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(filteredGroups.length > 0) ? filteredGroups.map((group, index) => (
                                    <TableRow key={group.id} title={`${group.group_name}`}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{group.group_name}</TableCell>
                                        <TableCell className="text-zinc-500">{group.group_code}</TableCell>
                                        <TableCell className="text-zinc-500">{dateFormat(group.created_time?.toString(), "mmm dd, yyyy")}</TableCell>
                                        <TableCell className="py-5 flex gap-4 text-zinc-500 justify-center">
                                            <a onClick={() => navigate(`/gp/inventories/groups/edit/${group.id}`)} className="text-zinc-600 hover:text-zinc-900">
                                                <PencilIcon className='h-5 w-5' />
                                            </a>
                                            <a onClick={() => navigate(`/gp/inventories/groups/view/${group.id}`)} className="text-zinc-600 hover:text-zinc-900">
                                                <EyeIcon className='h-5 w-5' />
                                            </a>
                                            <Dropdown>
                                                <DropdownButton plain aria-label="More options" className='h-5 w-5' >
                                                    <EllipsisVerticalIcon />
                                                </DropdownButton>
                                                <DropdownMenu anchor="bottom end">
                                                    <DropdownItem onClick={() => handleOpenDeleteDialog(group.id.toString())}>Delete</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </TableCell>
                                    </TableRow>
                                )) : <TableRow>
                                    <TableCell colSpan={9} className="text-center py-8">No group found.</TableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </div>
                )}
            </ul>

            {/* Delete Confirmation Dialog */}
            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                <DialogTitle>Delete Product Group</DialogTitle>
                <DialogDescription>
                    Are you sure you want to delete this group? This action cannot be undone.
                </DialogDescription>
                <DialogActions>
                    <Button plain onClick={() => setIsOpen(false)} disabled={isDeleting}>
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} disabled={isDeleting}>
                        {isDeleting ? <Loading /> : 'Delete'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default WarehouseProductGroupPage;
