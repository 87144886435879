// frontend/src/services/AppUserService.ts
import axios from 'axios';
import showErrorToast from '../components/Toasts/ShowErrorToast';
import { AppUser } from '../models/AppUser';

const API_URL = process.env.REACT_APP_SERVER_URL + '/app/users';

export const AppUserService = {
    login: async (username: string, password: string) => {
        try {
            const response = await axios.post(`${API_URL}/login`, { username, password });
            const { gpcapp_token, success, message } = response.data;

            if (success) {
                localStorage.setItem('gpcapp_token', gpcapp_token);
            } else {
                showErrorToast(message);
            }

            return success;
        } catch (error) {
            showErrorToast('Login Error: ' + error);
            throw error;
        }
    },

    getProfile: async () => {
        try {
            const token = localStorage.getItem('gpcapp_token');
            const response = await axios.get(`${API_URL}/profile`);
            const { success, userProfile, message } = response.data;

            if (!success) {
                showErrorToast(message);
                localStorage.removeItem('gpcapp_token');
                return null;
            }

            return userProfile;
        } catch (error) {
            showErrorToast('Error fetching profile: ' + error);
            localStorage.removeItem('gpcapp_token');
            throw error;
        }
    },

    logout: () => {
        localStorage.removeItem('gpcapp_token');
    },

    getAllUsers: async (): Promise<AppUser[]> => {
        try {
            const token = localStorage.getItem('gpcapp_token');
            const response = await axios.get(`${API_URL}/getAllUsers`);
            const { success, users, message } = response.data;

            if (!success) {
                showErrorToast(message);
                return [];
            }

            return users;
        } catch (error) {
            showErrorToast('Error fetching users: ' + error);
            throw error;
        }
    },

    getUserById: async (id: string): Promise<AppUser | null> => {
        try {
            const token = localStorage.getItem('gpcapp_token');
            const response = await axios.get(`${API_URL}/getUserById/${id}`);
            const { success, user, message } = response.data;

            if (!success) {
                showErrorToast(message);
                return null;
            }

            return user;
        } catch (error) {
            showErrorToast('Error fetching user: ' + error);
            throw error;
        }
    },

    addUser: async (userData: Partial<AppUser>) => {
        try {
            const token = localStorage.getItem('gpcapp_token');
            const response = await axios.post(`${API_URL}/add`, userData);
            return response.data;
        } catch (error) {
            showErrorToast('Error adding user: ' + error);
            throw error;
        }
    },

    updateUser: async (id: string, userData: Partial<AppUser>) => {
        try {
            const token = localStorage.getItem('gpcapp_token');
            const response = await axios.put(`${API_URL}/update/${id}`, userData);
            return response.data;
        } catch (error) {
            showErrorToast('Error updating user: ' + error);
            throw error;
        }
    },

    deleteUser: async (id: string) => {
        try {
            const token = localStorage.getItem('gpcapp_token');
            await axios.delete(`${API_URL}/delete/${id}`);
        } catch (error) {
            showErrorToast('Error deleting user: ' + error);
            throw error;
        }
    },

    resetPassword: async (userId: number, currentPassword: string, newPassword: string) => {
        try {
            const token = localStorage.getItem('gpcapp_token');
            const response = await axios.post(`${API_URL}/resetpassword`, { userId, currentPassword, newPassword });
            return response.data;
        } catch (error) {
            showErrorToast('Error resetting password: ' + error);
            throw error;
        }
    },

    resetPasswordByRandom: async (username: string) => {
        try {
            const token = localStorage.getItem('gpcapp_token');
            const response = await axios.post(`${API_URL}/resetPasswordByRandom`, { username });
            return response.data;
        } catch (error) {
            showErrorToast('Error resetting password by random: ' + error);
            throw error;
        }
    },
};
