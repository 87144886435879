import React, { useContext, useEffect, useState } from 'react';
import { EllipsisVerticalIcon, EyeIcon, PencilIcon } from '@heroicons/react/16/solid';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '../../../components/Dropdown';
import { Badge } from '../../../components/Badge';
import { Button } from '../../../components/Button';
import { Heading } from '../../../components/Heading';
import { Input, InputGroup } from '../../../components/Input';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../components/Table';
import dateFormat from 'dateformat';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../../../components/Dialog';
import { useAppAnnouncements } from '../../../hooks/useAppAnnouncement';
import Loading from '../../../components/Loading';

const AppAnnouncementPage: React.FC = () => {
    const { loading, error, deleteAnnouncement, fetchAnnouncements, announcements } = useAppAnnouncements();
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [isOpen, setIsOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [selectedAnnouncementId, setSelectedAnnouncementId] = useState<number | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "App Announcements";
    }, []);

    const filteredAnnouncements = announcements.filter((announcement) =>
        announcement.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleOpenDeleteDialog = (announcementId: number) => {
        setSelectedAnnouncementId(announcementId);
        setIsOpen(true);
    };

    const handleDelete = async () => {
        if (!selectedAnnouncementId) return;
        setIsDeleting(true);
        try {
            await deleteAnnouncement(selectedAnnouncementId);
            fetchAnnouncements();
            setIsOpen(false);
        } catch (error) {
            console.error('Failed to delete announcement:', error);
        } finally {
            setIsDeleting(false);
        }
    };

    return (
        <>
            <div className="flex flex-wrap items-end justify-between gap-4">
                <div className="max-sm:w-full sm:flex-1">
                    <Heading>App Announcements</Heading>
                    <div className="mt-4 flex max-w-xl gap-4">
                        <div className="flex-1">
                            <InputGroup>
                                <Input
                                    name="search"
                                    placeholder="Search announcement&hellip;"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </InputGroup>
                        </div>
                    </div>
                </div>
                <Button onClick={() => navigate('/gp/app/announcements/add')}>Create new announcement</Button>
            </div>

            <ul className="mt-10">
                {loading && <div className="w-full text-center h-full py-36 justify-center"><Loading /></div>}
                {error && <p className="py-16 text-center text-red-700">{error}</p>}
                {(!loading && !error) && (
                    <div>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableHeader>S/N</TableHeader>
                                    <TableHeader>Title</TableHeader>
                                    <TableHeader>Created On</TableHeader>
                                    <TableHeader className="text-center">Actions</TableHeader>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredAnnouncements.length > 0 ? filteredAnnouncements.map((announcement, index) => (
                                    <TableRow key={announcement.id}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{announcement.title}</TableCell>
                                        <TableCell>{dateFormat(announcement.created_time, "mmm dd, yyyy")}</TableCell>
                                        <TableCell className="py-5 flex gap-4 text-zinc-500 justify-center">
                                            <a onClick={() => navigate(`/gp/app/announcements/edit/${announcement.id}`)} className="text-zinc-600 hover:text-zinc-900">
                                                <PencilIcon className='h-5 w-5' />
                                            </a>
                                            <a onClick={() => navigate(`/gp/app/announcements/view/${announcement.id}`)} className="text-zinc-600 hover:text-zinc-900">
                                                <EyeIcon className='h-5 w-5' />
                                            </a>
                                            <Dropdown>
                                                <DropdownButton plain aria-label="More options" className="h-5 w-5">
                                                    <EllipsisVerticalIcon />
                                                </DropdownButton>
                                                <DropdownMenu anchor="bottom end">
                                                    <DropdownItem onClick={() => handleOpenDeleteDialog(announcement.id)}>Delete</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </TableCell>
                                    </TableRow>
                                )) : (
                                    <TableRow>
                                        <TableCell colSpan={4} className="text-center py-8">No announcement found.</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                )}
            </ul>

            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                <DialogTitle>Delete Announcement</DialogTitle>
                <DialogDescription>
                    Are you sure you want to delete this announcement? This action cannot be undone.
                </DialogDescription>
                <DialogActions>
                    <Button plain onClick={() => setIsOpen(false)} disabled={isDeleting}>Cancel</Button>
                    <Button onClick={handleDelete} disabled={isDeleting}>
                        {isDeleting ? <Loading /> : 'Delete'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AppAnnouncementPage;
