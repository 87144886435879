import { useState, useEffect, useCallback, useContext } from 'react';
import { AppSalesOrder } from '../models/AppSalesOrder';
import { AppSalesOrderService } from '../services/appSalesOrderService';
import { UserContext } from '../context/userContext';

export const useAppSalesOrders = () => {
    const [salesOrders, setSalesOrders] = useState<AppSalesOrder[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [totalSalesOrders, setTotalSalesOrders] = useState<number>(0);
    const { currentUser } = useContext(UserContext);


    const fetchSalesOrders = async () => {
        setLoading(true);
        setError(null);
        try {
            if (currentUser?.warehouse_hq === "1") {
                const orderData = await AppSalesOrderService.getAllSalesOrders();
                setSalesOrders(orderData);
                setTotalSalesOrders(orderData.length);
            } else if (currentUser?.warehouse_id && currentUser.role!.toString() !== "1") {
                const orderData = await AppSalesOrderService.getSalesOrderByWarehouseId(currentUser.warehouse_id.toString());
                setSalesOrders(orderData);
                setTotalSalesOrders(orderData.length);
            }
        } catch (err: any) {
            setError(err.message || 'Error fetching sales order');
        } finally {
            setLoading(false);
        }
    };

    const fetchSalesOrderById = async (id: number) => {
        setLoading(true);
        setError(null);
        try {
            return await AppSalesOrderService.getSalesOrderById(id);
        } catch (err) {
            setError('Failed to fetch sales order by ID.');
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const addSalesOrder = async (newSalesOrder: Omit<AppSalesOrder, 'id' | 'created_time' | 'updated_time'>) => {
        try {
            const createdSalesOrder = await AppSalesOrderService.createSalesOrder(newSalesOrder);
            setSalesOrders(prev => [...prev, createdSalesOrder]);
            return createdSalesOrder;
        } catch (err) {
            setError('Failed to add sales order');
            console.error(err);
        }
    };

    const updateSalesOrder = async (id: number, updatedSalesOrder: Omit<AppSalesOrder, 'id' | 'created_time' | 'updated_time'>) => {
        try {
            const updatedRes = await AppSalesOrderService.updateSalesOrder(id, updatedSalesOrder);
            setSalesOrders(prev => prev.map(order => (order.id === BigInt(id) ? updatedRes : order)));
            return updatedRes;
        } catch (err) {
            setError('Failed to update sales order');
            console.error(err);
        }
    };

    const deleteSalesOrder = async (id: number) => {
        try {
            await AppSalesOrderService.deleteSalesOrder(id);
            setSalesOrders(prev => prev.filter(order => order.id !== BigInt(id)));
        } catch (err) {
            setError('Failed to delete sales order');
            console.error(err);
        }
    };

    useEffect(() => {
        fetchSalesOrders();
    }, [currentUser]);

    return {
        salesOrders,
        loading,
        error,
        fetchSalesOrders,
        fetchSalesOrderById,
        addSalesOrder,
        updateSalesOrder,
        deleteSalesOrder
    };
};
