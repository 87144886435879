import { useState, useEffect, useCallback } from 'react';
import { AppAnnouncement } from '../models/AppAnnouncement';
import { AppAnnouncementService } from '../services/appAnnouncementService';

export const useAppAnnouncements = () => {
    const [announcements, setAnnouncements] = useState<AppAnnouncement[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const fetchAnnouncements = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await AppAnnouncementService.getAllAnnouncements();
            setAnnouncements(data);
        } catch (err) {
            setError('Failed to fetch announcements');
            console.error(err);
        } finally {
            setLoading(false);
        }
    }, []);

    const fetchAnnouncementById = async (id: number) => {
        setLoading(true);
        setError(null);
        try {
            const data = await AppAnnouncementService.getAnnouncementById(id);
            return data;
        } catch (err) {
            setError('Failed to fetch announcement by ID.');
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const addAnnouncement = async (newAnnouncement: Omit<AppAnnouncement, 'id' | 'created_time' | 'updated_time'>) => {
        try {
            const createdAnnouncementRes = await AppAnnouncementService.createAnnouncement(newAnnouncement);
            setAnnouncements(prev => [...prev, createdAnnouncementRes]);
            return createdAnnouncementRes;
        } catch (err) {
            setError('Failed to add announcement');
        }
    };

    const updateAnnouncement = async (id: number, updatedAnnouncement: Omit<AppAnnouncement, 'id' | 'created_time' | 'updated_time'>) => {
        try {
            const updatedRes = await AppAnnouncementService.updateAnnouncement(id, updatedAnnouncement);
            setAnnouncements(prev => prev.map(announcement => (announcement.id === id ? updatedRes : announcement)));
            return updatedRes;
        } catch (err) {
            setError('Failed to update announcement');
            console.error(err);
        }
    };

    const deleteAnnouncement = async (id: number) => {
        try {
            await AppAnnouncementService.deleteAnnouncement(id);
            setAnnouncements(prev => prev.filter(announcement => announcement.id !== id));
        } catch (err) {
            setError('Failed to delete announcement');
            console.error(err);
        }
    };

    useEffect(() => {
        fetchAnnouncements();
    }, [fetchAnnouncements]);

    return {
        announcements,
        loading,
        error,
        fetchAnnouncements,
        fetchAnnouncementById,
        addAnnouncement,
        updateAnnouncement,
        deleteAnnouncement
    };
};
