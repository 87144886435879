import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Heading, Subheading } from '../../../../../components/Heading';
import { Divider } from '../../../../../components/Divider';
import { Input } from '../../../../../components/Input';
import { Select } from '../../../../../components/Select';
import { Button } from '../../../../../components/Button';
import showLoadingToast from '../../../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../../../components/Toasts/ShowSuccessToast';
import showErrorToast from '../../../../../components/Toasts/ShowErrorToast';
import { useStockAdjustItems } from '../../../../../hooks/useStockAdjustItem'; // Custom hook for Stock-Adjust items
import { useWarehouseProductItem } from '../../../../../hooks/useWarehouseProductItem';
import { UserContext } from '../../../../../context/userContext';
import { Text } from '../../../../../components/Text';
import { Dialog, DialogActions, DialogDescription, DialogTitle } from '../../../../../components/Dialog';
import { useStockAdjust } from '../../../../../hooks/useStockAdjust';
import { StockAdjust } from '../../../../../models/StockAdjust';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../../components/Table';
import { PencilIcon, TrashIcon } from '@heroicons/react/16/solid';
import dateFormat from 'dateformat';
import { useWarehouseProductItemBatchNo } from '../../../../../hooks/useWarehouseProductItemBatchNo';
import { WarehouseProductItemBatchNo } from '../../../../../models/WarehouseProductItemBatchNo';
import { WarehouseProductItem } from '../../../../../models/WarehouseProductItem';
import ReactSelect, { SingleValue } from 'react-select';
import { useWarehouseProduct } from '../../../../../hooks/useWarehouseProduct';
import { WarehouseProduct } from '../../../../../models/WarehouseProduct';

interface AddStockAdjustItem {
    product_id: number | null;
    quantity: string | null;
    batch_no: string | null;
    stock_adjust_id: number | null;
    approved_by: string | null;
    reason: '',
}

const AddStockAdjustItemPageId = () => {
    const id = useParams<{ id: string }>();
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [stockAdjustItem, setStockAdjustItem] = useState<AddStockAdjustItem>({
        product_id: Number(0),
        quantity: '',
        batch_no: '',
        stock_adjust_id: Number(0),
        approved_by: '',
        reason: '',
    });
    const [stockAdjustItemsList, setStockAdjustItemsList] = useState<AddStockAdjustItem[]>([]); // To store added Stock-Adjust items
    const { createMultipleStockAdjustItems } = useStockAdjustItems(); // Hook for creating multiple Stock-Adjust items
    const { stockAdjusts } = useStockAdjust();
    const { products } = useWarehouseProduct();

    const [isOpen, setIsOpen] = useState(false); // Manage the edit dialog state
    const [editItemIndex, setEditItemIndex] = useState<number | null>(null); // Track which item is being edited
    const [isDeleting, setIsDeleting] = useState(false); // For delete confirmation
    const [editForm, setEditForm] = useState<Partial<AddStockAdjustItem>>({
        quantity: '',
        batch_no: '',
    });
    const [selectedStockAdjust, setSelectedStockAdjust] = useState<StockAdjust | null>(null);
    const { fetchBatchNoByProductItemId } = useWarehouseProductItemBatchNo();
    const [batchNos, setBatchNos] = useState<WarehouseProductItemBatchNo[]>([]);

    useEffect(() => {
        document.title = "Add Stock adjust Items";
    }, []);

    useEffect(() => {
        if (!id) return;

        if (id) {
            setStockAdjustItem(prev => ({ ...prev, stock_adjust_id: Number(id) }));
        }

        const matchingStockAdjust = stockAdjusts.find((stockAdjust) => Number(stockAdjust.id) === Number(id.id));
        if (matchingStockAdjust) {
            setSelectedStockAdjust(matchingStockAdjust);
            setStockAdjustItem(prev => ({ ...prev, stock_adjust_id: matchingStockAdjust.id }));
        }

    }, [stockAdjusts]);

    useEffect(() => {
        if (stockAdjusts.length > 0) {
            setStockAdjustItem(prev => ({ ...prev, stock_adjust_id: stockAdjusts[0].id! }));
        }
        if (products.length > 0) {
            setStockAdjustItem(prev => ({ ...prev, product_id: Number(products[0].id!) }));
        }
    }, [stockAdjusts, products]);

    const fetchBatchNoDetails = async (productItemId: number) => {
        try {
            const fetchBatchNo = await fetchBatchNoByProductItemId(productItemId);
            setBatchNos(fetchBatchNo || []);
        } catch (err) {
            showErrorToast(`Error fetching batch nos ${err}`);
        }
    }

    useEffect(() => {
        if (stockAdjustItem.product_id) {
            fetchBatchNoDetails(stockAdjustItem.product_id);
        }
    })


    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setStockAdjustItem(prev => ({ ...prev, [name]: value }));

    };

    // Add the current Stock-Adjust item to the table
    const handleAddToTable = () => {
        if (!stockAdjustItem.approved_by || !stockAdjustItem.batch_no || !stockAdjustItem.product_id || !stockAdjustItem.quantity || !stockAdjustItem.reason || !stockAdjustItem.stock_adjust_id) {
            showErrorToast("Please fill in all field before add to table");
            return;
        }
        const { quantity, batch_no, approved_by, reason } = stockAdjustItem;

        // Validation: Ensure that quantity, batch_no, and expiry_date are not empty
        if (!quantity || !batch_no || !approved_by || !reason) {
            showErrorToast('Please fill in Quantity, Batch No, Approved_by, Reason before adding to table.');
            return;
        }

        setStockAdjustItem({
            product_id: Number(0),
            quantity: '',
            batch_no: '',
            stock_adjust_id: Number(selectedStockAdjust!.id),
            approved_by: '',
            reason: '',
        }); // Reset the form fields after adding

        if (products.length > 0) {
            setStockAdjustItem(prev => ({ ...prev, product_id: Number(products[0].id!) }));
        }

        setStockAdjustItemsList(prevList => [...prevList, stockAdjustItem]);
    };

    // Save all stock-in items in the table
    const handleSaveAll = async () => {
        try {
            showLoadingToast(`Saving all items...`, 100);
            const res = await createMultipleStockAdjustItems(stockAdjustItemsList); // Save all items
            if (res!.dbsuccess) {
                setTimeout(() => {
                    showSuccessToast(`All items saved successfully.`);
                    navigate('/gp/stocks/stockadjust'); // Redirect after saving
                }, 1000);
            } else {
                showErrorToast(`Failed adding stock-adjust items data`);
            }
        } catch (err) {
            showErrorToast(`Failed to save stock-adjust items: ${err}`);
        }
    };

    // Delete a Stock-Adjust item by index
    const handleDeleteItem = (index: number) => {
        setStockAdjustItemsList(prevList => prevList.filter((_, i) => i !== index));
    };

    // Open the edit dialog and prefill form with current item data
    const handleOpenEditDialog = (index: number) => {
        const itemToEdit = stockAdjustItemsList[index];
        setEditForm({
            quantity: itemToEdit.quantity,
            batch_no: itemToEdit.batch_no,
            approved_by: itemToEdit.approved_by,
            reason: itemToEdit.reason,
        });
        setEditItemIndex(index); // Set the index of the item being edited
        setIsOpen(true); // Open the dialog
    };

    // Update the item in the StockAdjustItemsList
    const handleEditSubmit = () => {
        if (editItemIndex !== null) {
            const updatedItems = [...stockAdjustItemsList];
            updatedItems[editItemIndex] = {
                ...updatedItems[editItemIndex],
                ...editForm
            };
            setStockAdjustItemsList(updatedItems); // Update the list with the edited item
            setIsOpen(false); // Close the dialog
        }
    };

    const handleEditFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setEditForm(prevForm => ({ ...prevForm, [name]: value }));
    };

    const selectProductOptions = products.map((product: WarehouseProduct) => ({
        value: product.id,
        label: `${product.item_no || "-"}  ${product.second_item_no || "-"} : ${product.description || "-"}`,
    }));

    // Handle ReactSelect change
    const handleProductChange = (newValue: SingleValue<{ value: number; label: string }>) => {
        if (newValue) {
            setStockAdjustItem((prev) => ({ ...prev, product_id: Number(newValue.value) }));
        }
    };

    return (
        <form method="post" className="mx-auto max-w-6xl">
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
                <Heading>Add Stock-Adjust Item</Heading>
                <div className="mt-5 flex lg:ml-4 lg:mt-0">
                    <Button type="button" outline onClick={() => navigate(-1)}>
                        Cancel
                    </Button>
                    <Button type="button" className="ml-3" onClick={handleSaveAll} disabled={stockAdjustItemsList.length === 0}>
                        Save All
                    </Button>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Stock-Adjust Item Information</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            Details of specific inventory items adjusted.
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                            <Subheading>Stock Adjust Reference No</Subheading>
                            <label className='text-zinc-400'>{selectedStockAdjust?.reference_no}</label>
                        </div>

                        <div className="sm:col-span-full">
                            <Subheading>Manual No</Subheading>
                            <label className='text-zinc-400'>{selectedStockAdjust?.manual_no}</label>
                        </div>

                        {/* <div className="sm:col-span-full">
                            <section>
                                <div className="space-y-1">
                                    <Subheading>Product</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Select
                                        aria-label="Product"
                                        name="product_id"
                                        value={stockAdjustItem.product_id!.toString()}
                                        onChange={handleChange}
                                    >
                                        {productItems.map(product => (
                                            <option key={product.id} value={product.id!.toString()}>
                                                {product.item_no}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                            </section>
                        </div> */}

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Product</Subheading>
                                </div>
                                <div className="mt-2">
                                    <ReactSelect
                                        id="department_id"
                                        name="department_id"
                                        options={selectProductOptions}
                                        className="text-sm"
                                        placeholder="Select Product Item"
                                        onChange={handleProductChange}
                                        value={selectProductOptions.find(option => Number(option.value) === Number(stockAdjustItem.product_id!))}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section>
                                <div className="space-y-1">
                                    <Subheading>Quantity</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Quantity"
                                        type="number"
                                        name="quantity"
                                        value={stockAdjustItem.quantity!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section>
                                <div className="space-y-1">
                                    <Subheading>Batch No</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Select
                                        aria-label="Batch No"
                                        name="batch_no"
                                        value={stockAdjustItem.batch_no || ''}
                                        onChange={handleChange}
                                    >
                                        <option value="" disabled>Select Batch No</option>
                                        {batchNos.map(batch => (
                                            <option key={batch.batch_no} value={batch.batch_no}>
                                                {batch.batch_no}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section>
                                <div className="space-y-1">
                                    <Subheading>Approved By</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Approved By"
                                        type="text"
                                        name="approved_by"
                                        value={stockAdjustItem.approved_by!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section>
                                <div className="space-y-1">
                                    <Subheading>Reason</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Reason"
                                        type="text"
                                        name="reason"
                                        value={stockAdjustItem.reason!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-end gap-4 my-6">
                <Button type="button" onClick={handleAddToTable} disabled={(!stockAdjustItem.approved_by || !stockAdjustItem.batch_no || !stockAdjustItem.product_id || !stockAdjustItem.quantity || !stockAdjustItem.reason || !stockAdjustItem.stock_adjust_id)}>
                    Add to Table
                </Button>
            </div>

            <div>
                <Table className="[--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
                    <TableHead>
                        <TableRow>
                            <TableHeader>S/N</TableHeader>
                            <TableHeader>Product Barcode</TableHeader>
                            <TableHeader>Description</TableHeader>
                            <TableHeader>Quantity</TableHeader>
                            <TableHeader>UOM</TableHeader>
                            <TableHeader>Packing</TableHeader>
                            <TableHeader>Batch No</TableHeader>
                            <TableHeader>Approved By</TableHeader>
                            <TableHeader>Reason</TableHeader>
                            <TableHeader>Actions</TableHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(stockAdjustItemsList.length > 0) ? stockAdjustItemsList.map((item, index) => (
                            <TableRow key={index} title={`${index}`}>
                                <TableCell >{index + 1}</TableCell>
                                <TableCell >{products.find(p => BigInt(p.id) === BigInt(item.product_id!))?.item_no}</TableCell>
                                <TableCell >{products.find(p => BigInt(p.id) === BigInt(item.product_id!))?.description}</TableCell>
                                <TableCell className="text-zinc-500">{item.quantity}</TableCell>
                                <TableCell className="text-zinc-500">{products.find(p => BigInt(p.id) === BigInt(item.product_id!))?.uom}</TableCell>
                                <TableCell className="text-zinc-500">{products.find(p => BigInt(p.id) === BigInt(item.product_id!))?.packing}</TableCell>
                                <TableCell className="text-zinc-500">{item.batch_no}</TableCell>
                                <TableCell className="text-zinc-400">{item.approved_by}</TableCell>
                                <TableCell className="text-zinc-400">{item.reason}</TableCell>
                                <TableCell className="py-5 flex gap-4">
                                    <a onClick={() => handleOpenEditDialog(index)} className="text-zinc-600 hover:text-zinc-900">
                                        <PencilIcon className='h-5 w-5' />
                                    </a>
                                    <a onClick={() => handleDeleteItem(index)} className="text-zinc-600 hover:text-zinc-900">
                                        <TrashIcon className='h-5 w-5' />
                                    </a>
                                </TableCell>
                            </TableRow>
                        )) : <p className='py-8'>No items found.</p>}
                    </TableBody>
                </Table>
            </div>

            {/* Edit Stock Adjust Item Dialog */}
            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                <DialogTitle>Edit Stock Adjust Item</DialogTitle>
                <DialogDescription>
                    You can edit the quantity, batch no, and expiry date of the Stock-Adjust item.
                </DialogDescription>
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 mt-4">
                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>Quantity</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    aria-label="Edit Quantity"
                                    type="number"
                                    name="quantity"
                                    value={editForm.quantity || ''}
                                    onChange={handleEditFormChange}
                                />
                            </div>
                        </section>
                    </div>
                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>Batch Number</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    aria-label="Edit Batch No"
                                    type="text"
                                    name="batch_no"
                                    value={editForm.batch_no || ''}
                                    onChange={handleEditFormChange}
                                />
                            </div>
                        </section>
                    </div>

                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>Approved By</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    aria-label="Approved By"
                                    type="text"
                                    name="approved_by"
                                    value={editForm.approved_by || ''}
                                    onChange={handleEditFormChange}
                                />
                            </div>
                        </section>
                    </div>

                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>Reason</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    aria-label="Reason"
                                    type="text"
                                    name="reason"
                                    value={editForm.reason || ''}
                                    onChange={handleEditFormChange}
                                />
                            </div>
                        </section>
                    </div>
                </div>
                <DialogActions>
                    <Button plain onClick={() => setIsOpen(false)}>
                        Cancel
                    </Button>
                    <Button onClick={handleEditSubmit}>Save</Button>
                </DialogActions>
            </Dialog>
        </form>
    );
};

export default AddStockAdjustItemPageId;
