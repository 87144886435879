import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWarehouses } from '../../../../hooks/useWarehouse';
import { AppUser } from '../../../../models/AppUser';
import showErrorToast from '../../../../components/Toasts/ShowErrorToast';
import { useAppUser } from '../../../../hooks/useAppUser';
import showLoadingToast from '../../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../../components/Toasts/ShowSuccessToast';
import Loading from '../../../../components/Loading';
import { Heading, Subheading } from '../../../../components/Heading';
import { Button } from '../../../../components/Button';
import { Divider } from '../../../../components/Divider';
import { Input } from '../../../../components/Input';
import { Select } from '../../../../components/Select';
import { Switch, SwitchField, SwitchGroup } from '../../../../components/Switch';
import { Text } from '../../../../components/Text';
import { useAppAnnouncements } from '../../../../hooks/useAppAnnouncement';
import { UserContext } from '../../../../context/userContext';
import { Textarea } from '../../../../components/Textarea';

interface AddAnnouncement {
    title: string;
    content: string;
    created_user_id: bigint;
    warehouse_id: bigint;
}

const AddAppAnnouncementPage = () => {
    const navigate = useNavigate();
    const { warehouses } = useWarehouses();
    const { appUsers } = useAppUser();

    const [announcement, setAnnouncement] = useState<AddAnnouncement>({
        title: '',
        content: '',
        created_user_id: BigInt(0),
        warehouse_id: BigInt(0),
    });
    const { addAnnouncement, loading, error } = useAppAnnouncements();
    const { currentUser } = useContext(UserContext);

    useEffect(() => {
        document.title = "Add App Announcement";

        if (warehouses.length > 0) {
            setAnnouncement((prev) => ({ ...prev, warehouse_id: warehouses[0].id }));
        }
        if (appUsers.length > 0) {
            setAnnouncement((prev) => ({ ...prev, created_user_id: BigInt(appUsers[0].id) }));
        }
    }, []);

    useEffect(() => {
        if (currentUser) {
            setAnnouncement(prev => ({ ...prev, created_user_id: currentUser.id }));
        }
    }, [currentUser]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setAnnouncement(prev => ({ ...prev, [name]: value }));
    };

    const validateForm = () => {

        if (!announcement.title) {
            showErrorToast('Username is required');
            return false;
        }
        if (!announcement.content) {
            showErrorToast('Password is required');
            return false;
        }
        return true;
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        try {
            const announcementToSend = {
                ...announcement,
                created_user_id: announcement?.created_user_id.toString(), // ✅ Ensure it's a number (not a string)
                warehouse_id: announcement.warehouse_id.toString(),
            };

            const res = await addAnnouncement(announcementToSend);
            if (res.dbsuccess) {
                showLoadingToast(`Creating Announcement ...`, 100);
                setTimeout(() => {
                    navigate('/gp/app/announcements');
                    showSuccessToast(`Announcement added successfully`);
                }, 1000);
            } else {
                showErrorToast(`Error creating announcement: ${res.message}`);
            }
        } catch (err) {
            showErrorToast(`Failed to add announcement: ${err}`);
        }
    };


    if (loading) return <Loading />;
    if (error) return <p>{error}</p>;

    return (
        <form method="post" onSubmit={handleSubmit} className="mx-auto max-w-6xl">
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
                <Heading>Add App Announcement</Heading>
                <div className='mt-5 flex lg:ml-4 lg:mt-0'>
                    <Button type="reset" outline onClick={() => navigate(-1)}>
                        Cancel
                    </Button>
                    <Button type="submit" className='ml-3' disabled={!announcement.title || !announcement.content}>Save</Button>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Announcement Details</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            A brief summary highlighting key personal details.
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Title</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        type="text"
                                        aria-label="Title"
                                        name="title"
                                        value={announcement.title?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Content</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Textarea
                                        aria-label="Content"
                                        name="content"
                                        value={announcement.content?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Warehouse</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Select
                                        aria-label="Warehouse"
                                        name="warehouse_id"
                                        value={announcement.warehouse_id?.toString()}
                                        onChange={handleChange}
                                    >
                                        {warehouses.map(warehouse => (
                                            <option key={warehouse.id.toString()} value={warehouse.id.toString()}>
                                                {warehouse.warehouse_name}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Created User</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Select
                                        aria-label="Created User"
                                        name="created_user_id"
                                        value={announcement.created_user_id?.toString()}
                                        onChange={handleChange}
                                    >
                                        {appUsers.map(user => (
                                            <option key={user.id.toString()} value={user.id.toString()}>
                                                {user.username}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>

                <div className="flex justify-end gap-4 my-6">
                    <Button type="reset" plain onClick={() => navigate(-1)}>
                        Cancel
                    </Button>
                    <Button type="submit" disabled={!announcement.title || !announcement.content}>Save</Button>
                </div>
            </div>
        </form>
    );
};

export default AddAppAnnouncementPage;
