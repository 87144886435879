import React, { useEffect, useState } from 'react';
import { BanknotesIcon, CalendarIcon, ChevronLeftIcon, CreditCardIcon } from '@heroicons/react/16/solid';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AppUser } from '../../../../models/AppUser';
import { useAppUser } from '../../../../hooks/useAppUser';
import showErrorToast from '../../../../components/Toasts/ShowErrorToast';
import { AppUserService } from '../../../../services/appUserService';
import showSuccessToast from '../../../../components/Toasts/ShowSuccessToast';
import Loading from '../../../../components/Loading';
import { Avatar } from '../../../../components/Avatar';
import { Heading, Subheading } from '../../../../components/Heading';
import { Badge } from '../../../../components/Badge';
import dateFormat from 'dateformat';
import { Button } from '../../../../components/Button';
import { Divider } from '../../../../components/Divider';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from '../../../../components/Description-List';
import { Dialog, DialogActions, DialogDescription, DialogTitle } from '../../../../components/Dialog';
import { AppTrackLocation } from '../../../../models/AppTrackLocation';
import { useAppTrackLocations } from '../../../../hooks/useAppTrackLocation';
import { useAppPreCallPlans } from '../../../../hooks/useAppPreCallPlan';
import { PreCallPlan } from '../../../../models/AppPreCallPlan';
import { PostCallPlan } from '../../../../models/AppPostCallPlan';
import { useAppPostCallPlans } from '../../../../hooks/useAppPostCallPlan';

const ViewAppPostCallPlanIdPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [plan, setPlan] = useState<PostCallPlan | null>(null);
    const navigate = useNavigate();
    const { fetchPostCallPlanById, loading, error, } = useAppPostCallPlans();

    useEffect(() => {
        document.title = "View App Post Call Plan";
    }, []);

    useEffect(() => {
        if (!id) return;

        const fetchPlanDetails = async () => {
            try {
                const fetchedPlan = await fetchPostCallPlanById(Number(id));
                setPlan(fetchedPlan!);
            } catch (err) {
                console.error('Failed to fetch post call plan details:', err);
            }
        };

        fetchPlanDetails();
    }, [id]);

    if (loading) return <Loading />;
    if (error) return <p className="py-16 text-center text-red-700">{error}</p>;
    if (!plan) return <p className="py-16 text-center">Post Call Plan not found.</p>;

    return (
        <>
            <div className="max-lg:hidden">
                <Link to="/gp/app/postcallplans" className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400">
                    <ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500" />
                    Post Call Plans
                </Link>
            </div>
            <div className="mt-4 lg:mt-8">
                <div className="flex items-center gap-4">
                    <Avatar square initials={plan.pre_call_plan_id.toString()} className="bg-zinc-300 text-gray-700 size-8" />
                    <Heading>{plan.pre_call_plan_id}</Heading>
                </div>
                <div className="isolate mt-2.5 flex flex-wrap justify-between gap-x-6 gap-y-4">
                    <div className="flex flex-wrap gap-x-10 gap-y-4 py-1.5">
                        <span className="flex items-center gap-2 text-base/6 text-zinc-950 sm:text-sm/6 dark:text-white">
                            <CalendarIcon className="size-4 shrink-0 fill-zinc-400 dark:fill-zinc-500" />
                            <span>{dateFormat(plan.created_time, "mmmm dd, yyyy")}</span>
                        </span>
                    </div>

                </div>
            </div>

            <div className="mt-12">
                <Subheading>Pre Call Plan</Subheading>
                <Divider className="mt-4" />
                <DescriptionList>
                    <DescriptionTerm>Pre Call Plans</DescriptionTerm>
                    <DescriptionDetails>{plan.pre_call_plan_id || '-'}</DescriptionDetails>
                    <DescriptionTerm>Salesman</DescriptionTerm>
                    <DescriptionDetails>{plan.salesman_username || '-'}</DescriptionDetails>
                    <DescriptionTerm>Product</DescriptionTerm>
                    <DescriptionDetails>{plan.product_description || '-'}</DescriptionDetails>
                    <DescriptionTerm>Customer</DescriptionTerm>
                    <DescriptionDetails>{plan.customer_fullname || '-'}</DescriptionDetails>
                    <DescriptionTerm>Remarks</DescriptionTerm>
                    <DescriptionDetails>{plan.remarks || '-'}</DescriptionDetails>
                </DescriptionList>
            </div>
        </>
    );
};

export default ViewAppPostCallPlanIdPage;
