import { useState, useEffect, useContext, useRef } from 'react';
import { WarehouseProduct } from '../models/WarehouseProduct';
import { WarehouseProductService } from '../services/warehouseProductService';
import { UserContext } from '../context/userContext';

export const useWarehouseProduct = () => {
    const [products, setProducts] = useState<WarehouseProduct[]>([]);
    const [stockRemindProducts, setStockRemindProducts] = useState<WarehouseProduct[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [totalProducts, setTotalProducts] = useState<number>(0);
    const { currentUser } = useContext(UserContext);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const productListRef = useRef<HTMLDivElement>(null);
    const scrollPosition = useRef(0);
    const [isNewSearch, setIsNewSearch] = useState<boolean>(false); // Track new searches/filters

    const fetchAllFilteredProductsOffset = async (
        limit: number,
        offset: number,
        searchQuery: string,
        filterStartDate: string,
        filterEndDate: string,
        filterYear: string,
        filterWarehouseId: string
    ) => {
        if (productListRef.current) {
            scrollPosition.current = productListRef.current.scrollTop;
        }
        setError(null);

        try {
            let productData: WarehouseProduct[] = [];

            if (currentUser?.warehouse_hq === "1") {
                productData = await WarehouseProductService.getAllProducts(
                    limit,
                    offset,
                    searchQuery,
                    filterStartDate,
                    filterEndDate,
                    filterYear,
                    filterWarehouseId
                );
            } else if (currentUser?.warehouse_id && currentUser.role!.toString() !== "1") {
                productData = await WarehouseProductService.getAllProductsByWarehouseId(
                    currentUser.warehouse_id.toString(),
                    limit,
                    offset,
                    searchQuery,
                    filterStartDate,
                    filterEndDate,
                    filterYear,
                    filterWarehouseId
                );
            }

            if (productData.length === 0) {
                setHasMore(false);
            } else {
                // If it's a new search/filter, replace the products array
                if (isNewSearch || offset === 0) {
                    setProducts(productData);
                    setIsNewSearch(false); // Reset the flag
                } else {
                    // Otherwise, append to the existing products array
                    setProducts((prevProducts) => [...prevProducts, ...productData]);
                }
            }
        } catch (err) {
            setError('Failed to fetch products');
        } finally {
            setLoading(false);

            setTimeout(() => {
                if (productListRef.current) {
                    productListRef.current.scrollTop = scrollPosition.current;
                }
            }, 0);
        }
    };

    const fetchAllFilteredProductsStockRemind = async () => {
        setLoading(true);
        setError(null);
        try {
            if (currentUser?.warehouse_hq === "1") {
                const productData = await WarehouseProductService.getAllProductsStockRemind();
                setStockRemindProducts(productData);
            } else if (currentUser?.warehouse_id && currentUser.role!.toString() !== "1") {
                const productData = await WarehouseProductService.getAllProductsStockRemindByWarehouseId(
                    currentUser.warehouse_id.toString()
                );
                setStockRemindProducts(productData);
            }
        } catch (err) {
            setError('Failed to fetch products');
        } finally {
            setLoading(false);
        }
    };

    const fetchProductById = async (id: number) => {
        try {
            setLoading(true);
            const product = await WarehouseProductService.getProductById(id);
            return product;
        } catch (err) {
            setError('Failed to fetch this product');
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const createProduct = async (product: Omit<WarehouseProduct, 'id' | 'created_time' | 'updated_time'>) => {
        try {
            const newProductRes = await WarehouseProductService.createProduct(product);
            return newProductRes;
        } catch (err) {
            setError('Failed to add product');
        }
    };

    const updateProduct = async (id: number, updatedProduct: Omit<WarehouseProduct, 'created_time' | 'updated_time'>) => {
        try {
            const updatedProductRes = await WarehouseProductService.updateProduct(id, updatedProduct);
            return updatedProductRes;
        } catch (err) {
            setError('Failed to update product');
        }
    };

    const deleteProduct = async (id: number) => {
        setLoading(true);
        setError(null);
        try {
            await WarehouseProductService.deleteProduct(id);
            setProducts((prevProducts) => prevProducts.filter((i) => BigInt(i.id) !== BigInt(id)));
        } catch (err) {
            setError('Failed to delete product item');
        } finally {
            setLoading(false);
        }
    };

    // Automatically fetch products when the hook is first used
    useEffect(() => {
        fetchAllFilteredProductsStockRemind();
    }, [currentUser]);

    return {
        products,
        stockRemindProducts,
        loading,
        error,
        createProduct,
        updateProduct,
        deleteProduct,
        fetchProductById,
        totalProducts,
        fetchAllFilteredProductsOffset,
        hasMore,
        setIsNewSearch, // Expose setIsNewSearch to the component
    };
};