import React, { useEffect, useState } from 'react';
import { BanknotesIcon, CalendarIcon, ChevronLeftIcon, CreditCardIcon } from '@heroicons/react/16/solid';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AppUser } from '../../../../models/AppUser';
import { useAppUser } from '../../../../hooks/useAppUser';
import showErrorToast from '../../../../components/Toasts/ShowErrorToast';
import { AppUserService } from '../../../../services/appUserService';
import showSuccessToast from '../../../../components/Toasts/ShowSuccessToast';
import Loading from '../../../../components/Loading';
import { Avatar } from '../../../../components/Avatar';
import { Heading, Subheading } from '../../../../components/Heading';
import { Badge } from '../../../../components/Badge';
import dateFormat from 'dateformat';
import { Button } from '../../../../components/Button';
import { Divider } from '../../../../components/Divider';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from '../../../../components/Description-List';
import { Dialog, DialogActions, DialogDescription, DialogTitle } from '../../../../components/Dialog';

const ViewAppUserIdPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [user, setUser] = useState<AppUser | null>(null);
    const [isOpen, setIsOpen] = useState(false); // State for dialog open status
    const [isResetting, setIsResetting] = useState(false); // State for reset password loading
    const [resetMessage, setResetMessage] = useState<string | null>(null); // Message for reset success/error
    const navigate = useNavigate();
    const { fetchUserById, loading, error, } = useAppUser();

    useEffect(() => {
        document.title = "View App User";
    }, []);

    useEffect(() => {
        if (!id) return;

        const fetchUser = async () => {
            try {
                const userData = await fetchUserById(id);
                setUser(userData!);
            } catch (err) {
                console.error('Failed to fetch user details:', err);
            }
        };

        fetchUser();
    }, [id]);

    // Function to handle resetting the password
    const handleResetPassword = async () => {
        if (!user) return;
        if (!user.email) {
            showErrorToast('Email is required for resetting password');
            return;
        }
        setIsResetting(true);
        setResetMessage(null);

        try {
            const result = await AppUserService.resetPasswordByRandom(user.username);
            setResetMessage(result.message);
            if (result.dbsuccess) {
                showSuccessToast(result.message);
            } else {
                showErrorToast(result.message);
            }
        } catch (err) {
            console.error('Failed to reset password:', err);
            setResetMessage('Failed to reset password. Please try again.');
            showErrorToast('Failed to reset password. Please try again.');
        } finally {
            setIsResetting(false);
            setIsOpen(false); // Close the dialog after action
        }
    };

    if (loading) return <Loading />;
    if (error) return <p className="py-16 text-center text-red-700">{error}</p>;
    if (!user) return <p className="py-16 text-center">User not found.</p>;

    return (
        <>
            <div className="max-lg:hidden">
                <Link to="/gp/app/users" className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400">
                    <ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500" />
                    App Users
                </Link>
            </div>
            <div className="mt-4 lg:mt-8">
                <div className="flex items-center gap-4">
                    <Avatar square initials={user.username[0]} className="bg-zinc-300 text-gray-700 size-8" />
                    <Heading>{user.username}</Heading>
                </div>
                <div className="isolate mt-2.5 flex flex-wrap justify-between gap-x-6 gap-y-4">
                    <div className="flex flex-wrap gap-x-10 gap-y-4 py-1.5">
                        <span className="flex items-center gap-2 text-base/6 text-zinc-950 sm:text-sm/6 dark:text-white">
                            <CalendarIcon className="size-4 shrink-0 fill-zinc-400 dark:fill-zinc-500" />
                            <span>{dateFormat(user.created_time, "mmmm dd, yyyy")}</span>
                        </span>
                    </div>
                    <div className="flex gap-4">
                        <Button outline onClick={() => setIsOpen(true)}>Reset Password</Button>
                        <Button onClick={() => navigate(`/gp/app/users/edit/${user.id}`)}>Edit</Button>
                    </div>
                </div>
            </div>

            <div className="mt-12">
                <Subheading>App User Information</Subheading>
                <Divider className="mt-4" />
                <DescriptionList>

                    <DescriptionTerm>Email</DescriptionTerm>
                    <DescriptionDetails>{user.email || '-'}</DescriptionDetails>
                    <DescriptionTerm>Phone</DescriptionTerm>
                    <DescriptionDetails>{user.mobile_no || '-'}</DescriptionDetails>

                </DescriptionList>
            </div>
            <div className="mt-12">
                <Subheading>Mobile App Account Information</Subheading>
                <Divider className="mt-4" />
                <DescriptionList>
                    <DescriptionTerm>Username</DescriptionTerm>
                    <DescriptionDetails>{user.username}</DescriptionDetails>
                    <DescriptionTerm>Warehouse</DescriptionTerm>
                    <DescriptionDetails className="font-medium">{user.warehouse_id || '-'}</DescriptionDetails>
                    <DescriptionTerm>Admin Account</DescriptionTerm>
                    <DescriptionDetails className="font-medium">{(user.is_admin == '1') ? 'Yes' : 'No'}</DescriptionDetails>
                </DescriptionList>
            </div>

            {/* Reset Password Confirmation Dialog */}
            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                <DialogTitle>Reset User Password</DialogTitle>
                <DialogDescription>
                    Are you sure you want to reset this user’s password? A new password will be sent to the user's email.
                </DialogDescription>
                <DialogActions>
                    <Button plain onClick={() => setIsOpen(false)} disabled={isResetting}>
                        Cancel
                    </Button>
                    <Button onClick={handleResetPassword} disabled={isResetting}>
                        {isResetting ? 'Resetting...' : 'Reset'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ViewAppUserIdPage;
