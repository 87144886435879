import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { EllipsisVerticalIcon, EyeIcon, MagnifyingGlassIcon, PencilIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/16/solid';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '../../../components/Dropdown';
import { Badge } from '../../../components/Badge';
import { Link } from '../../../components/Link';
import { Button } from '../../../components/Button';
import { Heading, Subheading } from '../../../components/Heading';
import { Input, InputGroup } from '../../../components/Input';
import { Divider } from '../../../components/Divider';
import { Avatar } from '../../../components/Avatar';
import Loading from '../../../components/Loading';
import Pagination from '../../../components/Pagination';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../../../components/Dialog';
import { useWarehouseProduct } from '../../../hooks/useWarehouseProduct';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../components/Table';
import dateFormat from 'dateformat';
import { UserContext } from '../../../context/userContext';
import { WarehouseProduct } from '../../../models/WarehouseProduct';
import { Text } from '../../../components/Text';
import { useWarehouses } from '../../../hooks/useWarehouse';

const WarehouseProductPage: React.FC = () => {
    const { currentUser } = useContext(UserContext);
    const {
        loading,
        error,
        deleteProduct,
        products,
        fetchAllFilteredProductsOffset,
        hasMore,
        setIsNewSearch
    } = useWarehouseProduct();
    const { warehouses } = useWarehouses();
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [isOpen, setIsOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [selectedProductId, setSelectedProductId] = useState<string | null>(null);
    const [filterStartDate, setFilterStartDate] = useState('');
    const [filterEndDate, setFilterEndDate] = useState('');
    const [filterYear, setFilterYear] = useState('');
    const [filterWarehouseId, setFilterWarehouseId] = useState('');
    const navigate = useNavigate();
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [limit, setLimit] = useState(20);
    const [offset, setOffset] = useState(0);
    const productListRef = useRef<HTMLDivElement>(null);
    const scrollPosition = useRef(0);

    useEffect(() => {
        document.title = "Products";
    }, []);

    // Reset offset and products when search or filter changes
    useEffect(() => {
        setIsNewSearch(true); // Indicate a new search/filter
        setOffset(0); // Reset offset
    }, [searchQuery, filterStartDate, filterEndDate, filterYear, filterWarehouseId]);

    // Fetch products when offset changes
    useEffect(() => {
        fetchAllFilteredProductsOffset(limit, offset, searchQuery, filterStartDate, filterEndDate, filterYear, filterWarehouseId);
    }, [limit, offset, searchQuery, filterStartDate, filterEndDate, filterYear, filterWarehouseId]);

    // Infinite scroll handler
    useEffect(() => {
        if (productListRef.current) {
            productListRef.current.scrollTop = scrollPosition.current;
        }
        const handleScroll = () => {
            if (
                window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 100 &&
                !loading &&
                hasMore
            ) {
                setOffset(prevOffset => prevOffset + limit);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [loading, hasMore]);

    // // Filter products based on multiple criteria
    // const filteredProduct = Array.isArray(products)
    //     ? products.filter((product) => {
    //         const matchesSearch = product.description?.toLowerCase().includes(searchQuery.toLowerCase());
    //         const matchesStartDate = !filterStartDate || new Date(product.created_time) >= new Date(filterStartDate);
    //         const matchesEndDate = !filterEndDate || new Date(product.created_time) <= new Date(filterEndDate);
    //         const matchesYear = !filterYear || new Date(product.created_time).getFullYear().toString() === filterYear;
    //         const matchesWarehouse = !filterWarehouseId || product.warehouse_id === Number(filterWarehouseId);

    //         return matchesSearch && matchesStartDate && matchesEndDate && matchesYear && matchesWarehouse;
    //     }) : [];

    const handleOpenDeleteDialog = (productId: string) => {
        setSelectedProductId(productId);
        setIsOpen(true);
    };

    const handleDelete = async () => {
        if (!selectedProductId) return;

        setIsDeleting(true);
        try {
            await deleteProduct(Number(selectedProductId));
            setIsOpen(false);
        } catch (error) {
            console.error('Failed to delete product:', error);
        } finally {
            setIsDeleting(false);
        }
    };

    return (
        <>
            <div className="flex flex-wrap items-end justify-between gap-4">
                <div className="max-sm:w-full sm:flex-1">
                    <Heading>Products</Heading>
                    <div className="mt-4 flex max-w-xl gap-4">
                        <div className="flex-1">
                            <InputGroup>
                                <MagnifyingGlassIcon />
                                <Input
                                    name="search"
                                    placeholder="Search product&hellip;"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </InputGroup>
                        </div>
                    </div>
                </div>
                <Button onClick={() => navigate('/gp/inventories/products/add')}>Create new product</Button>
            </div>

            {/* Date, Year, and Warehouse Filters */}
            <div className="mt-4 px-6 py-4 bg-zinc-50 ring-1 ring-zinc-200 rounded-md">
                <div className='flex flex-wrap items-end justify-between gap-4'>
                    <Subheading>Filters & Sorting</Subheading>
                    {(isFilterOpen) ? <ChevronUpIcon onClick={() => setIsFilterOpen(false)} className='w-5 h-5' /> : <ChevronDownIcon onClick={() => setIsFilterOpen(true)} className='w-5 h-5' />}
                </div>
                {(isFilterOpen) && <div className='mt-4 grid gap-4 sm:grid-cols-1'>
                    <div>
                        <Text>From Date</Text>
                        <Input
                            type="date"
                            value={filterStartDate}
                            onChange={(e) => setFilterStartDate(e.target.value)}
                        />
                    </div>
                    <div>
                        <Text>To Date</Text>
                        <Input
                            type="date"
                            value={filterEndDate}
                            onChange={(e) => setFilterEndDate(e.target.value)}
                        />
                    </div>
                    <div>
                        <Text>By Year</Text>
                        <Input
                            type="number"
                            placeholder="Year"
                            value={filterYear}
                            onChange={(e) => setFilterYear(e.target.value)}
                        />
                    </div>
                    <div>
                        <Button onClick={() => {
                            setFilterStartDate('');
                            setFilterEndDate('');
                            setFilterYear('');
                            setFilterWarehouseId('');
                        }}>
                            Reset Filter
                        </Button>
                    </div>
                    {(currentUser?.role.toString() !== "5") && (
                        <div className='max-w-full'>
                            <Text>By Warehouse</Text>
                            <Dropdown>
                                <DropdownButton outline>
                                    {filterWarehouseId ? warehouses?.find(w => w.id?.toString() === filterWarehouseId)?.warehouse_name : 'All Warehouses'}
                                    <ChevronDownIcon />
                                </DropdownButton>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => setFilterWarehouseId('')}>All Warehouses</DropdownItem>
                                    {warehouses?.map((warehouse) => (
                                        <DropdownItem key={warehouse.id} onClick={() => setFilterWarehouseId(warehouse.id?.toString()!)}>
                                            {warehouse.warehouse_name}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    )}
                </div>}
            </div>

            {/* Product Table */}
            <ul className="mt-10">
                {loading && <div className='w-full text-center h-full py-36 justify-center'><Loading /></div>}
                {error && <p className='py-16 text-center text-red-700'>{error}</p>}
                {(!loading && !error) && (
                    <div>
                        <Table className="[--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
                            <TableHead>
                                <TableRow>
                                    <TableHeader>S/N</TableHeader>
                                    <TableHeader>Description</TableHeader>
                                    <TableHeader>Category</TableHeader>
                                    <TableHeader>Group</TableHeader>
                                    <TableHeader>Created on</TableHeader>
                                    <TableHeader className="text-center">Actions</TableHeader>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(products.length > 0) ? products.map((product, index) => (
                                    <TableRow key={product.id} title={`${product.barcode}`}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell className="text-zinc-500">{product.description ?? '-'}</TableCell>
                                        <TableCell className="text-zinc-500">{product.category_name ?? '-'}</TableCell>
                                        <TableCell className="text-zinc-500">{product.group_name ?? '-'}</TableCell>
                                        <TableCell className="text-zinc-500">{dateFormat(product.created_time?.toString(), "mmm dd, yyyy")}</TableCell>
                                        <TableCell className="py-5 grid grid-cols-3 text-zinc-500">
                                            <a onClick={() => navigate(`/gp/inventories/products/edit/${product.id}`)} className="text-zinc-600 hover:text-zinc-900">
                                                <PencilIcon className='h-5 w-5' />
                                            </a>
                                            <a onClick={() => navigate(`/gp/inventories/products/view/${product.id}`)} className="text-zinc-600 hover:text-zinc-900">
                                                <EyeIcon className='h-5 w-5' />
                                            </a>
                                            <Dropdown>
                                                <DropdownButton plain aria-label="More options" className='h-5 w-5'>
                                                    <EllipsisVerticalIcon />
                                                </DropdownButton>
                                                <DropdownMenu anchor="bottom end">
                                                    <DropdownItem onClick={() => handleOpenDeleteDialog(product.id.toString())}>Delete</DropdownItem>
                                                    <DropdownItem onClick={() => navigate(`/gp/inventories/products/prices/add/${product.id}`)}>Add Product Price</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </TableCell>
                                    </TableRow>
                                )) : <TableRow>
                                    <TableCell colSpan={9} className="text-center py-8">No product found.</TableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                        {loading && <div className="text-center py-4">Loading more products...</div>}
                        {!hasMore && <div className="text-center py-4">No more products to load.</div>}
                    </div>
                )}
            </ul>

            {/* Delete Confirmation Dialog */}
            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                <DialogTitle>Delete product</DialogTitle>
                <DialogDescription>
                    Are you sure you want to delete this product? This action cannot be undone.
                </DialogDescription>
                <DialogActions>
                    <Button plain onClick={() => setIsOpen(false)} disabled={isDeleting}>
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} disabled={isDeleting}>
                        {isDeleting ? <Loading /> : 'Delete'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default WarehouseProductPage;