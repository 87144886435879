import React, { useContext, useEffect, useState } from 'react';
import { EllipsisVerticalIcon, EyeIcon, MagnifyingGlassIcon, PencilIcon } from '@heroicons/react/16/solid';
import { Button } from '../../components/Button';
import { Heading } from '../../components/Heading';
import { Input, InputGroup } from '../../components/Input';
import Loading from '../../components/Loading';
import Pagination from '../../components/Pagination'; // Import the Pagination component
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../../components/Dialog';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../components/Table';
import dateFormat from 'dateformat';
import { UserContext } from '../../context/userContext';
import { useAppSalesOrders } from '../../hooks/useAppSalesOrder';

const AppSalesOrdersPage: React.FC = () => {
    const { loading, error, salesOrders } = useAppSalesOrders();
    const [searchQuery, setSearchQuery] = useState<string>(''); // State to store the search query
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Sales Orders";
    }, []);

    const filteredSalesOrders = salesOrders.filter((order) =>
        order.product_description!.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>
            <div className="flex flex-wrap items-end justify-between gap-4">
                <div className="max-sm:w-full sm:flex-1">
                    <Heading>Sales Orders</Heading>
                    <div className="mt-4 flex max-w-xl gap-4">
                        <div className="flex-1">
                            <InputGroup>
                                <MagnifyingGlassIcon />
                                <Input
                                    name="search"
                                    placeholder="Search category&hellip;"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </InputGroup>
                        </div>
                    </div>
                </div>
            </div>

            <ul className="mt-10">
                {loading && <div className='w-full text-center h-full py-36 justify-center'><Loading /></div>}
                {error && <p className='py-16 text-center text-red-700'>{error}</p>}
                {(!loading && !error) && (
                    <div>
                        <Table className="[--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
                            <TableHead>
                                <TableRow>
                                    <TableHeader>S/N</TableHeader>
                                    <TableHeader>Customer</TableHeader>
                                    <TableHeader>Product</TableHeader>
                                    <TableHeader>Price</TableHeader>
                                    <TableHeader>Quantity</TableHeader>
                                    <TableHeader>Created on</TableHeader>
                                    <TableHeader className="text-center">Actions</TableHeader>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(filteredSalesOrders.length > 0) ? filteredSalesOrders.map((order, index) => (
                                    <TableRow key={order.id} title={`${order.product_description}`}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{order.customer_fullname}</TableCell>
                                        <TableCell className="text-zinc-500">{order.product_description}</TableCell>
                                        <TableCell className="text-zinc-500">{order.price}</TableCell>
                                        <TableCell className="text-zinc-500">{order.quantity}</TableCell>
                                        <TableCell className="text-zinc-500">{dateFormat(order.created_time?.toString(), "mmm dd, yyyy")}</TableCell>
                                        <TableCell className="py-5 flex gap-4 text-zinc-500 justify-center">

                                            <a onClick={() => navigate(`/gp/app/salesorders/view/${order.id}`)} className="text-zinc-600 hover:text-zinc-900">
                                                <EyeIcon className='h-5 w-5' />
                                            </a>

                                        </TableCell>
                                    </TableRow>
                                )) : <TableRow>
                                    <TableCell colSpan={9} className="text-center py-8">No sales orders found.</TableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </div>
                )}
            </ul>
        </>
    );
};

export default AppSalesOrdersPage;
