import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Heading, Subheading } from '../../../../../components/Heading';
import { Divider } from '../../../../../components/Divider';
import { Input } from '../../../../../components/Input';
import { Select } from '../../../../../components/Select';
import { Button } from '../../../../../components/Button';
import showLoadingToast from '../../../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../../../components/Toasts/ShowSuccessToast';
import showErrorToast from '../../../../../components/Toasts/ShowErrorToast';
import { useStockInItems } from '../../../../../hooks/useStockInItem'; // Custom hook for stock-in items
import { useWarehouseProductItem } from '../../../../../hooks/useWarehouseProductItem';
import { useStockIn } from '../../../../../hooks/useStockIn';
import { UserContext } from '../../../../../context/userContext';
import { Text } from '../../../../../components/Text';
import { Dialog, DialogActions, DialogDescription, DialogTitle } from '../../../../../components/Dialog';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../../components/Table';
import dateFormat from 'dateformat';
import { MagnifyingGlassIcon, PencilIcon, TrashIcon } from '@heroicons/react/16/solid';
import { StockIn } from '../../../../../models/StockIn';
import ReactSelect, { SingleValue } from 'react-select';
import { WarehouseProductItem } from '../../../../../models/WarehouseProductItem';
import { useWarehouseProductItemBatchNo } from '../../../../../hooks/useWarehouseProductItemBatchNo';
import { WarehouseProductItemBatchNo } from '../../../../../models/WarehouseProductItemBatchNo';
import { useWarehouseProduct } from '../../../../../hooks/useWarehouseProduct';
import { WarehouseProduct } from '../../../../../models/WarehouseProduct';

interface AddStockInItem {
    product_id: number | null;
    quantity: string | null;
    batch_no: string | null;
    expiry_date: string | null;
    stock_in_id: number | null;
    supplier_id: number | null;
}

const AddStockInItemPageId = () => {
    const { currentUser } = useContext(UserContext);
    const id = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [stockInItem, setStockInItem] = useState<AddStockInItem>({
        product_id: Number(0),
        quantity: '',
        batch_no: '',
        expiry_date: '', // Date as string, or null if not provided
        stock_in_id: Number(0),
        supplier_id: Number(0),
    });
    const [stockInItemsList, setStockInItemsList] = useState<AddStockInItem[]>([]); // To store added stock-in items
    const { createMultipleStockInItems } = useStockInItems(); // Hook for creating multiple stock-in items
    const { stockIns } = useStockIn();
    const {
        loading,
        error,
        products,
    } = useWarehouseProduct();
    const [isOpen, setIsOpen] = useState(false); // Manage the edit dialog state
    const [isBatchNoDialogOpen, setIsBatchNoDialogOpen] = useState(false);
    const [editItemIndex, setEditItemIndex] = useState<number | null>(null); // Track which item is being edited
    const [isDeleting, setIsDeleting] = useState(false); // For delete confirmation
    const [editForm, setEditForm] = useState<Partial<AddStockInItem>>({
        quantity: '',
        batch_no: '',
        expiry_date: '',
    });
    const [selectedStockIn, setSelectedStockIn] = useState<StockIn | null>(null);
    const { fetchBatchNoByProductItemId } = useWarehouseProductItemBatchNo();
    const [batchNos, setBatchNos] = useState<WarehouseProductItemBatchNo[]>([]);

    useEffect(() => {
        document.title = "Add Stock in Items";
    }, []);

    // // Fetch products based on HQ access or specific warehouse
    // useEffect(() => {
    //     const fetchProductItemList = async () => {
    //         try {
    //             if (currentUser?.warehouse_hq === "1") {
    //                 const fetchedProductItem = await getProductItems();
    //                 setProductItemList(fetchedProductItem || []);
    //             } else if (currentUser?.warehouse_id) {
    //                 const fetchedProductItem = await getProductItemsByWarehouseId(currentUser.warehouse_id.toString());
    //                 setProductItemList(fetchedProductItem || []);
    //             }
    //         } catch (error) {
    //             console.error('Error fetching products items:', error);
    //         }
    //     };

    //     fetchProductItemList();
    // }, [currentUser]);

    const fetchBatchNoDetails = async (id: string) => {
        try {
            const res = await fetchBatchNoByProductItemId(Number(id));
            setBatchNos(res);
        } catch (err) {
            showErrorToast(`Failed fetching batch no`);
        }
    }

    useEffect(() => {
        if (!id) return;

        if (id) {
            setStockInItem(prev => ({ ...prev, stock_in_id: Number(id) }));
        }

        const matchingStockIn = stockIns.find((stockIn) => Number(stockIn.id) === Number(id.id));
        if (matchingStockIn) {
            setSelectedStockIn(matchingStockIn);
            setStockInItem(prev => ({ ...prev, stock_in_id: matchingStockIn.id }));
            setStockInItem(prev => ({ ...prev, supplier_id: matchingStockIn.supplier_id }));
        }

        if (products.length > 0) {
            setStockInItem(prev => ({ ...prev, product_id: Number(products[0].id!) }));
        }

        fetchBatchNoDetails(stockInItem.product_id?.toString()!);

    }, [stockIns, products]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setStockInItem(prev => ({ ...prev, [name]: value }));
    };

    // Add the current stock-in item to the table
    const handleAddToTable = () => {
        if (!stockInItem.product_id || !stockInItem.batch_no || !stockInItem.expiry_date || !stockInItem.quantity) {
            showErrorToast("Please fill in all field before add to table");
            return;
        }
        const { quantity, batch_no, expiry_date } = stockInItem;

        // Validation: Ensure that quantity, batch_no, and expiry_date are not empty
        if (!quantity || !batch_no || !expiry_date) {
            showErrorToast('Please fill in Quantity, Batch No, and Expiry Date before adding to table.');
            return;
        }

        setStockInItem({
            product_id: Number(0),
            quantity: '',
            batch_no: '',
            expiry_date: '',
            stock_in_id: Number(selectedStockIn!.id),
            supplier_id: Number(selectedStockIn!.supplier_id),
        }); // Reset the form fields after adding

        if (products.length > 0) {
            setStockInItem(prev => ({ ...prev, product_id: Number(products[0].id!) }));
        }

        setStockInItemsList(prevList => [...prevList, stockInItem]);

    };

    // Save all stock-in items in the table
    const handleSaveAll = async () => {
        try {
            showLoadingToast(`Saving all items...`, 100);
            const res = await createMultipleStockInItems(stockInItemsList); // Save all items
            if (res!.dbsuccess) {
                setTimeout(() => {
                    showSuccessToast(`All items saved successfully.`);
                    navigate('/gp/stocks/stockin'); // Redirect after saving
                }, 1000);
            } else {
                showErrorToast(`Failed adding stock-in items data`);
            }
        } catch (err) {
            showErrorToast(`Failed to save stock-in items: ${err}`);
        }
    };

    // Delete a stock-in item by index
    const handleDeleteItem = (index: number) => {
        setStockInItemsList(prevList => prevList.filter((_, i) => i !== index));
    };

    // Open the edit dialog and prefill form with current item data
    const handleOpenEditDialog = (index: number) => {
        const itemToEdit = stockInItemsList[index];
        setEditForm({
            quantity: itemToEdit.quantity,
            batch_no: itemToEdit.batch_no,
            expiry_date: itemToEdit.expiry_date
        });
        setEditItemIndex(index); // Set the index of the item being edited
        setIsOpen(true); // Open the dialog
    };

    // Update the item in the stockInItemsList
    const handleEditSubmit = () => {
        if (editItemIndex !== null) {
            const updatedItems = [...stockInItemsList];
            updatedItems[editItemIndex] = {
                ...updatedItems[editItemIndex],
                ...editForm
            };
            setStockInItemsList(updatedItems); // Update the list with the edited item
            setIsOpen(false); // Close the dialog
        }
    };

    const handleEditFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setEditForm(prevForm => ({ ...prevForm, [name]: value }));
    };

    const selectProductOptions = products.map((product: WarehouseProduct) => ({
        value: product.id,
        label: `${product.item_no || "-"}  ${product.second_item_no || "-"} : ${product.description || "-"}`,
    }));

    // Handle ReactSelect change
    const handleProductChange = (newValue: SingleValue<{ value: number; label: string }>) => {
        if (newValue) {
            setStockInItem((prev) => ({ ...prev, product_id: Number(newValue.value) }));
            fetchBatchNoDetails(newValue.value.toString()!);
        }
    };

    return (
        <form method="post" className="mx-auto max-w-6xl">
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
                <Heading>Add Stock-In Item</Heading>
                <div className="mt-5 flex lg:ml-4 lg:mt-0">
                    <Button type="button" outline onClick={() => navigate(-1)}>
                        Cancel
                    </Button>
                    <Button type="button" className="ml-3" onClick={handleSaveAll} disabled={stockInItemsList.length === 0}>
                        Save All
                    </Button>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Stock-In Item Information</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            Details of items received into stock.
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                            <Subheading>Stock In Reference No</Subheading>
                            <label className='text-zinc-400'>{selectedStockIn?.reference_no}</label>
                        </div>

                        <div className="sm:col-span-full">
                            <Subheading>Supplier</Subheading>
                            <label className='text-zinc-400'>{selectedStockIn?.supplier_fullname}</label>
                        </div>

                        <div className="sm:col-span-full">
                            <Subheading>Supplier Invoice No</Subheading>
                            <label className='text-zinc-400'>{selectedStockIn?.supplier_invoice}</label>
                        </div>

                        {/* <div className="sm:col-span-full">
                            <section>
                                <div className="space-y-1">
                                    <Subheading>Product</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Select
                                        aria-label="Product"
                                        name="product_id"
                                        value={stockInItem.product_id!.toString()}
                                        onChange={handleChange}
                                    >
                                        {productItemList.map(product => (
                                            <option key={product.id} value={product.id!.toString()}>
                                                {product.item_no}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                            </section>
                        </div> */}

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Product</Subheading>
                                </div>
                                <div className="mt-2">
                                    <ReactSelect
                                        id="department_id"
                                        name="department_id"
                                        options={selectProductOptions}
                                        className="text-sm"
                                        placeholder="Select Product Item"
                                        onChange={handleProductChange}
                                        value={selectProductOptions.find(option => Number(option.value) === Number(stockInItem.product_id!))}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section>
                                <div className="space-y-1">
                                    <Subheading>Quantity</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Quantity"
                                        type="number"
                                        name="quantity"
                                        value={stockInItem.quantity!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section>
                                <div className="space-y-1">
                                    <Subheading>Batch No (Input for new batch number)</Subheading>
                                </div>
                                <div className="mt-2 flex gap-4">
                                    <Input
                                        aria-label="Batch No"
                                        type="text"
                                        name="batch_no"
                                        value={stockInItem.batch_no!}
                                        onChange={handleChange}
                                    />
                                    <Button type="button" onClick={() => { setIsBatchNoDialogOpen(true) }}>
                                        <MagnifyingGlassIcon className='text-white' />
                                    </Button>
                                </div>
                            </section>

                        </div>

                        <div className="sm:col-span-full">
                            <section>
                                <div className="space-y-1">
                                    <Subheading>Expiry Date</Subheading>
                                </div>
                                <Input
                                    type="date"
                                    aria-label="expiry_date"
                                    name="expiry_date"
                                    value={stockInItem.expiry_date || ''}
                                    onChange={handleChange}
                                />
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-end gap-4 my-6">
                <Button type="button" onClick={handleAddToTable} disabled={(!stockInItem.product_id || !stockInItem.batch_no || !stockInItem.expiry_date || !stockInItem.quantity)}>
                    Add to Table
                </Button>
            </div>

            <div>
                <Table className="[--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
                    <TableHead>
                        <TableRow>
                            <TableHeader>S/N</TableHeader>
                            <TableHeader>Product Barcode</TableHeader>
                            <TableHeader>Description</TableHeader>
                            <TableHeader>Quantity</TableHeader>
                            <TableHeader>UOM</TableHeader>
                            <TableHeader>Packing</TableHeader>
                            <TableHeader>Batch No</TableHeader>
                            <TableHeader>Expiry Date</TableHeader>
                            <TableHeader>Actions</TableHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(stockInItemsList.length > 0) ? stockInItemsList.map((product, index) => (
                            <TableRow key={index} title={`${index}`}>
                                <TableCell >{index + 1}</TableCell>
                                <TableCell >{products.find(p => BigInt(p.id) === BigInt(product.product_id!))?.item_no}</TableCell>
                                <TableCell >{products.find(p => BigInt(p.id) === BigInt(product.product_id!))?.description}</TableCell>
                                <TableCell className="text-zinc-500">{product.quantity}</TableCell>
                                <TableCell className="text-zinc-500">{products.find(p => BigInt(p.id) === BigInt(product.product_id!))?.uom}</TableCell>
                                <TableCell className="text-zinc-500">{products.find(p => BigInt(p.id) === BigInt(product.product_id!))?.packing}</TableCell>
                                <TableCell className="text-zinc-500">{product.batch_no}</TableCell>
                                <TableCell className="text-zinc-400">{dateFormat(product.expiry_date?.toString(), "mmmm dd, yyyy")}</TableCell>
                                <TableCell className="py-5 flex gap-4">
                                    <a onClick={() => handleOpenEditDialog(index)} className="text-zinc-600 hover:text-zinc-900">
                                        <PencilIcon className='h-5 w-5' />
                                    </a>
                                    <a onClick={() => handleDeleteItem(index)} className="text-zinc-600 hover:text-zinc-900">
                                        <TrashIcon className='h-5 w-5' />
                                    </a>
                                </TableCell>
                            </TableRow>
                        )) : <TableRow>
                            <TableCell colSpan={9} className="text-center py-8">No item found.</TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
            </div>

            {/* Edit Stock In Item Dialog */}
            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                <DialogTitle>Edit Stock In Item</DialogTitle>
                <DialogDescription>
                    You can edit the quantity, batch no, and expiry date of the stock-in item.
                </DialogDescription>
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 mt-4">
                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>Quantity</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    aria-label="Edit Quantity"
                                    type="number"
                                    name="quantity"
                                    value={editForm.quantity || ''}
                                    onChange={handleEditFormChange}
                                />
                            </div>
                        </section>
                    </div>
                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>Batch Number</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    aria-label="Edit Batch No"
                                    type="text"
                                    name="batch_no"
                                    value={editForm.batch_no || ''}
                                    onChange={handleEditFormChange}
                                />
                            </div>
                        </section>
                    </div>
                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>Expiry Date</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    aria-label="Edit Expiry Date"
                                    type="date"
                                    name="expiry_date"
                                    value={editForm.expiry_date || ''}
                                    onChange={handleEditFormChange}
                                />
                            </div>
                        </section>
                    </div>
                </div>
                <DialogActions>
                    <Button plain onClick={() => setIsOpen(false)}>
                        Cancel
                    </Button>
                    <Button onClick={handleEditSubmit}>Save</Button>
                </DialogActions>
            </Dialog>

            {/** Batch No Dialog */}
            <Dialog open={isBatchNoDialogOpen} onClose={() => setIsBatchNoDialogOpen(false)}>
                <DialogTitle>Batch No List</DialogTitle>
                <DialogDescription>
                    <Text>Click the list below to set batch no.</Text>
                    {(batchNos.length > 0) ? batchNos?.map((batchNo, index) => (
                        <ul role="list" key={batchNo.id}
                            className="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl mt-4">
                            <li className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6" onClick={() => { setStockInItem(prev => ({ ...prev, batch_no: `${batchNo.batch_no}`, expiry_date: `${batchNo.expiry_date}` })); showSuccessToast(`Batch No and Expiry Date copied to field.`); setIsBatchNoDialogOpen(false); }}>
                                <div className="flex min-w-0 gap-x-4">
                                    {index + 1}
                                    <div className="min-w-0 flex-auto">
                                        <div className='flex justify-left text-center gap-5'>
                                            <p className="text-sm font-semibold text-gray-900">
                                                Batch No: {batchNo.batch_no}
                                            </p>
                                        </div>
                                        <p className="text-sm text-gray-500">
                                            Expiry Date: {batchNo.expiry_date}
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    )) : <Text>
                        No batch no found in database.</Text>}
                </DialogDescription>
                <DialogActions>
                    <Button plain onClick={() => setIsBatchNoDialogOpen(false)}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </form>
    );
};

export default AddStockInItemPageId;
