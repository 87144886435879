import React, { useContext, useEffect, useState } from 'react';
import { Button } from '../../../components/Button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../components/Table';
import { useNavigate } from 'react-router-dom';
import { useAppTrackCheckIns } from '../../../hooks/useAppTrackCheckIn';
import { Input, InputGroup } from '../../../components/Input';
import { Heading } from '../../../components/Heading';
import Loading from '../../../components/Loading';
import { EyeIcon } from '@heroicons/react/16/solid';
import dateFormat from 'dateformat';

const AppTrackCheckInPage: React.FC = () => {
    const { loading, error, fetchTrackCheckIns, trackCheckIns } = useAppTrackCheckIns();
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Track Check-Ins";
        fetchTrackCheckIns();
    }, [fetchTrackCheckIns]);

    const filteredTrackCheckIns = trackCheckIns.filter(checkIn =>
        checkIn.location_id.toString().includes(searchQuery.toLowerCase())
    );

    return (
        <div>
            <div className="flex flex-wrap items-end justify-between gap-4">
                <div className="max-sm:w-full sm:flex-1">
                    <Heading>Check Ins</Heading>
                    <div className="mt-4 flex max-w-xl gap-4">
                        <div className="flex-1">
                            <InputGroup>
                                <Input
                                    name="search"
                                    placeholder="Search check in&hellip;"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </InputGroup>
                        </div>
                    </div>
                </div>
            </div>

            <ul className="mt-10">
                {loading && <div className="w-full text-center h-full py-36 justify-center"><Loading /></div>}
                {error && <p className="py-16 text-center text-red-700">{error}</p>}
                {(!loading && !error) && (
                    <div>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableHeader>S/N</TableHeader>
                                    <TableHeader>Salesman</TableHeader>
                                    <TableHeader>Location</TableHeader>
                                    <TableHeader>Time</TableHeader>
                                    <TableHeader className='text-center'>Actions</TableHeader>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredTrackCheckIns.length > 0 ? filteredTrackCheckIns.map((checkIn, index) => (
                                    <TableRow key={checkIn.id}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{checkIn.salesman_username}</TableCell>
                                        <TableCell>{checkIn.location_name}</TableCell>
                                        <TableCell>{dateFormat(checkIn.created_time, "mmmm dd, yyyy hh:MM:ss")}</TableCell>
                                        <TableCell className="py-5 flex gap-4 text-zinc-500 justify-center">

                                            <a onClick={() => navigate(`/gp/app/track/checkins/view/${checkIn.id}`)} className="text-zinc-600 hover:text-zinc-900">
                                                <EyeIcon className='h-5 w-5' />
                                            </a>
                                        </TableCell>
                                    </TableRow>
                                )) : (
                                    <TableRow>
                                        <TableCell colSpan={4} className="text-center py-8">No check in found.</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                )}
            </ul>
        </div>

    );
};

export default AppTrackCheckInPage;
