import { useState, useEffect } from 'react';
import { WarehouseProductPriceService } from '../services/warehouseProductPriceListService';
import { WarehouseProductPrice } from '../models/WarehouseProductPriceList';

export const useWarehouseProductPrice = () => {
    const [prices, setPrices] = useState<WarehouseProductPrice[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        fetchPrices();
    }, []);

    const fetchPrices = async () => {
        setLoading(true);
        try {
            const data = await WarehouseProductPriceService.getAllPrices();
            setPrices(data);
        } catch (err) {
            setError('Failed to fetch prices');
        } finally {
            setLoading(false);
        }
    };

    const fetchPriceById = async (id: number) => {
        setLoading(true);
        try {
            const data = await WarehouseProductPriceService.getPriceById(id);
            return data;
        } catch (err) {
            setError('Failed to fetch price by ID');
        } finally {
            setLoading(false);
        }
    };

    const fetchPriceByProductId = async (id: number) => {
        setLoading(true);
        try {
            const data = await WarehouseProductPriceService.getPriceByProductId(id);
            return data;
        } catch (err) {
            setError('Failed to fetch price by Product ID');
        } finally {
            setLoading(false);
        }
    };

    const addPrice = async (price: Omit<WarehouseProductPrice, 'id' | 'created_time' | 'updated_time'>) => {
        try {
            await WarehouseProductPriceService.addPrice(price);
            fetchPrices();
        } catch (err) {
            setError('Failed to add price');
        }
    };

    const addMultiplePrice = async (prices: Omit<WarehouseProductPrice, 'id' | 'created_time' | 'updated_time'>[]) => {
        setLoading(true);
        setError(null);
        const createdPrices: WarehouseProductPrice[] = [];
        const dbSuccesses: boolean[] = [];

        try {
            for (const price of prices) {
                const newPriceRes = await WarehouseProductPriceService.addPrice(price);
                createdPrices.push(newPriceRes.data);
                dbSuccesses.push(newPriceRes.dbSuccess);
            }

            if (dbSuccesses.includes(false)) {
                return { dbsuccess: false }
            } else {
                return { dbsuccess: true }
            }
        } catch (err: any) {
            setError(err.message || 'Error creating multiple product prices');
            return null;
        } finally {
            setLoading(false);
        }
    }

    const updatePrice = async (id: number, price: Omit<WarehouseProductPrice, 'id' | 'created_time' | 'updated_time'>) => {
        try {
            await WarehouseProductPriceService.updatePrice(id, price);
            fetchPrices();
        } catch (err) {
            setError('Failed to update price');
        }
    };

    const deletePrice = async (id: number) => {
        try {
            await WarehouseProductPriceService.deletePrice(id);
            fetchPrices();
        } catch (err) {
            setError('Failed to delete price');
        }
    };

    return { prices, loading, error, fetchPrices, fetchPriceById, fetchPriceByProductId, addPrice, addMultiplePrice, updatePrice, deletePrice };
};
