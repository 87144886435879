import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWarehouses } from '../../../../hooks/useWarehouse';
import { AppUser } from '../../../../models/AppUser';
import showErrorToast from '../../../../components/Toasts/ShowErrorToast';
import { useAppUser } from '../../../../hooks/useAppUser';
import showLoadingToast from '../../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../../components/Toasts/ShowSuccessToast';
import Loading from '../../../../components/Loading';
import { Heading, Subheading } from '../../../../components/Heading';
import { Button } from '../../../../components/Button';
import { Divider } from '../../../../components/Divider';
import { Input } from '../../../../components/Input';
import { Select } from '../../../../components/Select';
import { Switch, SwitchField, SwitchGroup } from '../../../../components/Switch';
import { Text } from '../../../../components/Text';

interface AddUser {
    username: string;
    password: string;
    confirmPassword?: string; // Optional as it's not part of the AddUser interface but used for validation
    email: string;
    mobile_no: string;
    is_admin: bigint;
    warehouse_id: bigint;
}

const AddAppUserPage = () => {
    const navigate = useNavigate();

    const [user, setUser] = useState<AddUser>({
        username: '',
        password: '',
        confirmPassword: '', // For confirming password
        email: '',
        mobile_no: '',
        is_admin: BigInt(0),
        warehouse_id: BigInt(0),
    });
    const [allUsers, setAllUsers] = useState<AppUser[]>([]);
    const { warehouses } = useWarehouses();
    const { addUser, loading, error } = useAppUser();
    const [isAdmin, setIsAdmin] = useState(false)


    useEffect(() => {
        document.title = "Add App User";
    }, []);

    useEffect(() => {
        if (warehouses.length > 0) {
            setUser(prev => ({ ...prev, warehouse_id: warehouses[0].id }));
        }
    }, [warehouses]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setUser(prev => ({ ...prev, [name]: value }));
    };

    const handleToggleAdmin = (checked: boolean) => {
        setUser(prev => ({ ...prev, is_admin: checked ? BigInt(1) : BigInt(0) }));
        setIsAdmin(checked);
    };

    const validateForm = () => {

        if (!user.username) {
            showErrorToast('Username is required');
            return false;
        }
        if (!user.password) {
            showErrorToast('Password is required');
            return false;
        }
        if (user.password !== user.confirmPassword) {
            showErrorToast('Passwords do not match');
            return false;
        }
        if (!user.warehouse_id) {
            showErrorToast('Warehouse is required');
            return false;
        }
        return true;
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        try {
            const userToSend = {
                ...user,
                is_admin: user.is_admin.toString(), // ✅ Ensure it's a number (not a string)
                warehouse_id: user.warehouse_id.toString(), // ✅ Ensure it's a number
            };

            const res = await addUser(userToSend);
            if (res.dbsuccess) {
                showLoadingToast(`Creating ${user.username} ...`, 100);
                setTimeout(() => {
                    navigate('/gp/app/users');
                    showSuccessToast(`${user.username} added successfully`);
                }, 1000);
            } else {
                showErrorToast(`Error creating user: ${res.message}`);
            }
        } catch (err) {
            showErrorToast(`Failed to add user: ${err}`);
        }
    };


    if (loading) return <Loading />;
    if (error) return <p>{error}</p>;

    return (
        <form method="post" onSubmit={handleSubmit} className="mx-auto max-w-6xl">
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
                <Heading>Add App User</Heading>
                <div className='mt-5 flex lg:ml-4 lg:mt-0'>
                    <Button type="reset" outline onClick={() => navigate(-1)}>
                        Cancel
                    </Button>
                    <Button type="submit" className='ml-3' disabled={!user.username || !user.password || !user.confirmPassword || !user.email || !user.warehouse_id}>Save</Button>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Profile</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            A brief summary highlighting key personal details.
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Email</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        type="email"
                                        aria-label="Email"
                                        name="email"
                                        value={user.email?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Mobile Number</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Mobile Number"
                                        name="mobile_no"
                                        value={user.mobile_no?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Mobile App Account Information</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            Basic details associated with a user’s account.
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Username</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Username"
                                        name="username"
                                        value={user.username}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Password</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        type="password"
                                        aria-label="Password"
                                        name="password"
                                        value={user.password}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Confirm Password</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        type="password"
                                        aria-label="Confirm Password"
                                        name="confirmPassword"
                                        value={user.confirmPassword}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1 ">
                                    <div className='flex justify-between'>
                                        <Subheading>Admin Account</Subheading>
                                        <SwitchGroup>
                                            <SwitchField>
                                                <Switch
                                                    color="dark/zinc" // Choose color variant or customize
                                                    checked={isAdmin} // Pass the current state
                                                    onChange={handleToggleAdmin} // Set state on change
                                                />
                                            </SwitchField>
                                        </SwitchGroup>
                                    </div>
                                    <Text>Admin Account</Text>
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Warehouse</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Select
                                        aria-label="Warehouse"
                                        name="warehouse_id"
                                        value={user.warehouse_id.toString()}
                                        onChange={handleChange}
                                    >
                                        {warehouses.map(warehouse => (
                                            <option key={warehouse.id.toString()} value={warehouse.id.toString()}>
                                                {warehouse.warehouse_name}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <div className="flex justify-end gap-4 my-6">
                    <Button type="reset" plain onClick={() => navigate(-1)}>
                        Cancel
                    </Button>
                    <Button type="submit" disabled={!user.username || !user.password || !user.confirmPassword || !user.email || !user.warehouse_id}>Save</Button>
                </div>
            </div>
        </form>
    );
};

export default AddAppUserPage;
