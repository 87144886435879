// frontend/src/hooks/useAppUser.ts
import { useState, useEffect } from 'react';
import { AppUser } from '../models/AppUser';
import { AppUserService } from '../services/appUserService';

export const useAppUser = () => {
    const [appUsers, setAppUsers] = useState<AppUser[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const fetchAllUsers = async () => {
        setLoading(true);
        setError(null);
        try {
            const users = await AppUserService.getAllUsers();
            setAppUsers(users);
        } catch (err) {
            setError('Failed to fetch users');
        } finally {
            setLoading(false);
        }
    };

    const fetchUserById = async (id: string) => {
        setLoading(true);
        setError(null);
        try {
            const user = await AppUserService.getUserById(id);
            if (user) {
                setAppUsers((prev) => [...prev.filter((u) => u.id !== user.id), user]);
            }
            return user;
        } catch (err) {
            setError('Failed to fetch user');
        } finally {
            setLoading(false);
        }
    };

    const addUser = async (userData: Partial<AppUser>) => {
        try {
            const addUserRes = await AppUserService.addUser(userData);
            await fetchAllUsers();
            return addUserRes;
        } catch (err) {
            setError('Failed to add user');
        }
    };

    const updateUser = async (id: string, userData: Partial<AppUser>) => {
        try {
            const updatedUserRes = await AppUserService.updateUser(id, userData);
            await fetchAllUsers();
            return updatedUserRes;
        } catch (err) {
            setError('Failed to update user');
        }
    };

    const deleteUser = async (id: string) => {
        try {
            await AppUserService.deleteUser(id);
            await fetchAllUsers();
        } catch (err) {
            setError('Failed to delete user');
        }
    };

    useEffect(() => {
        fetchAllUsers();
    }, []);

    return {
        appUsers,
        loading,
        error,
        fetchAllUsers,
        fetchUserById,
        addUser,
        updateUser,
        deleteUser,
    };
};
