import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useWarehouses } from "../../../../hooks/useWarehouse";
import showErrorToast from "../../../../components/Toasts/ShowErrorToast";
import showLoadingToast from "../../../../components/Toasts/ShowLoadingToast";
import showSuccessToast from "../../../../components/Toasts/ShowSuccessToast";
import Loading from "../../../../components/Loading";
import { Heading, Subheading } from "../../../../components/Heading";
import { Button } from "../../../../components/Button";
import { Divider } from "../../../../components/Divider";
import { Input } from "../../../../components/Input";
import { Dialog, DialogActions, DialogDescription, DialogTitle } from "../../../../components/Dialog";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import { useAppTrackLocations } from "../../../../hooks/useAppTrackLocation";
import { MapPinIcon } from "@heroicons/react/16/solid"; // Importing the MapPinIcon from Heroicons
import "leaflet/dist/leaflet.css"; // Import Leaflet CSS
import L from "leaflet"; // Import Leaflet for custom icon handling
import { Select } from "../../../../components/Select";

interface AddLocation {
    location_name: string;
    longitude: string;
    latitude: string;
    warehouse_id: bigint;
}

const AddAppTrackLocationPage = () => {
    const navigate = useNavigate();
    const { warehouses } = useWarehouses();
    const { addTrackLocation, loading, error } = useAppTrackLocations();

    const [location, setLocation] = useState<AddLocation>({
        location_name: "",
        longitude: "",
        latitude: "",
        warehouse_id: BigInt(0),
    });

    const [isOpen, setIsOpen] = useState(false);
    const [currentPosition, setCurrentPosition] = useState<[number, number] | null>(null);

    useEffect(() => {
        document.title = "Add App Track Location";

        if (warehouses.length > 0) {
            setLocation((prev) => ({ ...prev, warehouse_id: warehouses[0].id }));
        }

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setCurrentPosition([position.coords.latitude, position.coords.longitude]);
                },
                () => {
                    showErrorToast("Could not get current location");
                }
            );
        }
    }, [warehouses]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setLocation((prev) => ({ ...prev, [name]: value }));
    };

    const validateForm = () => {
        if (!location.location_name) return showErrorToast("Location name is required"), false;
        if (!location.latitude) return showErrorToast("Latitude is required"), false;
        if (!location.longitude) return showErrorToast("Longitude is required"), false;
        if (!location.warehouse_id) return showErrorToast("Warehouse is required"), false;
        return true;
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!validateForm()) return;

        try {
            const locationToSend = {
                ...location,
                warehouse_id: location.warehouse_id.toString(),
            };

            const res = await addTrackLocation(locationToSend);
            if (res.dbsuccess) {
                showLoadingToast(`Creating ${location.location_name} ...`, 100);
                setTimeout(() => {
                    navigate("/gp/app/track/locations");
                    showSuccessToast(`${location.location_name} added successfully`);
                }, 1000);
            } else {
                showErrorToast(`Error creating location: ${res.message}`);
            }
        } catch (err) {
            showErrorToast(`Failed to add location: ${err}`);
        }
    };

    if (loading) return <Loading />;
    if (error) return <p>{error}</p>;

    // Convert MapPinIcon to an SVG string
    const mapPinIconSVG = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-6 h-6 text-red-600"><path fill-rule="evenodd" d="M10 1a7 7 0 100 14 7 7 0 000-14zm0 2a5 5 0 11-.001 10.001A5 5 0 0110 3zm1 6h-2V8h2v1z" clip-rule="evenodd" /></svg>`;

    // Create a custom Leaflet icon using the SVG string
    const customIcon = new L.Icon({
        iconUrl: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(mapPinIconSVG),
        iconSize: [32, 32], // Adjust the size as needed
        iconAnchor: [16, 32], // Adjust anchor position if needed
        popupAnchor: [0, -32], // Adjust the popup anchor position
    });

    return (
        <form method="post" onSubmit={handleSubmit} className="mx-auto max-w-6xl">
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
                <Heading>Add App Track Location</Heading>
                <div className="mt-5 flex lg:ml-4 lg:mt-0">
                    <Button type="reset" outline onClick={() => navigate(-1)}>
                        Cancel
                    </Button>
                    <Button type="submit" className="ml-3" disabled={!location.location_name || !location.longitude || !location.latitude}>
                        Save
                    </Button>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Location Details</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">Enter the location details below.</p>
                    </div>
                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Location Name</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Location Name"
                                        name="location_name"
                                        value={location.location_name}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>
                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Longitude</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Longitude"
                                        name="longitude"
                                        value={location.longitude}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>
                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Latitude</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Latitude"
                                        name="latitude"
                                        value={location.latitude}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>
                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Warehouse</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Select
                                        aria-label="Warehouse"
                                        name="warehouse_id"
                                        value={location.warehouse_id?.toString()}
                                        onChange={handleChange}
                                    >
                                        {warehouses.map(warehouse => (
                                            <option key={warehouse.id.toString()} value={warehouse.id.toString()}>
                                                {warehouse.warehouse_name}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                            </section>
                        </div>
                        <div className="sm:col-span-full">
                            <Button onClick={() => setIsOpen(true)}>Select Location from Map</Button>
                        </div>
                    </div>
                </div>
                <div className="flex justify-end gap-4 my-6">
                    <Button type="reset" plain onClick={() => navigate(-1)}>Cancel</Button>
                    <Button type="submit" disabled={!location.location_name || !location.latitude || !location.longitude}>Save</Button>
                </div>
            </div>

            {/* Map Dialog */}
            <Dialog open={isOpen} onClose={() => setIsOpen(false)} style={{ width: "80%", maxWidth: "800px", margin: "auto" }}>
                <DialogTitle>Select Location</DialogTitle>
                <DialogDescription>Click on the map to select latitude and longitude.</DialogDescription>
                <div style={{ height: "600px", width: "100%" }} id="map">
                    <MapContainer center={currentPosition || [0, 0]} zoom={15} style={{ height: "600px", width: "100%" }}>
                        <TileLayer url='https://tile.openstreetmap.org/{z}/{x}/{y}.png' />
                        <LocationMarker setLocation={setLocation} customIcon={customIcon} />
                    </MapContainer>
                </div>

                <DialogActions>
                    <Button onClick={() => setIsOpen(false)}>Cancel</Button>
                    <Button onClick={() => setIsOpen(false)}>Save</Button>
                </DialogActions>
            </Dialog>
        </form>
    );
};

// Map Marker Component
const LocationMarker: React.FC<{ setLocation: React.Dispatch<React.SetStateAction<AddLocation>>, customIcon: L.Icon }> = ({ setLocation, customIcon }) => {
    const [position, setPosition] = useState<[number, number] | null>(null);

    useMapEvents({
        click(e) {
            setPosition([e.latlng.lat, e.latlng.lng]);
            setLocation((prev) => ({
                ...prev,
                latitude: e.latlng.lat.toString(),
                longitude: e.latlng.lng.toString(),
            }));
        },
    });

    return position ? <Marker position={position} icon={customIcon} /> : null;
};

export default AddAppTrackLocationPage;
