import React, { useEffect, useState } from 'react';
import { BanknotesIcon, CalendarIcon, ChevronLeftIcon, CreditCardIcon, PencilIcon, TrashIcon } from '@heroicons/react/16/solid';
import { Link, useNavigate, useParams } from 'react-router-dom'; // useParams to get route params
import { Heading, Subheading } from '../../../../components/Heading';
import { Badge } from '../../../../components/Badge';
import { Button } from '../../../../components/Button';
import { Divider } from '../../../../components/Divider';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from '../../../../components/Description-List';
import { Avatar } from '../../../../components/Avatar';
import Loading from '../../../../components/Loading';
import dateFormat from 'dateformat';
import { useStockAdjust } from '../../../../hooks/useStockAdjust';
import { StockAdjust } from '../../../../models/StockAdjust';
import { useStockAdjustItems } from '../../../../hooks/useStockAdjustItem';
import showErrorToast from '../../../../components/Toasts/ShowErrorToast';
import { StockAdjustItem } from '../../../../models/StockAdjustItem';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../components/Table';
import { Dialog, DialogActions, DialogDescription, DialogTitle } from '../../../../components/Dialog';
import { Input } from '../../../../components/Input';
import showLoadingToast from '../../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../../components/Toasts/ShowSuccessToast';

const ViewStockAdjustIdPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [stockAdjust, setStockAdjust] = useState<StockAdjust | null>(null);
    const { fetchStockAdjustById, loading, error } = useStockAdjust();
    const [stockAdjustItems, setStockAdjustItems] = useState<StockAdjustItem[]>([]);
    const { fetchStockAdjustItemByStockAdjustId, updateStockAdjustItem, deleteStockAdjustItem } = useStockAdjustItems();

    const [isOpen, setIsOpen] = useState(false); // Dialog open state
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false); // Dialog open state
    const [editForm, setEditForm] = useState<Partial<StockAdjustItem>>({}); // Form state for item editing
    const [editingItemId, setEditingItemId] = useState<number | null>(null); // Track the item being edited
    const [selectedStockAdjustItemId, setSelectedStockAdjustItemId] = useState<string | null>(null); // ID of the user to be deleted
    const [isDeleting, setIsDeleting] = useState(false); // Loading state for deletion  

    useEffect(() => {
        document.title = "View Stock Adjust";
    }, []);

    const fetchStockAdjustDetails = async () => {
        if (!id) return; // Ensure ID is valid before making the request

        try {
            const fetchedStockAdjust = await fetchStockAdjustById(Number(id));
            setStockAdjust(fetchedStockAdjust);
            const fetchedStockAdjustItems = await fetchStockAdjustItemByStockAdjustId(Number(id));
            setStockAdjustItems(fetchedStockAdjustItems);
        } catch (err) {
            showErrorToast('Failed to fetch stock adjust & items details.');
        }
    };

    useEffect(() => {
        fetchStockAdjustDetails();
    }, [id]);

    const openEditDialog = (item: StockAdjustItem) => {
        setEditingItemId(Number(item.id));
        setEditForm({
            product_id: item.product_id,
            item_no: item.item_no,
            quantity: item.quantity,
            batch_no: item.batch_no,
            stock_adjust_id: item.stock_adjust_id,
        });
        setIsOpen(true);
    };

    const handleEditFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setEditForm((prevForm) => ({
            ...prevForm,
            [name]: value
        }));
    };

    const handleEditSubmit = async () => {
        if (editingItemId !== null) {
            showLoadingToast(`Updating ${editForm.item_no}...`, 100);
            const res = await updateStockAdjustItem(editingItemId, {
                ...editForm
            } as StockAdjustItem);

            if (res && res.dbsuccess) {
                showSuccessToast(`${editForm.item_no} updated successfully`);
                setStockAdjustItems((prevItems) =>
                    prevItems.map((item) =>
                        Number(item.id) === editingItemId ? { ...item, ...editForm } : item
                    )
                );
                setIsOpen(false); // Close dialog
            } else {
                showErrorToast(`Failed updating stock-adjust item data`);
            }
        }
    };

    // Open the delete dialog with the selected user's ID
    const handleOpenDeleteDialog = (stockAdjustId: string) => {
        setSelectedStockAdjustItemId(stockAdjustId);
        setIsDeleteDialogOpen(true);
    };

    // Handle delete user
    const handleDelete = async () => {
        if (!selectedStockAdjustItemId) return;

        setIsDeleting(true);
        try {
            await deleteStockAdjustItem(Number(selectedStockAdjustItemId)); // Call the delete API
            fetchStockAdjustDetails();
            setIsDeleteDialogOpen(false);
        } catch (error) {
            console.error('Failed to delete stock-in item data:', error);
            showErrorToast(`Failed deleting stock-in item data: ${error}`);
        } finally {
            setIsDeleting(false);
        }
    };

    if (loading) return <Loading />;
    if (error) return <p className='py-16 text-center text-red-700'>{error}</p>;
    if (!stockAdjust) return <p className='py-16 text-center'>Stock adjust not found.</p>;

    return (
        <>
            <div className="max-lg:hidden">
                <Link to="/gp/stocks/stockadjust" className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400">
                    <ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500" />
                    Stock Adjust
                </Link>
            </div>
            <div className="mt-4 lg:mt-8">
                <div className="flex items-center gap-4">
                    <Avatar square initials={stockAdjust.reference_no![0]} className="bg-zinc-300 text-gray-700 size-8" />
                    <Heading>{stockAdjust.reference_no}</Heading>
                </div>
                <div className="isolate mt-2.5 flex flex-wrap justify-between gap-x-6 gap-y-4">
                    <div className="flex flex-wrap gap-x-10 gap-y-4 py-1.5">
                        <span className="flex items-center gap-2 text-base/6 text-zinc-950 sm:text-sm/6 dark:text-white">
                            <CalendarIcon className="size-4 shrink-0 fill-zinc-400 dark:fill-zinc-500" />
                            <span className='pr-1 text-zinc-400'>Created on</span><span>{dateFormat(stockAdjust.created_time, "mmmm dd, yyyy")}</span>
                        </span>
                    </div>
                    <div className="flex gap-4">
                        <Button outline onClick={() => navigate(`/gp/stocks/stockadjust/add/items/${stockAdjust.id}`)}>Add Items</Button>
                        <Button onClick={() => navigate(`/gp/stocks/stockadjust/edit/${stockAdjust.id}`)}>Edit</Button>
                    </div>
                </div>
            </div>
            <div className="mt-12">
                <Subheading>Stock Adjust Information</Subheading>
                <Divider className="mt-4" />
                <DescriptionList>
                    <DescriptionTerm>Reference</DescriptionTerm>
                    <DescriptionDetails>{stockAdjust.reference_no!}</DescriptionDetails>
                    <DescriptionTerm>Customer</DescriptionTerm>
                    <DescriptionDetails>{stockAdjust.customer_fullname || '-'}</DescriptionDetails>
                    <DescriptionTerm>Manual Number</DescriptionTerm>
                    <DescriptionDetails>{stockAdjust.manual_no || '-'}</DescriptionDetails>
                    <DescriptionTerm>Logistic</DescriptionTerm>
                    <DescriptionDetails>{stockAdjust.logistic_fullname || '-'}</DescriptionDetails>
                    <DescriptionTerm>Stock Adjust Date</DescriptionTerm>
                    <DescriptionDetails>{dateFormat(stockAdjust.date?.toString(), "mmmm dd, yyyy")}</DescriptionDetails>
                    <DescriptionTerm>Issuer</DescriptionTerm>
                    <DescriptionDetails>{stockAdjust.issuer_fullname || '-'}</DescriptionDetails>
                    <DescriptionTerm>Warehouse</DescriptionTerm>
                    <DescriptionDetails>{stockAdjust.warehouse_name || '-'}</DescriptionDetails>
                </DescriptionList>
            </div>
            <Divider className='py-8' />
            <div>
                <Subheading>Stock Adjust Items List</Subheading>
                <Table className="mt-4 [--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
                    <TableHead>
                        <TableRow>
                            <TableHeader>Product Barcode</TableHeader>
                            <TableHeader>Description</TableHeader>
                            <TableHeader>Quantity</TableHeader>
                            <TableHeader>UOM</TableHeader>
                            <TableHeader>Packing</TableHeader>
                            <TableHeader>Batch No</TableHeader>
                            <TableHeader>Approved By</TableHeader>
                            <TableHeader>Reason</TableHeader>
                            <TableHeader>Actions</TableHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(stockAdjustItems.length > 0) ? stockAdjustItems.map((stockAdjustItem) => (
                            <TableRow key={stockAdjustItem.id} title={`${stockAdjustItem.item_no}`}>
                                <TableCell>{stockAdjustItem.item_no}</TableCell>
                                <TableCell>{stockAdjustItem.description}</TableCell>
                                <TableCell className="text-zinc-500">{stockAdjustItem.quantity}</TableCell>
                                <TableCell className="text-zinc-500">{stockAdjustItem.uom}</TableCell>
                                <TableCell className="text-zinc-500">{stockAdjustItem.packing}</TableCell>
                                <TableCell className="text-zinc-500">{stockAdjustItem.batch_no}</TableCell>
                                <TableCell className="text-zinc-500">{stockAdjustItem.approved_by}</TableCell>
                                <TableCell className="text-zinc-500">{stockAdjustItem.reason}</TableCell>
                                <TableCell className="py-5 flex gap-4">
                                    <a onClick={() => openEditDialog(stockAdjustItem)} className="text-zinc-600 hover:text-zinc-900">
                                        <PencilIcon className='h-5 w-5' />
                                    </a>
                                    <a onClick={() => handleOpenDeleteDialog(stockAdjustItem.id.toString())} className="text-zinc-600 hover:text-zinc-900">
                                        <TrashIcon className='h-5 w-5' />
                                    </a>
                                </TableCell>
                            </TableRow>
                        )) : <TableRow>
                            <TableCell colSpan={9} className="text-center py-8">No items found.</TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
            </div>
            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                <DialogTitle>Edit Stock Adjust Item</DialogTitle>
                <DialogDescription>
                    You can edit the quantity, batch no, and expiry date of the stock-in item.
                </DialogDescription>
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 mt-4">
                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>Quantity</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    aria-label="Edit Quantity"
                                    type="number"
                                    name="quantity"
                                    value={editForm.quantity || ''}
                                    onChange={handleEditFormChange}
                                />
                            </div>
                        </section>
                    </div>
                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>Batch Number</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    aria-label="Edit Batch No"
                                    type="text"
                                    name="batch_no"
                                    value={editForm.batch_no || ''}
                                    onChange={handleEditFormChange}
                                />
                            </div>
                        </section>
                    </div>
                </div>
                <DialogActions>
                    <Button plain onClick={() => setIsOpen(false)}>
                        Cancel
                    </Button>
                    <Button onClick={handleEditSubmit}>Save</Button>
                </DialogActions>
            </Dialog>

            {/* Delete Confirmation Dialog */}
            <Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
                <DialogTitle>Delete Stock Adjust Item</DialogTitle>
                <DialogDescription>
                    Are you sure you want to delete this stock adjust item? This action cannot be undone.
                </DialogDescription>
                <DialogActions>
                    <Button plain onClick={() => setIsDeleteDialogOpen(false)} disabled={isDeleting}>
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} disabled={isDeleting}>
                        {isDeleting ? <Loading /> : 'Delete'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ViewStockAdjustIdPage;
