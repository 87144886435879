import React, { useContext, useEffect, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon, EllipsisVerticalIcon, EyeIcon, MagnifyingGlassIcon, PencilIcon, PrinterIcon } from '@heroicons/react/16/solid';
import { Dropdown, DropdownButton, DropdownItem, DropdownLabel, DropdownMenu } from '../../../components/Dropdown';
import { Link } from '../../../components/Link';
import { Button } from '../../../components/Button';
import { Heading, Subheading } from '../../../components/Heading';
import { Input, InputGroup } from '../../../components/Input';
import Loading from '../../../components/Loading';
import { useNavigate } from 'react-router-dom';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../components/Table';
import dateFormat from 'dateformat';
import { UserContext } from '../../../context/userContext';
import { WarehouseProduct } from '../../../models/WarehouseProduct';
import { useWarehouseProductItem } from '../../../hooks/useWarehouseProductItem';
import { WarehouseProductItem } from '../../../models/WarehouseProductItem';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import showErrorToast from '../../../components/Toasts/ShowErrorToast';
import { Text } from '../../../components/Text';
import { useWarehouses } from '../../../hooks/useWarehouse';
import { useWarehouseProduct } from '../../../hooks/useWarehouseProduct';

const ReportStockBalancePage: React.FC = () => {
    const { jsPDF } = require('jspdf');
    require('jspdf-autotable');
    const { currentUser } = useContext(UserContext); // Get current user info
    const {
        loading,
        error,
        products
    } = useWarehouseProduct();
    const [searchQuery, setSearchQuery] = useState<string>(''); // State to store the search query
    const [currentPage, setCurrentPage] = useState<number>(1); // State to track the current page
    const [isOpen, setIsOpen] = useState(false); // Dialog open state
    const [isDeleting, setIsDeleting] = useState(false); // Loading state for deletion
    const [selectedProductId, setSelectedProductId] = useState<string | null>(null); // ID of the product to be deleted
    const navigate = useNavigate();
    const [filterStartDate, setFilterStartDate] = useState<string>('');
    const [filterEndDate, setFilterEndDate] = useState<string>('');
    const [filterWarehouseId, setFilterWarehouseId] = useState<string>('');
    const [filterYear, setFilterYear] = useState<string>('');
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const { warehouses } = useWarehouses();

    useEffect(() => {
        document.title = "Stock Balance Report";
    }, []);

    // Filter products based on the search query
    const filteredProduct = Array.isArray(products)
        ? products?.filter((product) => {
            const matchesSearch = product.item_no?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                product.second_item_no?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                product.description?.toLowerCase().includes(searchQuery.toLowerCase())

            const matchesStartDate = !filterStartDate || new Date(product.created_time) >= new Date(filterStartDate);
            const matchesEndDate = !filterEndDate || new Date(product.created_time) <= new Date(filterEndDate);
            const matchesYear = !filterYear || new Date(product.created_time).getFullYear().toString() === filterYear;
            const matchesWarehouse = !filterWarehouseId || Number(product.warehouse_id) === Number(filterWarehouseId);

            return matchesSearch && matchesStartDate && matchesEndDate && matchesYear && matchesWarehouse;
        }) : [];

    const formatDate = (date: Date): string => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    // Function to generate PDF
    const generatePDF = () => {
        const today = formatDate(new Date());

        if (!filteredProduct) {
            showErrorToast("PDF generation failed. Product item is not available.");
            return; // Exit the function early if productItem is undefined
        }

        const doc = new jsPDF();
        doc.setFontSize(12);
        doc.text(`Stock Balance Report | ${today}`, 14, 10);
        doc.autoTable({
            head: [['S/N', 'CCode', 'SKU', 'Item No', 'Second Item No', 'Description', 'Minimum Quantity', 'Current Quantity']],
            body: filteredProduct.map((product, index) => [
                index + 1,
                product.sku || "N/A",
                product.item_no || "N/A",
                product.second_item_no || "N/A",
                product.description || "N/A",
                product.minimum_quantity || 0,
                product.current_quantity || 0
            ])
        });

        doc.save(`stock_balance_report_${today}.pdf`);
    };

    // Function to generate CSV
    const generateCSV = () => {
        if (!filteredProduct) {
            showErrorToast("CSV generation failed. Product item is not available.");
            return; // Exit the function early if productItem is undefined
        }

        const csvData = [
            ['S/N', 'CCode', 'SKU', 'Item No', 'Second Item No', 'Description', 'Minimum Quantity', 'Current Quantity'],
            ...filteredProduct.map((product, index) => [
                index + 1,
                product.sku || "N/A",
                product.item_no || "N/A",
                product.second_item_no || "N/A",
                product.description || "N/A",
                product.minimum_quantity || 0,
                product.current_quantity || 0
            ])
        ];

        const csvContent = 'data:text/csv;charset=utf-8,' + csvData.map(e => e.join(",")).join("\n");
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        const today = formatDate(new Date());
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `stock_balance_report_${today}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Function to generate Excel
    const generateExcel = () => {
        if (!filteredProduct) {
            showErrorToast("Excel generation failed. Product item is not available.");
            return; // Exit the function early if productItem is undefined
        }

        const worksheet = XLSX.utils.json_to_sheet(filteredProduct.map((product, index) => ({
            SNo: index + 1,
            SKU: product.sku || "N/A",
            ItemNo: product.item_no || "N/A",
            SecondItemNo: product.second_item_no || "N/A",
            Description: product.description || "N/A",
            MinimumQuantity: product.minimum_quantity || 0,
            CurrentQuantity: product.current_quantity || 0
        })));

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Stock Balance");

        const today = formatDate(new Date());
        XLSX.writeFile(workbook, `stock_balance_report_${today}.xlsx`);
    };

    return (
        <>
            <div className="flex flex-wrap items-end justify-between gap-4">
                <div className="max-sm:w-full sm:flex-1">
                    <Heading>Stock Balance</Heading>
                    <div className="mt-4 flex max-w-xl gap-4">
                        <div className="flex-1">
                            <InputGroup>
                                <MagnifyingGlassIcon />
                                <Input
                                    name="search"
                                    placeholder="Search stock balance&hellip;"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </InputGroup>
                        </div>
                    </div>
                </div>
                <Dropdown>
                    <DropdownButton aria-label="More options">
                        Print Report
                    </DropdownButton>
                    <DropdownMenu anchor="bottom end">
                        <DropdownItem onClick={generatePDF}>PDF</DropdownItem>
                        <DropdownItem onClick={generateCSV}>CSV</DropdownItem>
                        <DropdownItem onClick={generateExcel}>Excel</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>

            {/* Date, Year, and Warehouse Filters */}
            <div className="mt-4 px-6 py-4 bg-zinc-50 ring-1 ring-zinc-200 rounded-md">
                <div className='flex flex-wrap items-end justify-between gap-4'>
                    <Subheading>Filters & Sorting</Subheading>
                    {(isFilterOpen) ? <ChevronUpIcon onClick={() => setIsFilterOpen(false)} className='w-5 h-5' /> : <ChevronDownIcon onClick={() => setIsFilterOpen(true)} className='w-5 h-5' />}
                </div>
                {(isFilterOpen) && <div className='mt-4 grid gap-4 sm:grid-cols-1'>
                    <div>
                        <Text>From Date</Text>
                        <Input
                            type="date"
                            value={filterStartDate}
                            onChange={(e) => setFilterStartDate(e.target.value)}
                        />
                    </div>
                    <div>
                        <Text>To Date</Text>
                        <Input
                            type="date"
                            value={filterEndDate}
                            onChange={(e) => setFilterEndDate(e.target.value)}
                        />
                    </div>
                    <div>
                        <Text>By Year</Text>
                        <Input
                            type="number"
                            placeholder="Year"
                            value={filterYear}
                            onChange={(e) => setFilterYear(e.target.value)}
                        />
                    </div>
                    <div>
                        <Button onClick={() => {
                            setFilterStartDate('');
                            setFilterEndDate('');
                            setFilterYear('');
                            setFilterWarehouseId('');
                        }}>
                            Reset Filter
                        </Button>
                    </div>
                    {(currentUser?.role.toString() === "1") && (
                        <div className='max-w-full'>
                            <Text>By Warehouse</Text>
                            <Dropdown>
                                <DropdownButton outline>
                                    {filterWarehouseId ? warehouses?.find(w => w.id?.toString() === filterWarehouseId)?.warehouse_name : 'All Warehouses'}
                                    <ChevronDownIcon />
                                </DropdownButton>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => setFilterWarehouseId('')}>All Warehouses</DropdownItem>
                                    {warehouses?.map((warehouse) => (
                                        <DropdownItem key={warehouse.id} onClick={() => setFilterWarehouseId(warehouse.id?.toString()!)}>
                                            {warehouse.warehouse_name}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    )}
                </div>}
            </div>

            <ul className="mt-10">
                {loading && <div className='w-full text-center h-full py-36 justify-center'><Loading /></div>}
                {error && <p className='py-16 text-center text-red-700'>{error}</p>}
                {(!loading && !error) && (
                    <div>
                        <Table className="[--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
                            <TableHead>
                                <TableRow>
                                    <TableHeader>S/N</TableHeader>
                                    <TableHeader>CCode</TableHeader>
                                    <TableHeader>SKU</TableHeader>
                                    <TableHeader>Item No</TableHeader>
                                    <TableHeader>Second Item No</TableHeader>
                                    <TableHeader>Description</TableHeader>
                                    <TableHeader>Minimum Quantity</TableHeader>
                                    <TableHeader>Current Quantity</TableHeader>
                                    <TableHeader>Created Date</TableHeader>
                                    <TableHeader>Actions</TableHeader>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(filteredProduct.length > 0) ? filteredProduct.map((product, index) => (
                                    <TableRow key={index} title={`${index}`}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{product.sku}</TableCell>
                                        <TableCell className="text-zinc-500">{product.item_no}</TableCell>
                                        <TableCell className="text-zinc-500">{product.second_item_no}</TableCell>
                                        <TableCell className="text-zinc-500">{product.description}</TableCell>
                                        <TableCell className="text-zinc-400">{product.minimum_quantity}</TableCell>
                                        <TableCell className="text-zinc-400">{product.current_quantity}</TableCell>
                                        <TableCell className="text-zinc-400">{dateFormat(product.created_time, "mmm dd, yyyy")}</TableCell>
                                        <TableCell className="py-5 flex gap-4">
                                            <a className="text-zinc-600 hover:text-zinc-900" onClick={() => navigate(`/gp/reports/stockbalance/view/${product.id}`)}>
                                                <EyeIcon className='h-5 w-5' />
                                            </a>
                                        </TableCell>
                                    </TableRow>
                                )) : <TableRow>
                                    <TableCell colSpan={9} className="text-center py-8">No stock balance found.</TableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </div>
                )}
            </ul>
        </>
    );
};

export default ReportStockBalancePage;
