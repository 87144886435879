import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from '../../../../models/User';
import { Heading, Subheading } from '../../../../components/Heading';
import { Divider } from '../../../../components/Divider';
import { Input } from '../../../../components/Input';
import { Textarea } from '../../../../components/Textarea';
import { Button } from '../../../../components/Button';
import showLoadingToast from '../../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../../components/Toasts/ShowSuccessToast';
import showErrorToast from '../../../../components/Toasts/ShowErrorToast';
import { useRoles } from '../../../../hooks/useRoles';
import Loading from '../../../../components/Loading';
import { useSetupUsergroup } from '../../../../hooks/useSetupUsergroup';
import { useWarehouseProduct } from '../../../../hooks/useWarehouseProduct';
import { useSetupBrand } from '../../../../hooks/useSetupBrand';
import { useWarehouses } from '../../../../hooks/useWarehouse';
import { useWarehouseProductCategory } from '../../../../hooks/useWarehouseProductCategory';
import { useWarehouseProductGroup } from '../../../../hooks/useWarehouseProductGroup';
import { useWarehouseProductDepartment } from '../../../../hooks/useWarehouseProductDepartment';
import ReactSelect, { SingleValue } from 'react-select';
import { WarehouseProductCategory } from '../../../../models/WarehouseProductCategory';
import { WarehouseProductDepartment } from '../../../../models/WarehouseProductDepartment';
import { WarehouseProductGroup } from '../../../../models/WarehouseProductGroup';
import { Select } from '../../../../components/Select';
import { UserContext } from '../../../../context/userContext';
import { SetupBrand } from '../../../../models/SetupBrand';

interface AddWarehouseProduct {
    sku: string;
    barcode?: string;
    description?: string;
    location?: string;
    image?: string | ArrayBuffer | null;
    warehouse_id: number;
    category_id: number;
    group_id: number;
    item_no?: string | null;
    second_item_no?: string | null;
    packing?: string | null;
    uom?: string | null;
    current_quantity?: string | null;
    minimum_quantity?: string | null;
}

const AddWarehouseProductPage = () => {
    const navigate = useNavigate();
    const [product, setProduct] = useState<AddWarehouseProduct>({
        sku: '',
        barcode: '',
        description: '',
        location: '',
        image: '',
        warehouse_id: Number(0),
        category_id: Number(0),
        group_id: Number(0),
        item_no: '',
        second_item_no: '',
        packing: '',
        uom: '',
        current_quantity: '0',
        minimum_quantity: '',
    });
    const { createProduct } = useWarehouseProduct();
    const { suppliers } = useSetupUsergroup();
    const { warehouses } = useWarehouses();
    const filteredWarehouses = warehouses.filter(warehouse => warehouse.hq !== '1');
    const { categories } = useWarehouseProductCategory();
    const { groups } = useWarehouseProductGroup();
    const { departments } = useWarehouseProductDepartment();
    const { currentUser } = useContext(UserContext);

    useEffect(() => {
        document.title = "Add Product";
    }, []);

    useEffect(() => {

        if (product.warehouse_id === 0 && filteredWarehouses.length > 0) {
            setProduct(prev => ({ ...prev, warehouse_id: Number(filteredWarehouses[0].id) }));
        }
        if (categories.length > 0) {
            setProduct(prev => ({ ...prev, category_id: Number(categories[0].id) }));
        }
        if (departments.length > 0) {
            setProduct(prev => ({ ...prev, department_id: Number(departments[0].id) }));
        }
        if (groups.length > 0) {
            setProduct(prev => ({ ...prev, group_id: Number(groups[0].id) }));
        }
        if (currentUser?.warehouse_hq !== "1") {
            setProduct(prev => ({ ...prev, warehouse_id: Number(currentUser?.warehouse_id) }));
        }
    }, [suppliers]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setProduct(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!product.sku || !product.warehouse_id || !product.sku || !product.category_id || !product.group_id) {
            showErrorToast("Please fill in all field before submit");
            return;
        }
        try {
            const res = await createProduct(product);
            if (res.dbsuccess) {
                showLoadingToast(`Creating ${product.sku} ...`, 100);
                setTimeout(() => {
                    navigate('/gp/inventories/products');
                    showSuccessToast(`${product.sku} added successfully`);
                }, 1000);
            } else {
                showErrorToast(`Failed adding product`);
            }
        } catch (err) {
            showErrorToast(`Failed to add product: ${err}`);
        }
    };

    const selectCategoryOptions = categories.map((category: WarehouseProductCategory) => ({
        value: category.id,
        label: `${category.category_name || "-"}  | Department:    ${category.department_name}`,
    }));

    // Handle ReactSelect change
    const handleCategoryChange = (newValue: SingleValue<{ value: number; label: string }>) => {
        if (newValue) {
            setProduct((prev) => ({ ...prev, category_id: newValue.value }));
        }
    };

    const selectGroupOptions = groups.map((group: WarehouseProductGroup) => ({
        value: group.id,
        label: `${group.group_name || "-"}  ${group.group_code}`,
    }));

    // Handle ReactSelect change
    const handleGroupChange = (newValue: SingleValue<{ value: number; label: string }>) => {
        if (newValue) {
            setProduct((prev) => ({ ...prev, group_id: newValue.value }));
        }
    };

    return (
        <form method="post" onSubmit={handleSubmit} className="mx-auto max-w-6xl">
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
                <Heading>Add Product</Heading>
                <div className='mt-5 flex lg:ml-4 lg:mt-0'>
                    <Button type="reset" outline onClick={() => navigate(-1)}>
                        Cancel
                    </Button>
                    <Button type="submit" className='ml-3' disabled={(!product.sku || !product.warehouse_id || !product.sku || !product.category_id || !product.group_id)}>Save & Continue</Button>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Product Information</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            Details about a product.
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Warehouse</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Select
                                        aria-label="Warehouse"
                                        name="warehouse_id"
                                        value={product.warehouse_id.toString()}
                                        onChange={handleChange}
                                        required
                                        disabled={currentUser?.warehouse_hq !== "1"}
                                    >
                                        {filteredWarehouses.map(warehouse => (
                                            <option key={warehouse.id.toString()} value={warehouse.id.toString()}>
                                                {warehouse.warehouse_name}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Item Number</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="SKU"
                                        name="sku"
                                        value={product.sku}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Barcode</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Barcode"
                                        name="barcode"
                                        value={product.barcode}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Description</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Description"
                                        name="description"
                                        value={product.description}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Category</Subheading>
                                </div>
                                <div className="mt-2">
                                    <ReactSelect
                                        id="category_id"
                                        name="category_id"
                                        options={selectCategoryOptions}
                                        className="text-sm"
                                        placeholder="Select Category"
                                        onChange={handleCategoryChange}
                                        value={selectCategoryOptions.find(option => option.value === product.category_id)}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Group</Subheading>
                                </div>
                                <div className="mt-2">
                                    <ReactSelect
                                        id="group_id"
                                        name="group_id"
                                        options={selectGroupOptions}
                                        className="text-sm"
                                        placeholder="Select Group"
                                        onChange={handleGroupChange}
                                        value={selectGroupOptions.find(option => option.value === product.group_id)}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Location</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Location"
                                        name="location"
                                        value={product.location!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Item No</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Item No"
                                        name="item_no"
                                        value={product.item_no!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Second Item No</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Second Item No"
                                        name="second_item_no"
                                        value={product.second_item_no!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Packing</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Packing"
                                        name="packing"
                                        value={product.packing!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>UOM</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="UOM"
                                        name="uom"
                                        value={product.uom!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Minimum Quantity</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Minimum Quantity"
                                        name="minimum_quantity"
                                        value={product.minimum_quantity!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-end gap-4 my-6">
                <Button type="reset" plain onClick={() => navigate(-1)}>
                    Cancel
                </Button>
                <Button type="submit" disabled={(!product.sku || !product.warehouse_id || !product.sku || !product.category_id || !product.group_id)}>Save</Button>
            </div>
        </form>
    );
};

export default AddWarehouseProductPage;
