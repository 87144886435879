import React, { useEffect, useState } from 'react';
import { BanknotesIcon, CalendarIcon, ChevronLeftIcon, CreditCardIcon, PencilIcon, TrashIcon } from '@heroicons/react/16/solid';
import { Link, useNavigate, useParams } from 'react-router-dom'; // useParams to get route params
import { Heading, Subheading } from '../../../../components/Heading';
import { Badge } from '../../../../components/Badge';
import { Button } from '../../../../components/Button';
import { Divider } from '../../../../components/Divider';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from '../../../../components/Description-List';
import { Avatar } from '../../../../components/Avatar';
import { UserService } from '../../../../services/userService'; // Import the UserService
import Loading from '../../../../components/Loading';
import dateFormat from 'dateformat';
import showErrorToast from '../../../../components/Toasts/ShowErrorToast';
import { WarehouseProduct } from '../../../../models/WarehouseProduct';
import { useWarehouseProduct } from '../../../../hooks/useWarehouseProduct';
import { WarehouseProductItem } from '../../../../models/WarehouseProductItem';
import { useWarehouseProductItem } from '../../../../hooks/useWarehouseProductItem';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../components/Table';
import { Dialog, DialogActions, DialogDescription, DialogTitle } from '../../../../components/Dialog';
import { Input } from '../../../../components/Input';
import showLoadingToast from '../../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../../components/Toasts/ShowSuccessToast';
import { WarehouseProductPrice } from '../../../../models/WarehouseProductPriceList';
import { useWarehouseProductPrice } from '../../../../hooks/useWarehouseProductPriceList';

const ViewWarehouseProductIdPage: React.FC = () => {
    const { id } = useParams<{ id: string }>(); // Use useParams to get the id from the URL
    const [product, setProduct] = useState<WarehouseProduct | null>(null);
    const { fetchProductById, loading, error } = useWarehouseProduct();
    const [productItems, setProductItems] = useState<WarehouseProductItem[]>([]);
    const { fetchProductItemByProductId } = useWarehouseProductItem();
    const navigate = useNavigate();
    const [currentProductPriceList, setCurrentProductPriceList] = useState<WarehouseProductPrice[]>([]);
    const { fetchPriceByProductId } = useWarehouseProductPrice();

    useEffect(() => {
        document.title = "View Product";
    }, []);

    const fetchProductDetails = async () => {
        if (!id) return; // Ensure ID is valid before making the request

        try {
            const fetchedProduct = await fetchProductById(Number(id));
            setProduct(fetchedProduct);
            const fetchedProductItems = await fetchProductItemByProductId(Number(id));
            setProductItems(fetchedProductItems);
        } catch (err) {
            showErrorToast('Failed to fetch product details.');
        }
    };

    // Fetch prices for the selected product
    const fetchPrices = async () => {
        try {
            const prices = await fetchPriceByProductId(Number(id));
            console.log(prices);
            if (prices) {
                setCurrentProductPriceList(prices.map(price => ({
                    product_id: price.product_id,
                    supplier_id: price.supplier_id,
                    price: price.price,
                    remarks: price.remarks ?? null, // Ensure remarks is never undefined
                    supplier_fullname: price.supplier_fullname ?? '-',
                })));
            }
        } catch (err) {
            showErrorToast('Failed to fetch product prices.');
        }
    };

    useEffect(() => {
        fetchProductDetails();
        fetchPrices();
    }, [id]);

    if (loading) return <Loading />;
    if (error) return <p className='py-16 text-center text-red-700'>{error}</p>;
    if (!product) return <p className='py-16 text-center'>Product not found.</p>;

    return (
        <>
            <div className="max-lg:hidden">
                <Link to="/gp/inventories/products" className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400">
                    <ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500" />
                    Products
                </Link>
            </div>
            <div className="mt-4 lg:mt-8">
                <div className="flex items-center gap-4">
                    <Avatar square initials={product.barcode![0]} className="bg-zinc-300 text-gray-700 size-8" />
                    <Heading>{product.barcode}</Heading>
                    <Badge color="lime">{product.sku!}</Badge>
                </div>
                <div className="isolate mt-2.5 flex flex-wrap justify-between gap-x-6 gap-y-4">
                    <div className="flex flex-wrap gap-x-10 gap-y-4 py-1.5">
                        <span className="flex items-center gap-2 text-base/6 text-zinc-950 sm:text-sm/6 dark:text-white">
                            <CalendarIcon className="size-4 shrink-0 fill-zinc-400 dark:fill-zinc-500" />
                            <span className='pr-1 text-zinc-400'>Created on</span><span>{dateFormat(product.created_time, "mmmm dd, yyyy")}</span>
                        </span>
                    </div>
                    <div className="flex gap-4">
                        <Button onClick={() => navigate(`/gp/inventories/products/edit/${product.id}`)}>Edit</Button>
                    </div>
                </div>
            </div>
            <div className="mt-12">
                <Subheading>Product Information</Subheading>
                <Divider className="mt-4" />
                <DescriptionList>
                    <DescriptionTerm>SKU</DescriptionTerm>
                    <DescriptionDetails>{product.sku}</DescriptionDetails>
                    <DescriptionTerm>Barcode</DescriptionTerm>
                    <DescriptionDetails>{product.barcode}</DescriptionDetails>
                    <DescriptionTerm>Description</DescriptionTerm>
                    <DescriptionDetails>{product.description}</DescriptionDetails>
                    <DescriptionTerm>Category</DescriptionTerm>
                    <DescriptionDetails>{product.category_name}</DescriptionDetails>
                    <DescriptionTerm>Group</DescriptionTerm>
                    <DescriptionDetails>{product.group_name}</DescriptionDetails>
                    <DescriptionTerm>Location</DescriptionTerm>
                    <DescriptionDetails>{product.location}</DescriptionDetails>
                    <DescriptionTerm>Item No</DescriptionTerm>
                    <DescriptionDetails>{product.item_no}</DescriptionDetails>
                    <DescriptionTerm>Second Item No</DescriptionTerm>
                    <DescriptionDetails>{product.second_item_no}</DescriptionDetails>
                    <DescriptionTerm>Packing</DescriptionTerm>
                    <DescriptionDetails>{product.packing}</DescriptionDetails>
                    <DescriptionTerm>UOM</DescriptionTerm>
                    <DescriptionDetails>{product.uom}</DescriptionDetails>
                    <DescriptionTerm>Current Quantity</DescriptionTerm>
                    <DescriptionDetails>{product.current_quantity}</DescriptionDetails>
                    <DescriptionTerm>Minimum Quantity</DescriptionTerm>
                    <DescriptionDetails>{product.minimum_quantity}</DescriptionDetails>
                </DescriptionList>
            </div>

            <div className='mt-12'>
                <Subheading>Product Price List</Subheading>
                <Divider className="mt-4" />
                <Table className="[--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
                    <TableHead>
                        <TableRow>
                            <TableHeader>S/N</TableHeader>
                            <TableHeader>Supplier</TableHeader>
                            <TableHeader>Price</TableHeader>
                            <TableHeader>Remark</TableHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(currentProductPriceList.length > 0) ? currentProductPriceList.map((item, index) => (
                            <TableRow key={index} title={`${index}`}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{item.supplier_fullname}</TableCell>
                                <TableCell>{item.price}</TableCell>
                                <TableCell className="text-zinc-500">{item.remarks}</TableCell>

                            </TableRow>
                        )) : <TableRow>
                            <TableCell colSpan={9} className="text-center py-8">No price found.</TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
            </div>
        </>
    );
};

export default ViewWarehouseProductIdPage;
