import axios from 'axios';
import { AppTrackCheckIn } from '../models/AppTrackCheckIn';

const API_URL = process.env.REACT_APP_SERVER_URL + "/app/track/checkins";

export const AppTrackCheckInService = {
    getAllTrackCheckIns: async (): Promise<AppTrackCheckIn[]> => {
        const response = await axios.get(`${API_URL}/getAllCheckIns`);
        return response.data;
    },

    getTrackCheckInById: async (id: number): Promise<AppTrackCheckIn> => {
        const response = await axios.get(`${API_URL}/getCheckInById/${id}`);
        return response.data;
    },

    createTrackCheckIn: async (trackCheckIn: Omit<AppTrackCheckIn, 'id' | 'created_time' | 'updated_time'>): Promise<AppTrackCheckIn> => {
        const response = await axios.post(`${API_URL}/add`, trackCheckIn);
        return response.data;
    },

    updateTrackCheckIn: async (id: number, trackCheckIn: Omit<AppTrackCheckIn, 'id' | 'created_time' | 'updated_time'>): Promise<AppTrackCheckIn> => {
        const response = await axios.put(`${API_URL}/update/${id}`, trackCheckIn);
        return response.data;
    },

    deleteTrackCheckIn: async (id: number): Promise<void> => {
        await axios.delete(`${API_URL}/delete/${id}`);
    }
};
