import axios from 'axios';
import { AppUserCurrentLocation } from '../models/AppUserCurrentLocation';

const API_URL = process.env.REACT_APP_SERVER_URL + "/app/users/currentlocations";

export const AppUserCurrentLocationService = {
    getAllLocations: async (): Promise<AppUserCurrentLocation[]> => {
        const response = await axios.get(`${API_URL}/getAllLocations`);
        return response.data;
    },
    getLocationById: async (id: number): Promise<AppUserCurrentLocation> => {
        const response = await axios.get(`${API_URL}/getLocationById/${id}`);
        return response.data;
    },
    getAllLocationsByUserId: async (id: number): Promise<AppUserCurrentLocation[]> => {
        const response = await axios.get(`${API_URL}/getAllLocationsByUserId/${id}`);
        return response.data;
    },
    getAllLocationsByUserIdToday: async (id: number): Promise<AppUserCurrentLocation[]> => {
        const response = await axios.get(`${API_URL}/getAllLocationsByUserIdToday/${id}`);
        return response.data;
    },
    createLocation: async (data: Omit<AppUserCurrentLocation, 'id' | 'created_time' | 'updated_time'>) => {
        const response = await axios.post(`${API_URL}/add`, data);
        return response.data;
    },
    updateLocation: async (id: number, data: Omit<AppUserCurrentLocation, 'id' | 'created_time' | 'updated_time'>) => {
        const response = await axios.put(`${API_URL}/update/${id}`, data);
        return response.data;
    },
    deleteLocation: async (id: number): Promise<void> => {
        await axios.delete(`${API_URL}/delete/${id}`);
    },
};